<mat-card *ngIf="errorMessage!=''" class="error-container">
  <mat-card-title class="mat-error">
    <mat-icon color="warn" style="float: left; margin-right: 10px;">error</mat-icon>
    <span>Erreur</span>
    <button mat-icon-button aria-label="dismiss" (click)="closeError()" style="float:right;">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-card-title>
  <mat-card-content>
    <pre [innerHTML]="errorMessage" class="mat-error" style="text-align: left;"></pre>

    <div>
      <h3>Que faire ?</h3>
      <ul>
        <li>Nous vous invitons à fermer tous les onglets de votre navigateur et à réessayer en suivant les étapes de
          connexion.</li>
        <li>N'utilisez ni la « flèche retour en arrière » de votre navigateur ni raccourcis.</li>
        <li>Si vous avez besoin de recommencer tout le processus de connexion, veillez à bien fermer votre navigateur au
          préalable.</li>
      </ul>
    </div>
  </mat-card-content>
</mat-card>


<ng-container *ngIf="!redirecting; else redirection">

  <mat-card *ngIf="!homeCard" class="conteneur">
    <mat-card-header>
      <mat-card-title>Connexion</mat-card-title>
      <mat-icon mat-card-avatar class="icon">person</mat-icon>
    </mat-card-header>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </mat-card>

  <div *ngIf="homeCard">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>

</ng-container>
<ng-template #content>
  <ng-container *ngIf="authService.currentUser$|async as user; else loginBlock">

    <div class="already-logged-msg">

      <div class="oidc-logged-msg" style="margin-top: 5px;" *ngIf="user.fromOidc">
        <img [src]="externalAuth.providerLogo" class="oidc-logo" [matTooltip]="userNameTooltip"
          [tooltipClass]="login-tooltip" />
        <span> Vous êtes identifié grâce à {{user.oidcProvider}} </span>
      </div>

      <p>{{ 'Logged in as'|trans }} {{ user.username || user.email }}</p>

      <button mat-raised-button color="primary" routerLink="/account">Mon compte</button>
    </div>
  </ng-container>
</ng-template>

<ng-template #loginBlock>

  <div *ngIf="externalAuth?.identityProvider !== ''" class="external-auth">
    <form (ngSubmit)="openIdConnect()">

      <div *ngIf="externalAuth?.msgHeader != ''" class="msg-header" [innerHTML]="externalAuth?.msgHeader | safe"></div>

      <input *ngIf="externalAuth?.providerButtonImage else oidcButtonWithoutImage" type="image"
        [src]="externalAuth?.providerButtonImage" class="open-id-button"
        [value]="'S\'identifier avec '+externalAuth?.providerName">

      <ng-template #oidcButtonWithoutImage>
        <button id="btnOidcLogin" mat-raised-button color="primary" type="submit" class="open-id-button">
          <span>S'identifier avec {{externalAuth?.providerName}}</span>
        </button>
      </ng-template>


      <div *ngIf="externalAuth?.linkInfoLabel !== '' && externalAuth?.linkInfoUrl !== ''" class="">
        <a [href]="externalAuth?.linkInfoUrl" target="_blank">
          {{externalAuth?.linkInfoLabel}}
          <i class="fas fa-external-link-alt"></i>
        </a>
      </div>

      <div class="choice-separator">
        Ou
      </div>
    </form>
  </div>

  <form (ngSubmit)="login()" [formGroup]="loginForm">
    <div fxLayout="row" fxLayoutAlign="center center" class="login-error warn-bg" *ngIf="error" translate>
      <mat-icon style="margin-right: 5px;">error</mat-icon> <span>Nom d'utilisateur ou mot de passe incorrect.</span>
    </div>

    <div fxLayout="column" class="content">
      <mat-form-field>

        <mat-label>Adresse e-mail</mat-label>

        <input type="email" matInput formControlName="username" autocomplete="username" [appFocusOnShow]="!homeCard"
          name="portailfamilles_login" />

        <mat-error
          *ngIf="loginForm.controls.username.invalid && (loginForm.controls.username.dirty || loginForm.controls.username.touched) ">
          <span translate>Nom d'utilisateur requis</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input type="password" matInput formControlName="password" [type]="showPass ? 'text' : 'password'"
          autocomplete="current-password" placeholder="Mot de passe" required name="portailfamilles_password" />
        <mat-icon matSuffix (click)="showPass = !showPass" style="cursor:pointer;">
          {{ showPass ? 'visibility_off' : 'visibility' }}
        </mat-icon>
        <mat-error
          *ngIf="loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched)">
          <span translate>Mot de passe requis</span>
        </mat-error>
      </mat-form-field>

      <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <mat-slide-toggle formControlName="remember" color="primary">{{'remember_me'|trans}}</mat-slide-toggle>
        <a href="#" routerLink="/forgot-password" style="margin: 10px; cursor: pointer;" translate>
          forgot_your_password
        </a>
      </div>

      <button id="btnLogin" mat-raised-button color="primary" type="submit" [disabled]="normalLoginLoading">
        <span translate>login</span>
        <app-loader size="1" class="" *ngIf="normalLoginLoading"></app-loader>
      </button>

      <div class="new" *ngIf="permService.hasPermission('account_create')">
        <span class="mat-h3"> {{'register_label'|trans}} </span>
        <button type="button" id="btn-register" mat-button color="accent" href="#" routerLink="/register">
          {{'register_button'|trans}}
        </button>
      </div>

    </div>

  </form>
</ng-template>

<ng-template #redirection>
  <app-loader>
    <h3>{{ redirectingMessage|trans }}</h3>
  </app-loader>
</ng-template>
