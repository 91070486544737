import { Consumer } from '@app/models/consumer';
import { Injectable } from '@angular/core';
import { computeAge } from '@app/utils/date-functions';
import { BaseAdapterService } from './base-adapter.service';

@Injectable({ providedIn: 'root' })
export class ConsumerAdapter extends BaseAdapterService<Consumer> {
  adapt(item: any): Consumer {
    const consumer = item as Consumer;

    consumer.id = Consumer.buildIdConsumer(item.idEnfant, item.idAdulte);
    consumer.idEnfant = item.idEnfant;
    consumer.idAdulte = item.idAdulte;


    if (consumer.dateNaissance) {
      consumer.dateNaissance = new Date(consumer.dateNaissance);
      consumer.age = computeAge(consumer.dateNaissance);
    } else {
      consumer.age = {years: 0, months: 0};
    }

    return consumer;
  }
}
