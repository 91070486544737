import { SmtpAccount } from '@app/services/mail.service';

export enum AddressApiMode {
  GOUV, // 0 = gouv (default)
  DOMINO // 1 = our own API
}

export type FormType = 'form-user' | 'form-adulte' | 'form-enfant' | 'form-pre-inscription' | 'form-famille' | 'form-assmat' | 'form-contrat-accueil-mentalo';

export interface GlobalConfig {
  header?: HeaderConfig;
  permissions?: PermissionConfig;
  sessions?: SessionConfig;

  addressApiMode?: AddressApiMode;

  externalAuth?: OpenIdConfig;

  // Only keys we want to give custom (might be reverted to full NgcCookieConsentConfig, if we get many more parameters ..)
  cookie?: CookieConfig;

  email?: {
    smtp?: number | SmtpAccount;
    signature?: string;
  };
}

export interface CookieConfig {
  domain?: string;
  text?: string;
  dismiss?: string;
}

export interface PermissionConfig {
  account_create?: boolean;
  account_edit?: boolean;
  child_create?: boolean;
  child_edit?: boolean;
  preinscription_create?: boolean;
  preinscription_edit?: boolean;
  reservation_create?: boolean;
  foyer_edit?: boolean;
}

export interface HeaderConfig {
  title: {
    enabled: boolean,
    text: string,
    style: any
  };

  logo: {
    enabled: boolean,
    image: string,
    height: number,
    width: number
  };

  favicon?: string;

  banner: BannerConfig;

  // banners: BannerConfig[];
}

export type BannerConfig = string;

// export interface BannerConfig {
//   enabled: boolean;
//   image: string;
//   height: number;
//   title: string;
//   position: number;
//   opacity: number;

//   titleStyle?: any;
//   pages?: string[];

//   // for display, front only
//   pageNames?: string[];
// }

export interface SessionConfig {
  dureeValidite?: number;
  dureeValiditeLienCreationCompteDepuisPortail?: number;
  dureeValiditeLienCreationCompteDepuisDomino?: number;
  dureeValiditeLienReinitialisationMPDepuisDomino?: number;
  dureeValiditeLienReinitialisationMPDepuisPortail?: number;
  dureeValiditeLienModificationEmail?: number;
}

export interface OpenIdConfig {
  identityProvider: string;
  providerName: string;
  providerLogo: string;
  providerButtonImage: string;

  clientId: string;
  clientSecret: string;

  urlAuthorization: string;
  urlToken: string;
  urlUserInfo: string;
  urlLogout: string;
  scopes: string;

  msgHeader: string;
  linkInfoLabel: string;
  linkInfoUrl: string;

}
