import { Component, OnInit, Inject, ViewChildren, ElementRef, QueryList, AfterViewInit } from '@angular/core';
import { LocaleService } from './services/locale.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, first, distinctUntilChanged, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable, forkJoin, of } from 'rxjs';
import { ThemeService } from './services/theme.service';
import { AuthenticationService, GlobalConfigService, PermissionService, SessionService, SnackbarService, UserService } from './services';
import { FamilyService } from './services/family.service';
import moment from 'moment';
import { DOCUMENT } from '@angular/common';
import { Role } from './models/user';
import { CacheService } from './services/cache.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ConfirmEmailComponent } from './components/_elements/confirm-email/confirm-email.component';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { HeaderService } from './services/header.service';
import { BoardConfig } from './models/board-card';
import { CookieConfig } from './models/global-config';
import { OverlayContainer } from '@angular/cdk/overlay';
import { BaseConfigService, loadConf } from './services/config/base.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  accountBoard: BoardConfig;
  AssmatActivedAccountPerm: boolean;

  title = 'portail';
  dominoConnect: boolean;

  // This used to be a Subject / Observable but happens only once, so for now keep it simple
  loaded = false;

  @ViewChildren('themeWrapper') themeWrapper: QueryList<ElementRef>;

  themeClass = 'light-theme';
  customOrDefaultClass = 'default-theme';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private localeService: LocaleService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
    private authService: AuthenticationService,
    private userService: UserService,
    private familyService: FamilyService,
    private globalConfigService: GlobalConfigService,
    private headerService: HeaderService,
    private cacheService: CacheService,
    private snackbarService: SnackbarService,
    private permService: PermissionService,
    private bottomSheet: MatBottomSheet,
    private cookieService: NgcCookieConsentService,
    private themeService: ThemeService,
    private overlayContainer: OverlayContainer,
    private baseConfigService: BaseConfigService,
  ) { }

  ngOnInit() {
    moment.locale('fr');

    this.dominoConnect = location.href.includes('dominoConnect')

    const oidcLogoutCallback = location.href.includes('logout') // callback for openIdConnect logout
      || location.href.includes('oidc-login-callback') // callback for openIdConnect logout
      || location.href.includes('login-callback') // callback for openIdConnect logout

    this.sessionService.init(this.dominoConnect)
    this.authService.init(!oidcLogoutCallback)

    this.baseConfigService.getFirstLoadConfs().subscribe((confsModif: loadConf[]) => {

      this.initApp()

    }, err => console.error("Erreur lors du chargement des configs", err))

    this.cacheService.initNewAppChecker();

    const overlayContainerElement = this.overlayContainer.getContainerElement();

    this.themeService.darkMode$.subscribe(darkMode => this.themeClass = darkMode ? 'dark-theme' : 'light-theme');
    this.themeService.isCustom$.subscribe(isCustom => this.customOrDefaultClass = (isCustom ? 'custom' : 'default') + '-theme');

    merge(
      this.themeService.darkMode$,
      this.themeService.isCustom$
    ).subscribe(_ => {
      overlayContainerElement.classList.remove('dark-theme', 'light-theme', 'custom-theme', 'default-theme');
      overlayContainerElement.classList.add(this.themeClass, this.customOrDefaultClass);
    });
  }

  ngAfterViewInit() {
    this.themeWrapper.changes.pipe(
      map(res => res.first),
      filter(x => !!x),
      first()
    ).subscribe(el => this.exposePrimaryColorForTimepicker(el));
  }

  initApp() {
    this.localeService.init();

    if (!this.dominoConnect) {
      // don't immediately check for user session if is connecting from Domino
      this.authService.startSessionChecker();
    }

    // Contains every loader required for app starts in good condition (theme, globalConfig)
    // (/!\ Should avoid too long loading ...)
    // @NB: GlobalConfig contains PermissionConfig, which is needed in Routes config itself, so better be loaded at start
    const appLoaders: Observable<any>[] = [
      this.themeService.loadTheme(),
      this.globalConfigService.init(),
    ];

    forkJoin(appLoaders).subscribe(_ => {
      this.loaded = true;
      this.clearRootSpinner();
      this.showCookieConsent();
    });


    // Must differ that, because when load on 'logout' page (oidc logout callback), 
    // we don't want to refreshUserDataOnChange before logout stuff...
    setTimeout(() => { this.refreshUserDataOnChange(); })

    this.authService.accountType$.subscribe(type => {
      if (type === 'family' || type === 'assmat') {
        this.accountBoard = type === 'family' ? this.baseConfigService.getFirstConf("board-user").content : this.baseConfigService.getFirstConf("board-assmat").content;
      } else if (type === 'noAccount') {
        this.snackbarService.error(this.translateService.instant('no_account_err_message'));
      } else {
        this.accountBoard = null;
      }

      this.headerService.refreshPageTitle(this.activatedRoute, this.router.url, this.accountBoard);
      this.headerService.refreshBreadcrumbs(this.activatedRoute);
    });

    merge(
      this.translateService.onLangChange,
      this.router.events.pipe(filter(e => e instanceof NavigationEnd))
    ).subscribe(() => {
      this.headerService.refreshBreadcrumbs(this.activatedRoute.root);
      this.headerService.refreshPageTitle(this.activatedRoute, this.router.url, this.accountBoard);
    });
  }

  refreshUserDataOnChange() {
    // Watch for User logs in/out and update linked data
    // => Loads User data from API when app starts, no use for localStorage.currentUser then ?
    this.authService.currentUser$.pipe(
      // A "real" user change is when the User.id changes
      distinctUntilChanged((x, y) => x?.id === y?.id)
    ).subscribe(user => {

      if (user) {
        // @TODO: maybe move these to "login" response, could even merge these data directly in "User" .. ?

        // If User is linked to an Adulte, load it (better always be the case, for now)
        if (user.role === Role.User && user.idAdulte) {
          // Reload the Adulte linked to current User, only when needed ...
          this.userService.reloadCurrentAdulte().subscribe(adulte => {

            if (!adulte.emailConfirm && !this.userService.userCreated) {
              this.bottomSheet.open(ConfirmEmailComponent);
            }

            if (adulte.families) {
              this.familyService.defineCurrentFamily(adulte.families);
            }

            if (adulte.assistantMaternel) {
              this.authService.storeIdAssmat(adulte.assistantMaternel.idAssistantMaternel);
            }

            this.permService.permission$.subscribe(perm => {
              if (!perm.enabled_assmat_account) {
                this.authService.setAccountType('family');
              } else {
                this.authService.setAccountType(user.accountType ||
                  (adulte.assistantMaternel && localStorage.getItem('remember-account-type') === 'assmat' ?
                    'assmat' : this.familyService.currentFamily ? 'family' : adulte.assistantMaternel ? 'assmat' : 'noAccount'));
              }

            })
          });
        } else if (user.role === Role.Admin || user.role === Role.Manager) {
          this.authService.setAccountType('admin');
        }

      } else {
        // Logout => clear everything
        this.userService.setCurrentAdulte(null);
        this.familyService.setCurrentFamily(null);
      }
    });
  }

  showCookieConsent() {
    this.globalConfigService.getPart<CookieConfig>('cookie').pipe(
      take(1),
      map(config => this.globalConfigService.getCookieConsentFromConfig(config))
    ).subscribe(config => {
      this.cookieService.init(config);
    });
  }

  exposePrimaryColorForTimepicker(el: ElementRef) {
    const themeWrapper = el.nativeElement;

    if (themeWrapper) {
      const primaryColor = getComputedStyle(themeWrapper).getPropertyValue('--primary');
      document.body.style.setProperty('--timepicker-primary', primaryColor);
    }
  }

  clearRootSpinner() {
    // Supprimer le loader de l'application
    const rootLoaderSpinner = this.document.querySelector('.root-loader-container');

    if (!!rootLoaderSpinner) {
      rootLoaderSpinner.remove();
    }
  }


}
