import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormViewerComponent, SubmitFormEvent } from '@app/components/_public/form-viewer/form-viewer.component';
import { StepType } from '@app/forms/config/form-model';
import { FormType } from '@app/models/global-config';
import { FormConfigService, FormHelperService, PermissionService, UserService } from '@app/services';
import { AssmatService } from '@app/services/assmat.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-assmat-info-edit',
  templateUrl: './assmat-info-edit.component.html',
  styleUrls: ['./assmat-info-edit.component.scss']
})
export class AssmatInfoEditComponent implements OnInit {

  idAssmat: number;
  step: string;
  readOnly: boolean;

  form: StepType[];
  form$: Observable<any>;
  data: any;
  isLoading = true;
  loadingMessage = 'Chargement';
  typeForm: FormType = 'form-assmat';

  formTitle: string;

  @ViewChild(FormViewerComponent) formViewer: FormViewerComponent;

  constructor(
    private formConfigService: FormConfigService,
    private helperService: FormHelperService,
    private route: ActivatedRoute,
    private permService: PermissionService,
    private userService: UserService,
    private assmatService: AssmatService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.readOnly = !this.permService.hasPermission('informations_assmat_edit');
    this.idAssmat = this.userService.currentUser.idAssmat || null;
    this.step = this.route.snapshot.paramMap.get('step');
    this.form$ = this.assmatService.getFormData(this.idAssmat, this.step);

    this.form$.subscribe(form => {
      this.form = this.formConfigService.getFormView(form.config).filter(f => f.enabled);
      this.data = !form.data ? { modeCreation: true } : form.data;

      this.formTitle = this.form[0].label;
      this.isLoading = false;
    })
  }

  onSave(event: SubmitFormEvent) {
    const data = event.model;
    this.formViewer.setErrorMessage('');
    this.loadingMessage = 'Enregistrement';

    if (this.readOnly) {
      return;
    }

    this.isLoading = true;

    this.assmatService.update(data, this.idAssmat, this.step).subscribe((result: any) => {
      this.helperService.displayDebugTraces(result.traces);
      this.helperService.notifySuccess('Modification effectuée',result.messages);  
      this.location.back();
    }, err => {
      this.isLoading = false;

      setTimeout(() => { // doit être encadré dans un setTimeout car sinon le 'formViewer' n'est pas accessible (car il était masqué par 'isLoading')...
        this.helperService.manageServerError(err,this.formViewer);
      })
    });

  }


}
