import { Injectable } from '@angular/core';
import { User, Role } from '@app/models/user';
import { HttpClient } from '@angular/common/http';
import { ApiCrudService } from './api-crud.service';
import { AuthenticationService } from './authentication.service';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { Adulte } from '@app/models/adulte';
import { FamilyService } from './family.service';
import { tap } from 'rxjs/operators';

// Difference between User and Adult is here a bit confusing ...
// @TODO: rename to "AdulteService"
@Injectable({ providedIn: 'root' })
export class UserService extends ApiCrudService<User> {

  url = 'users';
  formUrl = 'users/#idUser#/form/#formPart#';

  userCreated: boolean = false;

  private currentAdulteSubject = new BehaviorSubject<Adulte>(null);
  currentAdulte$ = this.currentAdulteSubject.asObservable();

  constructor(
    protected http: HttpClient,
    private authService: AuthenticationService,
    private familyService: FamilyService
  ) {
    super();
  }

  // shortcut to "AuthService", confusion caused by this service name ..
  get currentUser() {
    return this.authService.currentUserValue;
  }

  get currentAdulte() {
    return this.currentAdulteSubject.value;
  }

  reloadCurrentAdulte() {
    return this.getCurrentUserData().pipe(
      tap(data => this.setCurrentAdulte(data))
    );
  }

  setCurrentAdulte(adulte: Adulte) {
    this.currentAdulteSubject.next(adulte);
  }

  create(data: User | any) {
    this.userCreated = true;
    return this.http.post(this.url, data, this.httpOptions);
  }

  update(data: User | any, id?: number, part?: string) {
    part = part ? part : '';
    return this.http.put(`${this.url}/${id}/${part}`, data, this.httpOptions);
  }

  getCurrentUserData(): Observable<Adulte> {
    if (this.currentUser && this.currentUser.role === Role.User) {
      return this.http.get<Adulte>(this.url + '/' + this.currentUser.id);
    }

    return of(null);
  }

  getFormData(id, part?) {
    part = part ? part : '';
    const url = this.formUrl.replace('#idUser#', id).replace('#formPart#', part);
    return this.http.get(url, this.httpOptions);
  }

  updatePassword(data: object) {
    return this.http.post(`${this.url}/update-password`, data);
  }

  checkLoginAvailability(login: string) {
    return this.http.post(`${this.url}/login-availability`, { login });
  }

  getTokenType(token: string) {
    return this.http.get<{ type: string, user: User, errorMessage?: string }>(`${this.url}/token-info/${token}`);
  }

  confirmEmail(token: string) {
    return this.http.post<any>(`${this.url}/confirm-email`, { token });
  }

  getCurrentFamilyAdults() {
    return this.http.get<Adulte[]>(this.familyService.getCurrentFamilyDependantUrl('adultes'), this.httpOptions);
  }

  sendConfirmEmail(user: User) {
    return this.http.post(`${this.url}/envoi-confirm-email`, user, this.httpOptions);
  }
}
