<mat-checkbox *ngIf="paramLibre.nature === 'Booléen'" [(ngModel)]="paramLibre.valeur"
  [required]="paramLibre.obligatoire"
  style="width: 100%; text-align: left;">
  {{label}}
</mat-checkbox>

<mat-form-field *ngIf="paramLibre.nature === 'Chaîne' || paramLibre.nature === 'Numérique'" style="width: 100%;">
  <mat-label>{{label}}</mat-label>
  <input matInput [type]="paramLibre.nature === 'Chaîne' ? 'text' : 'number'" [required]="paramLibre.obligatoire"
    [(ngModel)]="paramLibre.valeur">
</mat-form-field>

<mat-form-field *ngIf="paramLibre.nature === 'Date'" style="width: 100%;">
  <mat-label>{{label}}</mat-label>
  <input matInput [matDatepicker]="picker" [(ngModel)]="paramLibre.valeur" [required]="paramLibre.obligatoire"
    placeholder="jj/mm/aaaa">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

<mat-form-field *ngIf="paramLibre.nature === 'Liste'" style="width: 100%;">
  <mat-label>{{label}}</mat-label>
  <mat-select [(ngModel)]="paramLibre.idParamLibreValeur" [required]="paramLibre.obligatoire">
    <mat-option *ngFor="let option of paramLibre.options" [value]="option.idParamLibreValeur">
      {{ option.designation }}
    </mat-option>
  </mat-select>
</mat-form-field>