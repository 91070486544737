import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { SharedModule } from './shared.module';
import { PublicModule } from './public.module';

import { AuthenticationGuard } from '@app/guards/authentication.guard';
import { PermissionGuard } from '@app/guards/permission.guard';

import { UserEditComponent } from '@app/components/_public/user-edit/user-edit.component';
import { ChildEditComponent } from '@app/components/_user/children/edit/child-edit.component';
import { PreinscriptionEditComponent } from '@app/components/_user/preinscription/edit/preinscription-edit.component';
import { UserReservationEditComponent } from '@app/components/_user/reservation/edit/edit.component';
import { PlanningSelectDialogComponent } from '@app/components/_user/reservation/planning/select-dialog/select-dialog.component';
import { AccountComponent } from '@app/components/_user/account/account.component';
import { UserInfoComponent } from '@app/components/_user/user-info/user-info.component';
import { AssmatInfoComponent } from '@app/components/_user/assmat-info/assmat-info.component';
import { PreinscriptionListComponent } from '@app/components/_user/preinscription/list/preinscription-list.component';
import { ChildrenListComponent } from '@app/components/_user/children/list/children-list.component';
import { FactureComponent } from '@app/components/_user/facture/facture.component';
import { ReservationsComponent } from '@app/components/_user/reservation/reservations.component';
import { PaiementComponent } from '@app/components/_user/facture/paiement/paiement.component';
import { FoyerComponent } from '@app/components/_user/foyer/foyer.component';
import { FoyerEditComponent } from '@app/components/_user/foyer/foyer-edit/foyer-edit.component';
import { ConjointEditComponent } from '@app/components/_user/conjoint-edit/conjoint-edit.component';
import { PlanningDetailsDialogComponent } from '@app/components/_user/reservation/planning/details-dialog/details-dialog.component';
import { GedComponent } from '@app/components/_user/ged/ged.component';
import { PresenceRepeatComponent } from '@app/components/_user/reservation/planning/presence-repeat/presence-repeat.component';
import { ReservationPanierComponent } from '@app/components/_user/reservation/panier/panier.component';
import { MikadoNewPresenceComponent } from '@app/components/_user/reservation/planning/mikado-new-presence/mikado-new-presence.component';
import { AssmatPointageComponent } from '@app/components/_user/assmat-pointage/assmat-pointage.component';
import { TicketDematComponent } from '@app/components/_user/ticket-demat/ticket-demat.component';
import { AchatTicketComponent } from '@app/components/_user/ticket-demat/achat-ticket/achat-ticket.component';
import { EditionListComponent } from '@app/components/_user/edition/edition-list.component';
import { LasidoPreinscriptionComponent } from '@app/components/_user/lasido-preinscription/lasido-preinscription.component';
import { LasidoPreinscriptionEditComponent } from '@app/components/_user/lasido-preinscription/edit/edit.component';
import { AssmatInfoEditComponent } from '@app/components/_user/assmat-info/assmat-info-edit/assmat-info-edit.component';
import { AssmatAvailabilityComponent } from '@app/components/_user/assmat-info/assmat-availability/assmat-availability.component';
import { ContratAccueilMentaloListComponent } from '@app/components/_user/contrats-accueil-mentalo/contrat-accueil-mentalo-list/contrat-accueil-mentalo-list.component';
import { ContratAccueilMentaloEditComponent } from '@app/components/_user/contrats-accueil-mentalo/contrat-accueil-mentalo-edit/contrat-accueil-mentalo-edit.component';
import { InscriptionAnimationsComponent } from '@app/components/_user/animations/inscription-animations/inscription-animations.component';
import { InscriptionReunionsComponent } from '@app/components/_user/reunions/inscription-reunions/inscription-reunions.component';
import { ListAnimationsComponent } from '@app/components/_user/animations/list-animations/list-animations.component';
import { ListReunionsComponent } from '@app/components/_user/reunions/list-reunions/list-reunions.component';
import { PresenceListComponent } from '@app/components/_user/reservation/planning/presence-list/presence-list.component';
import { LasidoInscriptionComponent } from '@app/components/_user/lasido-inscription/lasido-inscription.component';
import { RenouvellementInscriptionComponent } from '@app/components/_user/lasido-inscription/renouvellement-inscription/renouvellement-inscription.component';
import { CogitoPreinscriptionComponent } from '@app/components/_user/cogito-preinscription/cogito-preinscription.component';
import { EditCogitoPreinscriptionComponent } from '@app/components/_user/cogito-preinscription/edit-cogito-preinscription/edit-cogito-preinscription.component';
import { ListAnimationsReunionsComponent } from '@app/components/_user/list-animations-reunions/list-animations-reunions.component';
import { EditPhotoComponent } from '@app/components/_user/children/edit-photo/edit-photo.component';
import { CogitoInscriptionsComponent } from '@app/components/_user/cogito-inscriptions/cogito-inscriptions.component';
import { EditCogitoInscriptionComponent } from '@app/components/_user/cogito-inscriptions/edit-cogito-inscription/edit-cogito-inscription.component';


const userRoutes = [
  {
    path: 'account', canActivate: [AuthenticationGuard], data: { title: 'Mon compte' }, children: [
      // Account index
      { path: '', pathMatch: 'full', component: AccountComponent, data: { title: 'Mon compte' } },

      // Generic paths for every component
      { path: 'user', component: UserInfoComponent },
      { path: 'assmat', component: AssmatInfoComponent, canActivate: [PermissionGuard], data: { perm: 'enabled_assmat_account' } },
      { path: 'contrat-accueil-mentalo', component: ContratAccueilMentaloListComponent },
      { path: 'animations', component: ListAnimationsComponent },
      { path: 'reunions', component: ListReunionsComponent },
      { path: 'preinscription', component: PreinscriptionListComponent },
      { path: 'lasido-preinscriptions', component: LasidoPreinscriptionComponent },
      { path: 'lasido-inscriptions', component: LasidoInscriptionComponent },
      { path: 'cogito-preinscriptions', component: CogitoPreinscriptionComponent },
      { path: 'cogito-inscriptions', component: CogitoInscriptionsComponent },
      { path: 'children', component: ChildrenListComponent },
      { path: 'reservations', component: ReservationsComponent },
      { path: 'factures', component: FactureComponent },
      { path: 'foyer', component: FoyerComponent },
      { path: 'documents', component: GedComponent },
      { path: 'assmat-pointage', component: AssmatPointageComponent, canActivate: [PermissionGuard], data: { disableAccountSwitch: true, perm: 'enabled_assmat_account' } },
      { path: 'editions', component: EditionListComponent },
      { path: 'tickets', component: TicketDematComponent },

      // handle possible former similar URLs for same component
      { path: 'profile', redirectTo: 'user' },
      { path: 'personal', redirectTo: 'user' },

      // Personal informations
      {
        path: 'user', data: { title: 'Utilisateur', disableAccountSwitch: true }, children: [
          { path: 'edit', component: UserEditComponent, canActivate: [PermissionGuard], data: { perm: 'account_edit' } },
          // Keep readonly access to part of account data (form disabled)
          { path: ':step', component: UserEditComponent },
        ]
      },
      //Assmat info
      {
        path: 'assmat', data: { title: 'Assistant maternel', disableAccountSwitch: true }, children: [
          { path: 'availability', component: AssmatAvailabilityComponent, canActivate: [PermissionGuard], data: { perm: 'disponibilites_edit', title: "Disponibilités" } },
          { path: 'edit', component: AssmatInfoEditComponent, canActivate: [PermissionGuard], data: { perm: 'informations_assmat_edit' } },
          { path: ':step', component: AssmatInfoEditComponent },
        ]
      },
      // Foyer
      {
        path: 'foyer', data: { title: 'Foyer', disableAccountSwitch: true }, children: [
          { path: 'edit', component: FoyerEditComponent, canActivate: [PermissionGuard], data: { perm: 'foyer_edit' } },
          { path: ':step', component: FoyerEditComponent },
        ]
      },
      // Conjoint
      {
        path: 'conjoint', data: { title: 'Conjoint', disableAccountSwitch: true }, children: [
          { path: 'edit', component: ConjointEditComponent, canActivate: [PermissionGuard], data: { perm: 'foyer_edit' } },
          { path: ':step', component: ConjointEditComponent },
        ]
      },

      // Children
      {
        path: 'children', data: { title: 'Enfants', disableAccountSwitch: true }, children: [
          {
            path: 'add', component: ChildEditComponent, canActivate: [PermissionGuard],
            data: { title: 'Nouvel enfant', perm: 'child_create' },
          },
          {
            path: 'edit/:id', component: ChildEditComponent, canActivate: [PermissionGuard],
            data: { perm: 'child_edit' },
          },
          // Keep readonly access to part child data (form disabled)
          { path: 'edit/:id/:step', component: ChildEditComponent }
        ]
      },
      //Contrats accueil mentalo
      {
        path: 'contrat-accueil-mentalo', data: { title: 'Contrat d\'accueil' }, children: [
          {
            path: 'add', component: ContratAccueilMentaloEditComponent, canActivate: [PermissionGuard],
            data: { title: "Nouveau contrat d'accueil", perm: 'contrat_accueil_create' }
          },
          {
            path: 'edit/:id', component: ContratAccueilMentaloEditComponent, canActivate: [PermissionGuard],
            data: { title: "Modification contrat d'accueil", perm: 'contrat_accueil_edit' }
          },
          {
            path: 'edit/:id/:step', component: ContratAccueilMentaloEditComponent
          }
        ]
      },

      {
        path: 'animations', data: { title: 'Animations' }, children: [
          {
            path: 'inscriptions', component: InscriptionAnimationsComponent, canActivate: [PermissionGuard],
            data: { title: "Inscriptions aux animations", perm: "animations_add" }
          }
        ]
      },

      {
        path: 'reunions', data: { title: 'Réunions' }, children: [
          {
            path: 'inscriptions', component: InscriptionReunionsComponent, canActivate: [PermissionGuard],
            data: { title: "Inscriptions aux réunions", perm: "reunions_add" }
          }
        ]
      },

      // Preinscriptions
      {
        path: 'preinscription', data: { title: 'Pré-inscriptions', disableAccountSwitch: true }, children: [
          {
            path: 'add', component: PreinscriptionEditComponent, canActivate: [PermissionGuard],
            data: { title: 'Nouvelle pré-inscription', perm: 'preinscription_create' }
          },
          {
            path: 'edit/:id', component: PreinscriptionEditComponent, canActivate: [PermissionGuard],
            data: { perm: 'preinscription_edit' },
          },
          { path: 'edit/:id/:step', component: PreinscriptionEditComponent }
        ]
      },

      // Diabolo : reservations
      {
        path: 'reservations', data: { title: 'Réservations', disableAccountSwitch: true }, children: [
          { path: 'panier', component: ReservationPanierComponent },
          { path: 'new', component: UserReservationEditComponent, canActivate: [PermissionGuard] },
          { path: ':id', component: UserReservationEditComponent }
        ]
      },

      // Facturation - Paiement
      { path: 'paiement/:regie', component: PaiementComponent, data: { disableAccountSwitch: true } },

      // Tickets dématarialisé
      {
        path: 'tickets', data: { title: 'Mes tickets', disableAccountSwitch: true }, children: [
          { path: 'achat', component: AchatTicketComponent, data: { title: 'Achat de tickets', disableAccountSwitch: true } },

        ]
      },

      // Lasido - Preinscriptions
      {
        path: 'lasido-preinscriptions', data: { title: 'Pré-inscriptions Lasido' }, children: [
          { path: 'new', component: LasidoPreinscriptionEditComponent, data: { title: 'Nouvelle pré-inscription' } },
          { path: ':id', component: LasidoPreinscriptionEditComponent, data: { title: "Modification d'un pré-inscription" } },
        ]
      },

      // Lasido - Inscriptions-renouvellement
      {
        path: 'lasido-inscriptions', data: { title: 'Inscriptions Lasido' }, children: [
          { path: ':id', component: RenouvellementInscriptionComponent, data: { title: 'Renouvellement inscription' } },
        ]
      },

      // Cogito - Preinscriptions
      {
        path: 'cogito-preinscriptions', data: { title: "Pré-inscriptions Scolaires" }, children: [
          { path: 'new', component: EditCogitoPreinscriptionComponent, canActivate: [PermissionGuard], data: { perm: 'cogito_create', title: "Nouvelle pré-inscription" } },
          { path: ':id', component: EditCogitoPreinscriptionComponent, canActivate: [PermissionGuard], data: { perm: 'cogito_edit', title: "Modification d'une pré-incsription" } },
        ]
      },
      // Cogito - Inscriptions
      {
        path: 'cogito-inscriptions', data: { title: "Inscriptions Scolaires" }, children: [
          { path: 'new', component: EditCogitoInscriptionComponent, canActivate: [PermissionGuard], data: { perm: 'cogito_inscriptions_create', title: "Nouvelle inscription" } },
          { path: ':id', component: EditCogitoInscriptionComponent, canActivate: [PermissionGuard], data: { perm: 'cogito_inscriptions_edit', title: "Modification d'une incsription" } },
        ]
      },
    ]
  }
] as Route[];

@NgModule({
  declarations: [
    ChildEditComponent,
    PreinscriptionEditComponent,
    UserReservationEditComponent,
    PlanningSelectDialogComponent,
    PlanningDetailsDialogComponent,
    MikadoNewPresenceComponent,
    PresenceRepeatComponent,
    AccountComponent,
    PaiementComponent,
    FoyerComponent,
    FoyerEditComponent,
    ConjointEditComponent,
    GedComponent,
    ReservationPanierComponent,
    AssmatPointageComponent,
    TicketDematComponent,
    AchatTicketComponent,
    LasidoPreinscriptionComponent,
    LasidoPreinscriptionEditComponent,
    AssmatInfoEditComponent,
    AssmatInfoComponent,
    AssmatAvailabilityComponent,
    ContratAccueilMentaloListComponent,
    ContratAccueilMentaloEditComponent,
    InscriptionAnimationsComponent,
    InscriptionReunionsComponent,
    ListAnimationsComponent,
    ListReunionsComponent,
    PresenceListComponent,
    LasidoInscriptionComponent,
    RenouvellementInscriptionComponent,
    CogitoPreinscriptionComponent,
    EditCogitoPreinscriptionComponent,
    ListAnimationsReunionsComponent,
    EditPhotoComponent,
    CogitoInscriptionsComponent,
    EditCogitoInscriptionComponent
  ],
  imports: [
    SharedModule,
    PublicModule,
    RouterModule.forRoot(userRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})
export class UserModule { }
