<ng-template appTopbarActions>
  <button mat-mini-fab color="accent-lighter" [matMenuTriggerFor]="moreMenu">
    <mat-icon>settings</mat-icon>
  </button>

  <mat-menu #moreMenu="matMenu">
    <button mat-menu-item (click)="onToggleInvalideFile()">
      <span *ngIf="!showInvalideFile">
        <mat-icon color="accent">visibility</mat-icon>
        Voir les fichiers expirés
      </span>
      <span *ngIf="showInvalideFile">
        <mat-icon color="warn">visibility_off</mat-icon>
        Cacher les fichiers expirés
      </span>
    </button>
  </mat-menu>
</ng-template>

<div *ngIf="config && loaded; else loader">

  <div class="container" [class.small-screen]="platformService.isMobile">

    <mat-card class="info-card" *ngIf="config.info" [portailCustomStyle]="config.styleCardInfo">
      <div [innerHtml]="config.info | safe"></div>
    </mat-card>

    <mat-accordion multi="false">

      <ng-container *ngFor="let type of knownTypes">
        <mat-expansion-panel *ngIf="dataByType[type]?.entities.length && dataByType[type] as typeData" [expanded]="type === firstType">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i *ngIf="type !== 'preinscription' && 'assistant maternel'" class="icon icodomino-{{ type }}"></i>
              <i *ngIf="type === 'preinscription'" class="icon icodomino-pre-inscription"></i>
              <i *ngIf="type === 'assistant maternel'" class="icon icodomino-assmat"></i>
              <span class="title">{{ ('ged.type.' + type)|trans|titlecase }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <mat-card *ngFor="let entite of typeData.entities" class="pieces-card">
              <mat-card-header *ngIf="entite.name !== ''">
                <mat-card-title>
                  <div></div>
                  <mat-icon>arrow_right</mat-icon>
                  <span>{{ entite.name }} <br>
                    <span *ngIf="entite.details"
                      style="font-size:small; font-style:italic;margin-right: 2px;">{{entite.details}}</span>
                    <span *ngIf="entite.dateDemande"
                      style="font-size:small; font-style:italic;">({{entite.dateDemande | date:"shortDate"}})</span></span>
                  <mat-icon *ngIf="entite.error" color="warn" class="error" [matTooltip]="entite.error"
                    matTooltipClass="tooltip-pre-line tooltip-large">
                    error_outline
                  </mat-icon>
                </mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <ng-container [ngTemplateOutlet]="panelContentCard" [ngTemplateOutletContext]="{
                  entite: entite,
                  pieces: typeData.pieces,
                  documents: typeData.documents
                }"></ng-container>
              </mat-card-content>
            </mat-card>
          </ng-template>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
</div>

<ng-template #panelContentCard let-pieces="pieces" let-entite="entite" let-documents="documents">
  <ng-container *ngFor="let piece of pieces">
    <div *ngIf="entite.typeStructure === piece.typeStructure" class="piece-container">
      <div class="piece-content">
        <div class="piece-title">
          <b>{{ piece.name }}</b>
          <a *ngIf="piece.pieceJointeObligatoire" class="doc-obligatoire" (click)="openPieceDetailsDialog(piece)">
            <div fxLayout="row" fxLayoutAlign="start center">
              (<mat-icon color="warn">warning</mat-icon>
              <span>Document obligatoire</span>)
            </div>
          </a>
        </div>

        <div class="piece-description">
          <div [innerHtml]="piece.description | safe"></div>
        </div>

        <div *ngIf="getDocumentsFor(documents, piece, entite) as filteredDocs" class="documents-list">
          <ng-container [ngTemplateOutlet]="docsList"
            [ngTemplateOutletContext]="{docs: filteredDocs, entite: entite, piece: piece, valid: true}"></ng-container>

          <ng-container *ngIf="showInvalideFile" [ngTemplateOutlet]="docsList"
            [ngTemplateOutletContext]="{docs: filteredDocs, entite: entite, piece: piece, valid: false}"></ng-container>
        </div>
      </div>

      <div class="piece-buttons">
        <button mat-mini-fab matTooltip="Informations" color="accent" (click)="openPieceDetailsDialog(piece)">
          <app-icon type="fa" icon="info"></app-icon>
        </button>

        <button mat-mini-fab color="primary" *ngIf="permService.hasPermission('document_add')"
          matTooltip="Ajouter un fichier" (click)="inputFile.click()">
          <input #inputFile type="file" hidden (change)="onFileSelect($event.target.files, entite, piece)">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>


</ng-template>

<ng-template #docsList let-docs="docs" let-entite="entite" let-piece="piece" let-valid="valid">
  <!-- @NB: this makes many container and loop item for nothing .. maybe filter before ? (getFilteredDocuments()) -->
  <ng-container *ngFor="let doc of docs">
    <ng-container *ngIf="doc.valid === valid && doc.idEntite === entite.id && doc.idPieceAFournir === piece.id">
      <div class="file" [matMenuTriggerFor]="documentMenu" [matMenuTriggerData]="{doc: doc}">

        <div *ngIf="documentDownload[doc.idDocumentElectronique] as progress; else icon" class="download-progress">
          <mat-progress-spinner [value]="progress" diameter="32">
          </mat-progress-spinner>
          <span>{{ progress }}%</span>
        </div>

        <ng-template #icon>
          <i *ngIf="getFileIcon(doc) as icon; else default" class="far fa-file-{{ icon }}" [class.invalid]="!valid"></i>
          <ng-template #default><i class="far fa-file"></i></ng-template>
        </ng-template>

        <div class="name-file">
          {{ doc.nomFichier }}
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<mat-menu #documentMenu>
  <ng-template matMenuContent let-doc="doc">
    <button mat-menu-item (click)="openDocDetails(doc)">
      <mat-icon color="primary">visibility</mat-icon>
      <span>Détails</span>
    </button>
    <button mat-menu-item (click)="downloadDocument(doc)">
      <mat-icon color="accent">get_app</mat-icon>
      <span>Télécharger</span>
    </button>
    <button mat-menu-item (click)="deleteDocument(doc)" [disabled]="doc.idDocumentElectronique > 0">
      <mat-icon color="warn">delete</mat-icon>
      <span>Supprimer</span>
    </button>
  </ng-template>
</mat-menu>

<ng-template #documentDetails let-data>
  <mat-toolbar mat-dialog-title color="primary">
    <button mat-icon-button [mat-dialog-close] class="close-dialog">
      <mat-icon>close</mat-icon>
    </button>
    <span class="title">Détails : {{ data.doc.nomFichier }}</span>
  </mat-toolbar>

  <mat-dialog-content class="document-details thin-scrollbar">
    <div class="details-list">
      <!-- @NB: we'll need entity here to get "for child / family / etc." text, like in upload dialog -->
      <h3 style="text-align: center;">{{ data.piece.name }}</h3>
      <p><label>Date d'ajout :</label><span>{{ data.doc.dateHeureAjout|date:'dd/MM/yyyy' }}</span></p>
      <p><label>Date de fin de validité :</label><span>{{ data.doc.dateFinValidite|date:'dd/MM/yyyy' }}</span></p>
    </div>

    <div class="preview-container">
      <h4>Aperçu :</h4>
      <app-loader *ngIf="loadingPreview; else preview">Chargement de l'aperçu</app-loader>
      <ng-template #preview>
        <ng-container [ngTemplateOutlet]="filePreview"
          [ngTemplateOutletContext]="{extension: getExtension(data.doc.nomFichier)}">
        </ng-container>
      </ng-template>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions align="center">
    <button mat-raised-button color="primary" [mat-dialog-close]>Fermer</button>
    <button mat-raised-button color="accent" (click)="downloadDocument(data.doc)">Télécharger</button>
  </div>
</ng-template>

<ng-template #pieceDetailsDialog let-data>
  <mat-toolbar mat-dialog-title color="primary">
    <button mat-icon-button [mat-dialog-close] class="close-dialog">
      <mat-icon>close</mat-icon>
    </button>
    <span class="title">{{ data.piece.name }} : Informations</span>
  </mat-toolbar>

  <div mat-dialog-content class="thin-scrollbar">

    <ng-container *ngIf="data.mandatoryAccueils.length" [ngTemplateOutlet]="accueilsList"
      [ngTemplateOutletContext]="{accueils: data.mandatoryAccueils, mandatory: true}"></ng-container>

    <ng-container *ngIf="data.optionalAccueils.length" [ngTemplateOutlet]="accueilsList"
      [ngTemplateOutletContext]="{accueils: data.optionalAccueils, mandatory: false}"></ng-container>

  </div>
</ng-template>

<ng-template #accueilsList let-accueils="accueils" let-forced="mandatory">
  <div>
    <h4>
      <app-icon *ngIf="forced" type="fa" icon="exclamation-triangle" class="icon-exclamation"></app-icon>
      Ce document est {{ forced ? 'obligatoire' : 'demandé' }} dans
      {{ accueils.length > 1 ? 'les Établissements / Accueils suivants :' : 'l\'Établissement / Accueil suivant :' }}
    </h4>

    <div *ngFor="let accueil of accueils" class="accueil-list-item">
      <app-icon type="do" icon="accueil" class="icon"></app-icon>
      <span class="name">
        {{ (accueil.nameEtablissement + ' ' + accueil.name).trim() }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #fileUploadDialog let-data>
  <mat-toolbar mat-dialog-title color="primary">
    <button mat-icon-button [mat-dialog-close] class="close-dialog">
      <mat-icon>close</mat-icon>
    </button>
    <span class="title">
      Sélection
    </span>
  </mat-toolbar>

  <div mat-dialog-content class="thin-scrollbar">
    <h3 style="text-align: center;">{{ data.piece.name }} pour
      <span>{{ (data.entite.type === 'famille' ? 'la famille ' : '') + data.entite.name }}</span>
    </h3>

    <ng-container [ngTemplateOutlet]="filePreview"
      [ngTemplateOutletContext]="{extension: getExtension(uploadingFile.name) }">
    </ng-container>

    <div class="uploading-file-name">
      Fichier à envoyer :
      <span>{{ uploadingFile.name }}</span>
    </div>

    <mat-progress-bar *ngIf="uploadProgress !== null" [value]="uploadProgress"></mat-progress-bar>
  </div>

  <div mat-dialog-actions align="center">

    <ng-container *ngIf="!errorMessage; else uploadError">
      <button mat-raised-button color="warn" (click)="cancelUpload()">Annuler</button>
      <button mat-raised-button color="primary" (click)="onUploadSubmit(data.entite, data.piece)"
        [disabled]="uploadProgress !== null">
        Envoyer
      </button>
    </ng-container>

    <ng-template #uploadError>
      <span class="err-message">
        <i class="fas fa-exclamation-triangle icon-exclamation"></i>
        <span [innerHtml]="errorMessage | safe"></span>
      </span>
    </ng-template>
  </div>
</ng-template>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>

<ng-template #filePreview let-extension="extension">
  <div *ngIf="previewURL" style="text-align: center;">
    <pdf-viewer *ngIf="extension == 'pdf'" [src]="previewURL" class="pdf-viewer" [render-text]="false"
      [original-size]="false"></pdf-viewer>
    <img *ngIf=" extension !=='pdf'" [src]="previewURL" class="image-viewer">
  </div>

  <div *ngIf="!previewURL" class="no-preview">
    <app-icon *ngIf="!extension.startsWith('doc')" type='fa' size="100" icon="file-alt">
    </app-icon>
    <app-icon *ngIf="extension.startsWith('doc')" type='fa' size="100" icon="file-word">
    </app-icon>

    <div style="text-align: center;font-size: large;">Visuel non disponible</div>
  </div>
</ng-template>
