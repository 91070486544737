import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { User } from '@app/models/user';
import { AuthenticationService, SnackbarService, TypeSnackbar } from '@app/services';
import { finalize, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-user-update-email',
  templateUrl: './update-email.component.html',
  styleUrls: ['./update-email.component.scss']
})
export class UpdateEmailComponent implements OnInit {

  @Input() user: User;
  @Input() inDialog: boolean; // wheter should display in MatDialog context, else full page

  @Output() done = new EventEmitter();
  @Output() cancel = new EventEmitter();

  newEmail = new FormControl('', [Validators.required, Validators.email]);

  saving: boolean; // true while saving (sending request to server)

  constructor(
    private authService: AuthenticationService,
    private snackbar: SnackbarService
  ) { }

  ngOnInit(): void {

    this.newEmail.valueChanges.pipe(
      distinctUntilChanged()
    )
      .subscribe((value: string) => {
        this.newEmail.patchValue(value.toLowerCase());
      })


  }

  onConfirm() {
    if (this.saving || this.newEmail.invalid) {
      return;
    }

    // Send request to server (+ display a small loader)
    this.saving = true;
    this.authService.updateEmail(this.user, this.newEmail.value).pipe(finalize(() => this.saving = false)).subscribe(resp => {
      if (resp.status === 'ok') {
        // tell the user what to do next, in SnackBar, and close (may instead stay in the dialog, sliding to a message)
        this.snackbar.open({ message: 'update_email.email_sent_instruction', type: TypeSnackbar.info, textButton: 'OK' });
        this.done.emit();
      } else {
        // tell the user what's wrong, based on server response
        this.snackbar.error('update_email.status.' + resp.status);
      }
    });
  }

  onCancel() {
    this.cancel.emit();
  }
}
