import { Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { UserService } from 'src/app/services/user.service'
import { FamilyService } from 'src/app/services/family.service';
import { map } from 'rxjs/operators';
import { addressToUpdate } from '@app/models/address';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { FormConfigService } from '.';


@Injectable({
  providedIn: 'root'
})
export class AdresseUpdateService {

  clickToUpdateAddress$: Subject<boolean> = new Subject();

  constructor(
    private userService: UserService,
    private familyService: FamilyService,
    private snackbar: SnackbarService,
    private formConfig: FormConfigService
  ) { }

  getDataUser(dataUpdateAddress: addressToUpdate) {
    let { codePostal, numRue, rue, rue2, rue3, ville, idRue, ...dataWithoutAddress } = dataUpdateAddress.user;
    return dataWithoutAddress;
  }

  getDataConjoint(dataUpdateAddress: addressToUpdate) {
    let { codePostal, numRue, rue, rue2, rue3, ville, idRue, ...dataWithoutAddress } = dataUpdateAddress.conjoint;
    return dataWithoutAddress;
  }

  getDataFoyer(dataUpdateAddress: addressToUpdate) {
    let { codePostal, numRue, rue, rue2, rue3, ville, idRue, ...dataWithoutAddress } = dataUpdateAddress.famille;
    return dataWithoutAddress;
  }

  getConjointCoordinatesUpdated(typeForm: string, dataUpdateAddress: addressToUpdate) {
    if (typeForm === "foyer") {
      return { ...dataUpdateAddress.familyAddress, ...this.getDataConjoint(dataUpdateAddress) }
    }
    if (typeForm === "user") {
      return { ...dataUpdateAddress.userAddress, ...this.getDataConjoint(dataUpdateAddress) }
    }
  }

  getUserCoordinatesUpdated(typeForm: string, dataUpdateAddress: addressToUpdate) {
    if (typeForm === "foyer") {
      return { ...dataUpdateAddress.familyAddress, ...this.getDataUser(dataUpdateAddress) }
    }
    if (typeForm === "conjoint") {
      return { ...dataUpdateAddress.conjointAddress, ...this.getDataUser(dataUpdateAddress) }
    }
  }

  getFoyerCoordinatesUpdated(typeForm: string, dataUpdateAddress: addressToUpdate) {
    if (typeForm === "user") {
      return { ...dataUpdateAddress.userAddress, ...this.getDataFoyer(dataUpdateAddress) }
    }
    if (typeForm === "conjoint") {
      return { ...dataUpdateAddress.conjointAddress, ...this.getDataFoyer(dataUpdateAddress) }
    }
  }

  updateAddressUser(typeForm: string, dataUpdateAddress: addressToUpdate) {
    let coordinatesData;

    if (typeForm === "foyer") {
      coordinatesData = this.getUserCoordinatesUpdated(typeForm, dataUpdateAddress);
    }

    if (typeForm === "conjoint") {
      coordinatesData = this.getUserCoordinatesUpdated(typeForm, dataUpdateAddress);
    }

    this.userService.update(coordinatesData, this.userService.currentUser.id, 'coordinates').subscribe(x => {
      this.clickToUpdateAddress$.next(true);
      this.notifySuccess();
    }, err => {
      const errMsg = `Erreur lors de la modification des coordonnées de ${dataUpdateAddress.user.civilite}
         ${dataUpdateAddress.user.nom} ${dataUpdateAddress.user.prenom} :`;
      this.notifyError('form-adulte', err, errMsg);
    }
    );
  }

  updateAddressConjoint(typeForm: string, dataUpdateAddress: addressToUpdate) {
    let coordinatesData;

    if (typeForm === "user") {
      coordinatesData = this.getConjointCoordinatesUpdated(typeForm, dataUpdateAddress);
    }

    if (typeForm === "foyer") {
      coordinatesData = this.getConjointCoordinatesUpdated(typeForm, dataUpdateAddress)
    }
    this.familyService.updateConjoint(coordinatesData, 'coordinates').subscribe(x => {
      this.clickToUpdateAddress$.next(true);
      this.notifySuccess();
    }, err => {
      const errMsg = `Erreur lors de la modification des coordonnées de ${dataUpdateAddress.conjoint.civilite}
         ${dataUpdateAddress.conjoint.nom} ${dataUpdateAddress.conjoint.prenom} :`;
      this.notifyError('form-adulte', err, errMsg);
    }
    );
  }

  updateAddressFoyer(typeForm: string, dataUpdateAddress: addressToUpdate) {
    let coordinatesData;

    if (typeForm === "user") {
      coordinatesData = this.getFoyerCoordinatesUpdated(typeForm, dataUpdateAddress);
    }

    if (typeForm === "conjoint") {
      coordinatesData = this.getFoyerCoordinatesUpdated(typeForm, dataUpdateAddress)
    }
    this.familyService.update(coordinatesData, this.familyService.currentFamily.id, 'coordinates').subscribe(x => {
      this.clickToUpdateAddress$.next(true);
      this.notifySuccess();
    }, err => {
      const errMsg = `Erreur lors de la modification des coordonnées de ${dataUpdateAddress.famille.civilite}
         ${dataUpdateAddress.famille.nomFamille} :`;
      this.notifyError('form-famille', err, errMsg);
    }
    );
  }

  updateAddresses(typeForm: string, dataUpdateAddress: addressToUpdate) {
    if (typeForm === "user") {
      let coordinatesConjointData = this.getConjointCoordinatesUpdated(typeForm, dataUpdateAddress);
      let coordinatesFoyerData = this.getFoyerCoordinatesUpdated(typeForm, dataUpdateAddress);

      forkJoin([
        this.familyService.updateConjoint(coordinatesConjointData, 'coordinates'),
        this.familyService.update(coordinatesFoyerData, this.familyService.currentFamily.id, 'coordinates')
      ]).subscribe(_ => {
        this.notifySuccess();
      });
    }
    if (typeForm === "foyer") {
      let coordinatesUserData = this.getUserCoordinatesUpdated(typeForm, dataUpdateAddress);
      let coordinatesConjointData = this.getConjointCoordinatesUpdated(typeForm, dataUpdateAddress);
      forkJoin([
        this.familyService.updateConjoint(coordinatesConjointData, 'coordinates'),
        this.userService.update(coordinatesUserData, this.userService.currentUser.id, 'coordinates')
      ]).subscribe(_ => {
        this.clickToUpdateAddress$.next(true);
        this.notifySuccess();
      }, err => this.clickToUpdateAddress$.next(true));
    }
    if (typeForm === "conjoint") {
      let coordinatesUserData = this.getUserCoordinatesUpdated(typeForm, dataUpdateAddress);
      let coordinatesFoyerData = this.getFoyerCoordinatesUpdated(typeForm, dataUpdateAddress);
      forkJoin([
        this.familyService.update(coordinatesFoyerData, this.familyService.currentFamily.id, 'coordinates'),
        this.userService.update(coordinatesUserData, this.userService.currentUser.id, 'coordinates')
      ]).subscribe(_ => {
        this.clickToUpdateAddress$.next(true);
        this.notifySuccess();
      }, err => this.clickToUpdateAddress$.next(true));
    }
  }

  checkAddress(typeFormCoordinates: string): Observable<any> {

    let userAddress;
    let conjointAddress;
    let familyAddress;
    let conjointAddressIsSameUserAddress: boolean;
    let familyAddressIsSameUserAddress: boolean;
    let userAddressIsSameFamilyAddress: boolean;
    let conjointAddressIsSameFamilyAddress: boolean;
    let familyAddressIsSameConjointAddress: boolean;
    let userAddressIsSameConjointAddress: boolean;

    return forkJoin([
      this.userService.getFormData(this.userService.currentUser.id, 'coordinates'),
      this.familyService.getFormData(this.familyService.currentFamily.id, 'coordinates'),
      this.familyService.getConjointFormData('coordinates')
    ]).pipe(map(([userFormData, familleFormData, conjointFormData]: [any, any, any]) => {

      const user = userFormData.data;
      const famille = familleFormData.data;
      const conjoint = conjointFormData.data;

      if (user) {
        userAddress = this.setAddressFromFormData(user);
      }

      if (famille) {
        familyAddress = this.setAddressFromFormData(famille);
      }

      if (!this.isNullish(conjoint)) {
        conjointAddress = this.setAddressFromFormData(conjoint);
      }

      if (typeFormCoordinates === "user" && userAddress) {
        if (familyAddress) {
          familyAddressIsSameUserAddress = Object.entries(userAddress).toString() === Object.entries(familyAddress).toString();
        }
        if (conjointAddress) {
          conjointAddressIsSameUserAddress = Object.entries(userAddress).toString() === Object.entries(conjointAddress).toString();
        }

        return {
          user,
          famille,
          conjoint,
          userAddress,
          conjointAddress,
          familyAddress,
          familyAddressIsSameUserAddress,
          conjointAddressIsSameUserAddress
        }
      }

      if (typeFormCoordinates === "famille" && familyAddress) {
        if (userAddress) {
          userAddressIsSameFamilyAddress = Object.entries(familyAddress).toString() === Object.entries(userAddress).toString();
        }
        if (conjointAddress) {
          conjointAddressIsSameFamilyAddress = Object.entries(familyAddress).toString() === Object.entries(conjointAddress).toString();
        }
        return {
          user,
          famille,
          conjoint,
          conjointAddress,
          userAddress,
          familyAddress,
          userAddressIsSameFamilyAddress,
          conjointAddressIsSameFamilyAddress
        }
      }

      if (typeFormCoordinates === "conjoint" && conjointAddress) {
        if (familyAddress) {
          familyAddressIsSameConjointAddress = Object.entries(conjointAddress).toString() === Object.entries(familyAddress).toString();
        }
        if (userAddress) {
          userAddressIsSameConjointAddress = Object.entries(conjointAddress).toString() === Object.entries(userAddress).toString();
        }
        return {
          user,
          famille,
          conjoint,
          conjointAddress,
          userAddress,
          familyAddress,
          familyAddressIsSameConjointAddress,
          userAddressIsSameConjointAddress
        }
      }
      return null;
    })
    )
  }

  setAddressFromFormData(formData: any) {
    return (({ codePostal, numRue, rue, rue2, rue3, ville, idRue }) => ({ codePostal, numRue, rue, rue2, rue3, ville, idRue }))(formData);
  }

  manyAddressesDisplay(address1, address2, same1, same2): boolean {
    if (address1 && address2 && !same1 && !same2) {
      return true;
    } return false;
  }

  notifySuccess() {
    this.snackbar.info(`Modification effectuée`);
  }

  notifyError(typeForm: string, err: any, errorMessage: string) {
    this.formConfig.getForm(typeForm, 'coordinates').subscribe(formStep => {
      let form = this.formConfig.getFormView(formStep).filter(f => f.enabled);
      if (typeof (err) === 'object' && err.errors) {
        setTimeout(() => {
          for (const fieldName in err.errors) {
            if (err.errors.hasOwnProperty(fieldName)) {
              const field = this.formConfig.findFieldByName(form, fieldName);
              errorMessage += `<li>${(field && field.templateOptions && field.templateOptions.label) ?
                field.templateOptions.label : fieldName} : ${err.errors[fieldName]}</li>`
              this.snackbar.error(errorMessage);
            }
          }
        });
      } else if (typeof (err) === 'string') {
        this.snackbar.error(err);
      }
    })
  }

  isNullish(obj): boolean {
    if (obj === undefined) {
      return true;
    }
    return Object.values(obj).every(value => {
      return value === null ? true : false
    });
  }
}

