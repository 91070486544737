import { FormGroup, ValidationErrors, AbstractControl } from '@angular/forms';

/**
 * Helper to counter known issue with form control setErrors({}) which makes uneasy to add / remove error
 * Equivalent to formControl.setErrors({errorName: toggle}), but also manages some internal troubles
 * @see https://github.com/angular/angular/issues/21564
 */
function properlyToggleFormError(formControl: AbstractControl, errorName: string, toggle?: boolean) {

    let errors = formControl.errors || {};

    // If no boolean given, enable if was disabled, and vice versa
    if (toggle === null || toggle === undefined) {
        toggle = !formControl.hasError(errorName);
    }

    if (toggle) {
        errors = Object.assign({}, { [errorName]: true }, errors);
    } else if (errorName in errors) {
        delete errors[errorName];
    }

    // Ensure we don't pass {} to setErrors(), or the field won't be valid even with no real error
    if (!Object.keys(errors).length) {
        errors = null;
    }

    formControl.setErrors(errors);
}

/**
 * Validates that fields of formGroup are equals, otherwise add 'fieldMatch' error on group last field
 */
export function fieldMatchValidator(formGroup: FormGroup): ValidationErrors {
    if (!formGroup.dirty) {
        return;
    }

    const controlKeys = Object.keys(formGroup.controls);

    if (controlKeys.length < 2) {
        return;
    }

    const refValue = formGroup.value[controlKeys.shift()];
    const differentValues = Object.keys(formGroup.controls).filter(key => formGroup.value[key] !== refValue);
    const lastField = formGroup.get(controlKeys.pop());

    properlyToggleFormError(lastField, 'fieldMatch', differentValues.length > 0);
}
