import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpRequest, HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { GlobalConfigService } from './global-config.service';
import { AddressApiMode } from '@app/models/global-config';

@Injectable({
  providedIn: 'root'
})
export class AdresseAutocompleteService {

  apiUrl = 'https://api-adresse.data.gouv.fr/search/';
  newApiUrl = '/address-search';

  apiMode: AddressApiMode;

  constructor(
    private http: HttpClient,
    private globalConfigService: GlobalConfigService
  ) {
    this.globalConfigService.config$.subscribe(conf => this.apiMode = conf.addressApiMode);
  }

  searchAddress(address: string) {
    return this.apiMode === AddressApiMode.DOMINO ? this.searchAddressFromDB(address) : this.searchAddressFromGouvApi(address);
  }

  searchAddressFromGouvApi(address: string, type?: string): Observable<any> {

    if (address && address.length < 3) { // don't call this api with less than 3 caract because it will respond a 400 / Bad request
      return of([]);
    }

    const headers = new HttpHeaders(
      { 'Content-Type': 'application/x-www-form-urlencoded' }
    );

    let searchUrl = this.apiUrl + '?q=' + address;

    if (type) {
      searchUrl += '&type=' + type;
    }

    const req = new HttpRequest('GET', searchUrl, {}, {
      responseType: 'json',
      headers
    });

    return this.http.request(req).pipe(

      filter(response => response.type !== 0),

      map((res: HttpResponse<any>) => res.body.features || [])
    );
  }

  searchAddressFromDB(query: string) {
    const params = new HttpParams({ fromObject: { q: query } });

    return this.http.get<{ timer: string, adresses: any[] }>(this.newApiUrl, { params }).pipe(
      map(x => x.adresses)
    );
  }
}
