import { OnInit, Component } from '@angular/core';
import { AuthenticationService, SessionService } from '@app/services';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  template: `
  <mat-card *ngIf="errorMessage!=''" class="error-container">
    <mat-card-title class="mat-error">
      <mat-icon color="warn" style="float: left; margin-right: 10px;">error</mat-icon>
      <span>Erreur</span>
      <button mat-icon-button aria-label="dismiss" (click)="closeError()" style="float:right;">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-card-title>
    <mat-card-content>
      <pre [innerHTML]="errorMessage" class="mat-error" style="text-align: left;"></pre>
    </mat-card-content>
  </mat-card>
  <app-loader *ngIf="redirecting">
    <h3>{{ redirectingMessage|trans }}</h3>
  </app-loader>
  `
})
export class LogoutComponent implements OnInit {

  redirecting = false;
  redirectingMessage = '';
  errorMessage = '';

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.redirecting = true;
    this.redirectingMessage = ' Redirection ';

    const oidcLogoutCallback = location.href.includes('oidc-logout') // callback for openId connect / logout request
      || location.href.includes('oidc-logout-callback') // callback for openId connect / logout request
      || location.href.includes('logout-callback') // callback for openId connect / logout request

    if (oidcLogoutCallback) {

      const state = this.activatedRoute.snapshot.queryParams['state'];
      this.authService.openIdConnectLogoutCallback(state).subscribe(_ => {
        this.redirecting = false;
        this.router.navigate(['home']); // or 'login' ?? 
      }, error => {
        this.redirecting = false;
        console.error(error)
        this.errorMessage = error.msg;
      });

    } else {
      this.authService.logout().subscribe(res => {
        if (res && res.redirectTo) {
          window.location.href = res.redirectTo;
        } else {
          this.router.navigate(['home']); // or 'login' ?? 
        }
      })
    }
  }

  closeError() {
    this.errorMessage = '';
    this.router.navigate(['home']); // or 'login' ?? 
  }
}
