import { Injectable, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BoardConfig } from '@app/models/board-card';
import { BannerConfig } from '@app/models/global-config';
import { AppRoute } from '@app/models/nav-item';
import { BehaviorSubject } from 'rxjs';

const routeTitleArray = [
  { url: '/home', title: 'Accueil' },
  { url: '/news', title: 'Actualités' },
  { url: '/account', title: 'Mon compte' },
  { url: '/login', title: 'Se connecter' },
  { url: '/register', title: 'Nouveau compte' },
  { url: '/about', title: 'A propos' },
  { url: '/forgot-password', title: 'Mot de passe oublié' }
];

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private currentBannerSubject = new BehaviorSubject<BannerConfig>(null);
  currentBanner$ = this.currentBannerSubject.asObservable();

  private faviconSubject = new BehaviorSubject<string>(null);
  favicon$ = this.faviconSubject.asObservable();

  private pageTitleSubject = new BehaviorSubject<string>(null);
  pageTitle$ = this.pageTitleSubject.asObservable();

  private breadcrumbsSubject = new BehaviorSubject<AppRoute[]>([]);
  breadcrumbs$ = this.breadcrumbsSubject.asObservable();

  // topbarConfig$ = new BehaviorSubject<TopbarConfig>(null);
  topbarActionsTemplate$ = new BehaviorSubject<TemplateRef<any>>(null);

  setCurrentPageTitle(title: string) {
    this.pageTitleSubject.next(title);
  }

  setBanner(banner: BannerConfig) {
    this.currentBannerSubject.next(banner);
  }

  setFavicon(url: string) {
    this.faviconSubject.next(url);
  }

  setBreadcrumbs(breadcrumbs: AppRoute[]) {
    this.breadcrumbsSubject.next(breadcrumbs);
  }

  refreshBreadcrumbs(route: ActivatedRoute) {
    this.setBreadcrumbs([{ title: 'home', url: '/', icon: 'home' } as AppRoute].concat(this.createBreadcrumbs(route)));
  }

  createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: AppRoute[] = []): AppRoute[] {
    const children = route.children;

    if (!children?.length) {
      return breadcrumbs;
    }

    for (const child of children) {
      const path = child.snapshot.url.map(part => part.path).join('/');

      if (path && path !== 'home') {
        url += '/' + path;
        const title = child.snapshot.data.title || ('route.' + path.replace(/\//g, '_'));
        breadcrumbs.push({ title, url });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  refreshPageTitle(route: ActivatedRoute, url: string, accountBoard: BoardConfig) {
    const title = this.computePageTitle(route, url, accountBoard);

    this.setCurrentPageTitle(title);
  }

  computePageTitle(route: ActivatedRoute, url: string, accountBoard: BoardConfig) {
    if (accountBoard) {
      // remove empty char before leading /
      const urlParts = url.split('/').filter(p => !!p);

      if (urlParts.length > 1 && urlParts[0] === 'account') {
        let sectionName = urlParts[1];

        if (sectionName === 'conjoint') {
          sectionName = 'foyer';
        }

        const pageCard = accountBoard.cards.find(c => c.component === sectionName);
        if (pageCard) {
          return pageCard.title;
        }
      }
    }

    const routeData = this.getRouteData(route);
    if (routeData?.title) {
      return routeData.title;
    }

    const titleFromRouteArray = routeTitleArray.find(r => r.url === url);
    if (titleFromRouteArray) {
      return titleFromRouteArray.title;
    }

    return '';
  }

  getRouteData(route: ActivatedRoute) {
    while (route.firstChild) {
      route = route.firstChild;
    }

    // use this to get only data from current route, otherwise they're inherited by default (avoids empty title, why not ...)
    // return route?.routeConfig?.data;
    return route.snapshot.data;
  }

  // setTopbarActions(actions: PageAction[]) {
  //   const config = this.topbarConfig$.value || {};
  //   config.actions = actions;

  //   this.topbarConfig$.next(config);
  // }

  resetTopbar() {
    // this.topbarConfig$.next(null);
    this.topbarActionsTemplate$.next(null);
  }
}
