import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { Cours, DepDiscNivGroup, LasidoInscription, LasidoInscriptionConfig } from '@app/models/lasido-inscription';
import { FamilyService, MenuService, PlatformService, SnackbarService } from '@app/services';
import { LasidoService } from '@app/services/lasido.service';
import { environment } from '@env/environment';
import { forkJoin } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-renouvellement-inscription',
  templateUrl: './renouvellement-inscription.component.html',
  styleUrls: ['./renouvellement-inscription.component.scss']
})
export class RenouvellementInscriptionComponent implements OnInit {

  @ViewChild('coursSelectDialog') coursSelectDialog;

  title: string = "Renouvellement d'une inscription"
  idFromUrl: number;
  idFamille: number;
  inscription: LasidoInscription;
  config: LasidoInscriptionConfig;
  coursListAvailable: Cours[];
  userComment: string;
  coursMaxSelectLeft: number;
  acceptReglement: boolean = false;
  coursSelectDialogRef: MatDialogRef<any>;
  isInscriptionValid: boolean = false;
  saving: boolean = false;

  constructor(
    private lasidoService: LasidoService,
    private route: ActivatedRoute,
    private router: Router,
    private familyService: FamilyService,
    public platformService: PlatformService,
    private menuService: MenuService,
    private dialog: MatDialog,
    private snackbar: SnackbarService
  ) { }

  ngOnInit(): void {
    this.idFromUrl = this.route.snapshot.paramMap.has('id') ? +this.route.snapshot.paramMap.get('id') : null;
    this.idFamille = this.familyService.currentFamily.id;

    this.loadData().subscribe(_ => {
      this.updateValidStatus();

      this.inscription.depDiscNivGroup.forEach(depDiscNiv => {
        if (depDiscNiv.cours.length) {
          depDiscNiv.choicesCours = depDiscNiv.cours.filter(cours => cours.choice).sort((a, b) => a.ordre - b.ordre);
        }
      });
    });
  }

  loadData() {
    return this.familyService.currentFamilyReadyOnce$.pipe(
      switchMap(family => {

        const loaders = [];

        loaders.push(this.lasidoService.getConfigInscriptionForUser().pipe(tap(c => this.config = c)));
        loaders.push(this.lasidoService.getInscription(family, this.idFromUrl).pipe(tap(insc => {
          this.inscription = insc;
          this.inscription.depDiscNivGroup = this.inscription.depDiscNivGroup.filter(discNiv => discNiv.etatRenouvellement !== '' && discNiv.disciplineReservable);
        })));

        return forkJoin(loaders);
      })
    );
  }

  viewReglementInterieur() {
    if (this.platformService.isMobile) {
      this.menuService.sidenav.close();
    }

    if (this.config.urlApiDoc && this.config.urlApiDoc !== "0") {
      window.open(environment.apiUrl + this.config.urlApiDoc, '_blank');
    } else {
      window.open(this.config.url, '_blank');
    }
  }

  radioChange(event, discNiv: DepDiscNivGroup) {
    if (event.value === 'oui') {
      discNiv.choicesCours = discNiv.choicesCours ? discNiv.choicesCours : [];
      this.coursMaxSelectLeft = Math.max(0, this.config.coursMax - discNiv.choicesCours?.length);
    }
    this.updateValidStatus();
  }

  onChangeAcceptReglement(event) {
    this.updateValidStatus();
  }

  addCours(discNiv: DepDiscNivGroup) {
    discNiv.choicesCours = discNiv.choicesCours ? discNiv.choicesCours : [];
    this.coursSelectDialogRef = this.dialog.open(this.coursSelectDialog);
    this.platformService.adaptDialogToScreen(this.coursSelectDialogRef);

    this.coursListAvailable = discNiv.availableCours;
    this.coursMaxSelectLeft = Math.max(0, this.config.coursMax - discNiv.choicesCours.length);

    this.coursSelectDialogRef.afterClosed().pipe(
      filter(r => !!r)
    ).subscribe((selection: Cours[]) => {
      for (const sel of selection) {
        if ((!this.config.coursMax || discNiv.choicesCours.length < this.config.coursMax) && !discNiv.choicesCours.some(c => c.idCours === sel.idCours)) {
          discNiv.choicesCours.push(sel);
        } else {
          // error ?
        }
      }
      this.updateValidStatus();
    });
  }

  onRemoveCourse(discNiv: DepDiscNivGroup, cours: Cours) {
    const index = discNiv.choicesCours.findIndex(c => c.idCours === cours.idCours);

    if (index > -1) {
      discNiv.choicesCours.splice(index, 1);

      this.updateValidStatus();
    }
  }

  onCoursSelectChange(event: MatSelectionListChange) {
    if (!this.config.coursMax) {
      return;
    }

    event.options.forEach(opt => {
      if (opt.selected) {
        this.coursMaxSelectLeft--;
      } else {
        this.coursMaxSelectLeft++;
      }
    });

    if (this.coursMaxSelectLeft < 1) {
      event.source.options.filter(opt => !opt.selected).forEach(opt => opt.disabled = true);
    } else {
      event.source.options.forEach(opt => opt.disabled = false);
    }
  }

  onDropCourse(event: CdkDragDrop<Cours>, discNiv: DepDiscNivGroup) {
    moveItemInArray(discNiv.choicesCours, event.previousIndex, event.currentIndex);
  }

  onValidateCoursSelection(list: MatSelectionList) {
    const selectedCourses = list.selectedOptions.selected.map(x => x.value);
    this.coursSelectDialogRef.close(selectedCourses);
  }

  updateValidStatus() {
    this.isInscriptionValid = this.checkValid();
  }

  checkValid() {
    if (!this.inscription.depDiscNivGroup.length) {
      return false;
    }

    for (const discNiv of this.inscription.depDiscNivGroup) {

      if (!discNiv.renewal) {
        return false;
      }

      if (discNiv.renewal === 'oui' && discNiv.availableCours.length) {
        if (this.config.coursMin > discNiv.choicesCours?.length) {
          return false;
        } else if (this.config.coursMax && this.config.coursMax < discNiv.choicesCours?.length) {
          return false;
        }
      }
    }

    return true;
  }

  cancel() {
    this.router.navigate([`account/lasido-inscriptions`]);
  }

  submit() {
    if (!this.checkValid()) {
      return;
    }

    this.saving = true;

    this.lasidoService.saveInscription(this.inscription, this.familyService.currentFamily.id).subscribe(resp => {
      this.snackbar.info('Enregistrement réussi')
      this.saving = false;
      this.router.navigate([`account/lasido-inscriptions`]);
    }, _ => {
      this.saving = false;
      this.snackbar.error("Erreur lors de l'enregistrement")
    });
  }
}
