import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface PortailConfig { }

export interface dateTimeLoadConf {
  objet: string;
  derniereModif: string;
}

export interface loadConf {
  objet: string;
  derniereModif: string;
  content: any;
  custom?: boolean;

  //only for theme config
  css?: string;
  darkModeSwitch?: any;
  fontFamily?: string;
  formFieldAppearance?: string;
}

@Injectable({
  providedIn: 'root'
})
export class BaseConfigService {

  configUrl = 'conf/';

  cachedConfigs = {};

  objetsOfFirstLoadConf = ["board-home", "board-assmat", "board-user", "translation", "custom-links", "global", "menu-items", "theme"];

  firstLoadConfigs = new BehaviorSubject<loadConf[]>([]);
  currentFirstLoadConfigs$ = this.firstLoadConfigs.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  get<T extends PortailConfig>(type: string): Observable<T> {
    if (this.cachedConfigs[type]) {
      return of(this.cachedConfigs[type]);
    }

    return this.http.get<T>(this.configUrl + type).pipe(
      tap(conf => this.cachedConfigs[type] = conf)
    );
  }

  // @NB: generally only for admin !
  set<T extends PortailConfig>(type: string, value: T) {
    return this.http.put(this.configUrl + type, value);
  }

  updateFirstLoadConfigs(confs: loadConf[]) {
    this.firstLoadConfigs.next(confs);
  }

  getDateTimeFirstLoadConfFromStorage(): any[] {
    let firstLoadConf = [];
    this.objetsOfFirstLoadConf.forEach(objet => {
      if (objet in localStorage) {
        let firstConfInLocalstorage: loadConf = JSON.parse(localStorage.getItem(objet))
        firstLoadConf.push({
          objet: firstConfInLocalstorage.objet,
          derniereModif: firstConfInLocalstorage.derniereModif,
          custom: firstConfInLocalstorage.custom
        });
      } else {
        firstLoadConf.push({
          objet,
          derniereModif: "",
          custom: false
        })
      }
    })
    return firstLoadConf;
  }

  getFirstLoadConfs(): Observable<loadConf[]> {
    let dateTimeFirstLoadConfsFromStorage = this.getDateTimeFirstLoadConfFromStorage();
    let dateTimeFirstLoadConfsFromStorageString = JSON.stringify(dateTimeFirstLoadConfsFromStorage);
    return this.http.get<loadConf[]>(`${this.configUrl}firstLoadConfs?storageConfigs=${dateTimeFirstLoadConfsFromStorageString}`)
      .pipe(
        tap((confs: loadConf[]) => {
          if (confs.length) {
            confs.forEach((conf: loadConf) => {
              if (conf.content) {
                localStorage.setItem(conf.objet, JSON.stringify(conf));
              }
            })
          }

          let firstLoadConfigs: loadConf[] = [];
          this.objetsOfFirstLoadConf.forEach(objet => {
            let confInlocalStorage = JSON.parse(localStorage.getItem(objet));
            if (confInlocalStorage !== null) {
              firstLoadConfigs.push(confInlocalStorage);
            }
          });
          this.updateFirstLoadConfigs(firstLoadConfigs);

        }));
  }

  getFirstConf(type: string): loadConf {
    let config = this.firstLoadConfigs.value.find((conf: loadConf) => conf.objet === type);
    if (config.content) {
      return config;
    }
  }

  getErrorMessageSizeConf(config: any, maxSize: number = 2): string {
    // const size = new TextEncoder().encode(JSON.stringify(config)).length; // solution qui ne fonctionne pas avec IE
    const size = encodeURI(JSON.stringify(config)).split(/%..|./).length - 1;
    const kiloBytes = size / 1024;
    const megaBytes = kiloBytes / 1024;

    return megaBytes >= maxSize ? `
      <div style="font-size:16px">
        <p> Une erreur est survenue lors de l'enregistrement de la configuration.</p>
        <p> La taille ne doit pas dépasser <strong>2Mo</strong>.</p>
        <p> Veillez à importer les images depuis la bibliothèque de documents.</p>
      </div>` : "";

  }

}
