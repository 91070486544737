import { FormControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ExtendedFormlyFieldConfig } from '@app/forms/config/form-model';

export function CountValidator(control: FormControl, field: FormlyFieldConfig): ValidationErrors {

  if (field.templateOptions.min && control.value.length < field.templateOptions.min) {
    return { count: 'min' };
  }

  if (field.templateOptions.max && control.value.length > field.templateOptions.max) {
    return { count: 'max' };
  }

  return null;
}

export function CountValidatorMessage(err, field: ExtendedFormlyFieldConfig) {

  // In JSON config : validation: {messages: {min_template: 'at least %min%', max_template: 'maximum authorized is %max%'}}
  if (field.validation && (err + '_template' in field.validation.messages)) {
    return field.validation.messages[err + '_template'].toString()
      .replace('%min%', field.templateOptions.min + '')
      .replace('%max%', field.templateOptions.max + '');
  }

  return err === 'min' ?
    'Veuillez ajouter au moins ' + field.templateOptions.min + ' entrée(s)' :
    'Le maximum autorisé est ' + field.templateOptions.max;
}
