<mat-card *ngIf="config && config.infoMessage" class="card-info-container" [portailCustomStyle]="config.styleCardInfo">
  <div [innerHtml]="config.infoMessage | safe"></div>
</mat-card>

<mat-card *ngIf="(isEdit && preinscription) || options; else loader" class="usager-select-container">
  <ng-container *ngIf="!isEdit">
    <p>{{ 'lasido.edit.usager_select'|trans }}</p>

    <mat-form-field>
      <mat-label>{{ 'entity.usager'|trans }}</mat-label>
      <mat-select [value]="selectedUsager" required panelClass="usager-select"
        (selectionChange)="onChangeUsager($event)">
        <mat-optgroup>
          {{ 'entity.adults'|trans }}
          <ng-container *ngFor="let usager of options.usagers">
            <mat-option *ngIf="usager.type === 'adulte'" [value]="usager">
              {{ usager.prenom }} {{ usager.nom }}
            </mat-option>
          </ng-container>
        </mat-optgroup>

        <mat-optgroup>
          {{ 'entity.children'|trans }}
          <ng-container *ngFor="let usager of options.usagers">
            <mat-option *ngIf="usager.type === 'enfant'" [value]="usager">
              {{ usager.prenom }} {{ usager.nom }}
              <span *ngIf="usager.age">({{ 'enfant.age'|trans:{age: usager.age.years} }})</span>
            </mat-option>
          </ng-container>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>

    <div *ngIf="!hideAccueil">

      <p>{{ 'lasido.edit.accueil_select'|trans }}</p>

      <mat-form-field *ngIf="!hideEtablissement">
        <mat-label>{{ 'entity.etablissement'|trans }}</mat-label>
        <mat-select [value]="selectedEtab" required (selectionChange)="onChangeEtab($event)">
          <mat-option *ngFor="let etab of options.etablissements" [value]="etab">{{ etab.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'entity.accueil'|trans }}</mat-label>
        <mat-select [value]="selectedAccueil" required (selectionChange)="onChangeAccueil($event)">
          <mat-option *ngFor="let accueil of selectedEtab?.accueils" [value]="accueil">{{ accueil.name }}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>
  </ng-container>

  <div *ngIf="isEdit" class="existing-info">

    <div>
      <label>{{ 'entity.usager'|trans }} :</label>
      <span>{{ preinscription.prenom }} {{ preinscription.nom }}</span>
    </div>

    <div>
      <label>{{ 'lasido.annee_scolaire'|trans }} :</label>
      <span>{{ preinscription.anneeScolaireName }}</span>
    </div>

    <div *ngIf="preinscription.accueilName">
      <label>Etablissement - Accueil :</label>
      <span *ngIf="preinscription.etablissementName">{{ preinscription.etablissementName }} ></span>
      {{ preinscription.accueilName }}
    </div>

    <div>
      <label>{{ 'lasido.date_demande'|trans }} :</label>
      <span>{{ preinscription.dateDemande ? (preinscription.dateDemande|date) : '' }}</span>
    </div>

    <div class="status">
      <label>{{ 'lasido.status'|trans }} :</label>
      <span>
        {{ preinscription.decision }}

        <app-icon *ngIf="preinscription.decision === 'Refusée'" type="fa" icon="times-circle"
          class="icon-decision warn"></app-icon>
        <app-icon *ngIf="preinscription.decision === 'Acceptée'" type="fa" icon="check-circle"
          class="icon-decision primary"></app-icon>
        <app-icon *ngIf="preinscription.decision === 'Abandon'" type="fa" icon="trash" class="icon-decision">
        </app-icon>
        <app-icon *ngIf="preinscription.decision === 'En attente'" type="fa" icon="question-circle"
          class="icon-decision accent"></app-icon>
      </span>
    </div>
  </div>
</mat-card>

<div *ngIf="(preinscription?.idAdulte || preinscription?.idEnfant) && preinscription?.idAccueil"
  class="lasido-groups-container">

  <ul *ngIf="preinscription.errors?.length" class="error-list">
    <li *ngFor="let err of preinscription.errors">{{ err|trans }}</li>
  </ul>

  <h4 class="section-title">
    <mat-icon>checklist</mat-icon>
    {{ 'lasido.edit.choices_title'|trans }}
  </h4>

  <ng-container *ngIf="disciplineTree; else loader">

    <mat-card *ngFor="let group of preinscription.choiceGroups" class="group-choice">

      <mat-card-title>
        <div style="flex: 1;">
          <span *ngIf="config.afficherDepartement">
            {{ group.departmentName }}
            <mat-icon class="separator">chevron_right</mat-icon>
          </span>
          <span>
            {{ group.disciplineName }}
            <mat-icon class="separator">chevron_right</mat-icon>
          </span>
          <span>{{ group.niveauName }}</span>
        </div>

        <button mat-raised-button color="warn" (click)="removeChoiceGroup(group)">
          <mat-icon>delete</mat-icon>
        </button>

      </mat-card-title>

      <ul *ngIf="group.errors?.length" class="error-list">
        <li *ngFor="let err of group.errors" class="warn">{{ err|trans }}</li>
      </ul>

      <div *ngIf="group.hasCours" class="courses-container">

        <p class="text-center">Veuillez sélectionner des cours et les classer selon votre ordre de préférence.</p>

        <div *ngIf="group.cours?.length" class="courses-list">

          <div class="courses-index-list">
            <span *ngFor="let course of group.cours; let i = index">{{ i + 1 }}.</span>
          </div>

          <div cdkDropList class="courses-drop-list" (cdkDropListDropped)="onDropCourse($event, group)">
            <div class="course-drag-item" *ngFor="let course of group.cours" cdkDrag>
              <span>{{ course.name }}
                <span *ngIf="!course.provisoire; else coursProvisoire" class="details-cours">
                  (Le {{course.jour}} de
                  {{course.heureDebut}} à
                  {{course.heureFin}})
                </span>
                <ng-template #coursProvisoire>
                  <span class="details-cours">({{'lasido.text-cours-provisoire' | trans}})</span>
                </ng-template>
              </span>
              <mat-icon>drag_handle</mat-icon>
            </div>
          </div>

          <div class="courses-delete-list">
            <span *ngFor="let course of group.cours">
              <mat-icon color="warn" [matTooltip]="'action.delete'|trans" (click)="onRemoveCourse(group, course)">delete
              </mat-icon>
            </span>
          </div>
        </div>
      </div>

      <p *ngIf="!group.hasCours" class="text-center">{{ 'lasido.edit.no_course_message'|trans }}</p>

      <div class="text-center" style="margin-top: 10px;">
        <span *ngIf="group.minCours && group.minCours > group.cours?.length; else maxCoursMessage">
          Vous devez encore ajouter au moins {{ group.minCours - group.cours?.length }} cours.
        </span>

        <ng-template #maxCoursMessage>
          <ng-container *ngIf="group.maxCours">
            <span *ngIf="group.maxCours <= group.cours?.length; else remainingCours">
              Vous avez atteint le maximum de {{ group.maxCours }} cours.
            </span>
            <ng-template #remainingCours>
              <span>Vous pouvez encore ajouter {{ group.maxCours - group.cours?.length }} cours.</span>
            </ng-template>
          </ng-container>
        </ng-template>
      </div>

      <div *ngIf="group.hasCours" fxLayout="row" fxLayoutAlign="center center" style="margin-top: 15px">
        <button mat-raised-button color="primary" (click)="addCours(group)"
          [disabled]="!!group.maxCours && group.cours?.length >= group.maxCours">
          <mat-icon>school</mat-icon>
          {{ 'lasido.edit.add_course'|trans }}
        </button>
      </div>

      <mat-form-field *ngIf="config.enabledComment" class="no-hint" style="width: 100%; margin-top: 20px;">
        <mat-label>Commentaire</mat-label>
        <textarea matInput [(ngModel)]="group.comment" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6">
          </textarea>
      </mat-form-field>
    </mat-card>

    <div *ngIf="config.linkType && (config.url !== '0' || config.urlApiDoc !== '0')" fxLayout="row"
      fxLayoutAlign="center center" style="text-align: center; margin-bottom: 20px;">
      <mat-checkbox [(ngModel)]="preinscription.validationReglement">
        J'accepte le règlement intérieur
      </mat-checkbox>
      <button mat-icon-button color="accent" matTooltip="Voir" (click)="viewReglementInterieur()">
        <mat-icon>visibility</mat-icon>
      </button>
    </div>

    <mat-card class="actions-card">
      <button mat-raised-button color="primary" (click)="addChoiceGroup()">
        <mat-icon>add</mat-icon>
        {{ 'lasido.edit.add_choice'|trans }}
      </button>
      <span class="separator"></span>
      <button mat-raised-button color="accent" (click)="onValidatePreinscription()"
        [disabled]="(!preinscription.validationReglement && (config.linkType && (config.url !== '0' || config.urlApiDoc !== '0'))) || !isPreinscriptionValid || saving">
        <mat-icon [hidden]="saving">check</mat-icon>
        <app-loader *ngIf="saving" class="icon-loader" size=".75"></app-loader>
        {{ 'action.validate'|trans }}
      </button>
    </mat-card>
  </ng-container>
</div>

<ng-template #loader>
  <app-loader class="margin"></app-loader>
</ng-template>

<ng-template #editChoiceGroupDialog>
  <div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <mat-toolbar mat-dialog-title color="primary" cdkDragHandle>
      <span class="title">
        {{ 'lasido.edit.choice_dialog_title'|trans }}
      </span>
      <button class="close-dialog" mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <div mat-dialog-content class="choice-group-selects">

    <mat-form-field *ngIf="config.afficherDepartement">
      <mat-label>{{ 'lasido.department'|trans }}</mat-label>
      <mat-select #department (selectionChange)="onChangeDepartment($event.value)">
        <mat-option *ngFor="let dep of disciplineTree" [value]="dep.id">{{ dep.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'lasido.discipline'|trans }}</mat-label>
      <mat-select [(ngModel)]="editChoiceGroup.discipline" (ngModelChange)="onChangeDiscipline($event)">
        <mat-option *ngFor="let disc of disciplineChoices" [value]="disc.id">{{ disc.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'lasido.level'|trans }}</mat-label>
      <mat-select [(ngModel)]="editChoiceGroup.niveau" (ngModelChange)="onChangeNiveau($event)">
        <mat-option *ngFor="let lvl of niveauChoices" [value]="lvl.id">{{ lvl.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div mat-dialog-actions [align]="'right'">

    <button mat-raised-button color="warn" mat-dialog-close>
      <mat-icon>close</mat-icon>
      {{ 'action.cancel'|trans }}
    </button>
    <button mat-raised-button color="accent" mat-dialog-close="true" [disabled]="!editChoiceGroup.niveau">
      <mat-icon>check</mat-icon>
      {{ 'action.validate'|trans }}
    </button>

  </div>

</ng-template>

<ng-template #coursSelectDialog>
  <div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <mat-toolbar mat-dialog-title color="primary" cdkDragHandle>
      <span class="title">
        {{ 'lasido.edit.course_dialog_title'|trans }}
      </span>
      <button class="close-dialog" mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <div mat-dialog-content class="cours-select">

    <mat-selection-list #coursList (selectionChange)="onCoursSelectChange($event)">
      <mat-list-option *ngFor="let cours of coursChoices" [value]="cours">
        <div class="label-cours">{{ cours.name }}</div>
        <div *ngIf="!cours.provisoire; else coursProvisoire" class="details-cours">Le {{cours.jour}} de
          {{cours.heureDebut}} à
          {{cours.heureFin}} </div>
        <ng-template #coursProvisoire>
          <div class="details-cours">{{'lasido.text-cours-provisoire' | trans}}</div>
        </ng-template>
      </mat-list-option>
    </mat-selection-list>
  </div>

  <div mat-dialog-actions [align]="'right'">
    <button mat-raised-button color="warn" mat-dialog-close>
      <mat-icon>close</mat-icon>
      {{ 'action.cancel'|trans }}
    </button>
    <button mat-raised-button color="accent" (click)="onValidateCoursSelection(coursList)">
      <mat-icon>check</mat-icon>
      {{ 'action.validate'|trans }}
    </button>
  </div>
</ng-template>
