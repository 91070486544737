import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Usager } from '@app/models/consumer';
import { Family } from '@app/models/family';
import { LasidoInscription, LasidoInscriptionConfig } from '@app/models/lasido-inscription';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { ChildAdapter } from './adapter/child-adapter.service';

export interface LasidoConfig {
  anneeScolaire?: number;
  coursMin: number;
  coursMax: number;
  afficherDepartement: boolean;
  linkType?: string;
  url?: string;
  urlApiDoc?: string;
  docName?: string;
  infoMessage?: string;
  styleCardInfo?: {};
  enabledComment: boolean;

  idEtablissement?: number;
  idAccueil?: number;
}

export interface LasidoPreinscription {
  id?: number;
  idAdulte?: number;
  idEnfant?: number;
  idAccueil?: number;

  nom?: string;
  prenom?: string;

  anneeScolaire?: number;

  status?: string; // en attente / traitée ?
  validationReglement?: boolean;
  comment?: string;

  choiceGroups?: LasidoChoiceGroup[];
  errors?: string[];
}

export interface AccueilTreeItem {
  id: number;
  name: string;

  // only for Etablissement level
  accueils?: AccueilTreeItem[];
}

// export interface PreinscriptionOptions {
//   usagers: Usager[];
//   etablissements: AccueilTreeItem[];
// }

export interface LasidoChoiceGroup {
  discipline: number;
  niveau: number;
  cours: LasidoCourse[]; // rangés par ordre de prio

  decision?: string; // acceptée / refusée ?
  coursRetenu?: number; // id du cours retenu

  // attributes for display
  departmentName?: string;
  disciplineName?: string;
  niveauName?: string;
  hasCours?: boolean;
  minCours?: number;
  maxCours?: number;

  errors: string[];

  // niveauDiscipline?: {
  //   id: number;
  //   libelle: string;
  // }
  // coursRetenu?: {
  //   id: number;// id du cours retenu
  //   libelle: string
  // };
}

interface LasidoTreeItem {
  id: number;
  name: string;
}

interface LasidoDepartment extends LasidoTreeItem {
  disciplines: LasidoDiscipline[];
}

interface LasidoDiscipline extends LasidoTreeItem {
  niveaux: LasidoNiveau[];
}

interface LasidoNiveau extends LasidoTreeItem {
  cours: LasidoCourse[];
}

export interface LasidoCourse extends LasidoTreeItem { }

export type LasidoDisciplineTree = LasidoDepartment[];

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class LasidoService {

  configUrlPreinscription = 'conf/lasido';
  configUrlInscription = 'conf/lasidoInscription';

  constructor(
    private http: HttpClient,
    private childAdapter: ChildAdapter
  ) { }

  getConfigPreinscriptionForAdmin() {
    return this.http.get<{ config: LasidoConfig, anneesScolaires: [], etablissements: [] }>('conf/admin/lasido');
  }

  getConfigPreinscriptionForUser() {
    return this.http.get<LasidoConfig>(this.configUrlPreinscription);
  }

  saveConfigPreinscription(config: any) {
    return this.http.put(this.configUrlPreinscription, { config }, httpOptions);
  }

  getConfigInscriptionForAdmin() {
    return this.http.get<{ config: LasidoInscriptionConfig, anneesScolaires: [] }>('conf/admin/lasidoInscription');
  }

  getConfigInscriptionForUser() {
    return this.http.get<LasidoInscriptionConfig>(this.configUrlInscription);
  }

  saveConfigInscription(config: any) {
    return this.http.put(this.configUrlInscription, { config }, httpOptions);
  }

  getFamilyPreinscriptions(f: Family | number) {
    const idFamily = typeof f === 'object' ? f.id : f;
    return this.http.get<LasidoPreinscription[]>(`familles/${idFamily}/lasido-preinscriptions`);
  }

  getFamilyInscriptions(f: Family | number) {
    const idFamily = typeof f === 'object' ? f.id : f;
    return this.http.get<LasidoInscription[]>(`familles/${idFamily}/lasido-inscriptions`);
  }

  getInscription(f: Family | number, id: number) {
    const idFamily = typeof f === 'object' ? f.id : f;

    return this.http.get<LasidoInscription>(`familles/${idFamily}/lasido-inscriptions/${id}`);
  }

  getPreinscription(f: Family | number, id: number) {
    const idFamily = typeof f === 'object' ? f.id : f;

    return this.http.get<LasidoPreinscription>(`familles/${idFamily}/lasido-preinscriptions/${id}`);
  }

  savePreinscription(preinscription: LasidoPreinscription, idFamily: number) {
    return this.http.post<{ errors?: [] }>(`familles/${idFamily}/lasido-preinscriptions`, { preinscription });
  }

  getOptions(family: Family | number) {
    const idFamily = typeof family === 'object' ? family.id : family;

    return this.http.get<{ usagers: Usager[], etablissements: AccueilTreeItem[] }>(`familles/${idFamily}/lasido/options`).pipe(
      map(data => {
        data.usagers.forEach(u => {
          if (u.type === 'enfant') {
            u = this.childAdapter.adapt(u);
          }
        });

        return data;
      })
    );
  }

  getAvailableLevels(idFamily: number, type: string, id: number, annee: number, accueil: number) {
    const url = `familles/${idFamily}/lasido/disciplines-tree/${type}/${id}/${annee}/${accueil}`;

    return this.http.get<{ departements: LasidoDepartment[], alreadyTagged: number[] }>(url);
  }

  setGroupAttributes(tree: LasidoDisciplineTree, group: LasidoChoiceGroup, config: LasidoConfig) {
    const dep = tree.find(d => d.disciplines.find(di => di.id === group.discipline));
    const disc = dep?.disciplines.find(d => d.id === group.discipline);
    const lvl = disc?.niveaux.find(n => n.id === group.niveau);

    if (dep && config.afficherDepartement) {
      group.departmentName = dep.name;
    }

    group.disciplineName = disc?.name;
    group.niveauName = lvl?.name;
    group.hasCours = lvl?.cours && lvl.cours.length > 0;

    if (group.hasCours) {
      group.minCours = Math.min(lvl.cours.length, config.coursMin);
      group.maxCours = Math.min(lvl.cours.length, config.coursMax);
    }
  }

  getLevelData(tree: LasidoDisciplineTree, lvl: number) {
    for (const dep of tree) {
      for (const disc of dep.disciplines) {
        for (const niv of disc.niveaux) {
          if (niv.id === lvl) {
            return { departement: dep.id, discipline: disc.id, niveau: niv.id };
          }
        }
      }
    }
  }

  getCoursByIdNiveau(idNiveau: number) {
    return this.http.get(`cours-by-niveau/${idNiveau}`);
  }


  checkPermRenouvellementInscription(config: LasidoInscriptionConfig): boolean {
    const start = moment(config.startDate).isValid() ? moment(config.startDate) : null;
    const end = moment(config.endDate).isValid() ? moment(config.endDate).add(1, 'day') : null;
    const currentDateTime = moment(new Date());

    if ((start && end && currentDateTime.isBetween(start, end))
      || (!end && currentDateTime.isAfter(start))
      || (!start && currentDateTime.isBefore(end))) {
      return true;
    };

    return false;
  }

  saveInscription(inscription: LasidoInscription, idFamily: number) {
    return this.http.post(`familles/${idFamily}/lasido-inscriptions`, { inscription });
  }
}
