import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExtendedFormlyFieldConfig, ExtendedFormlyFormOptions } from '@app/forms/config/form-model';
import { FormConfigService } from '@app/services';
import { clone } from '@app/utils/object';

@Component({
  selector: 'app-formly-suggestion-dialog',
  templateUrl: './suggestion-dialog.component.html',
  styleUrls: ['./suggestion-dialog.component.scss']
})
export class SuggestionDialogComponent implements OnInit {
  form: FormGroup;
  model: {};
  fields: ExtendedFormlyFieldConfig[];
  options: ExtendedFormlyFormOptions;
  // Suspend submit while form init, to avoid too early validation, and error "Expression has changed after it was checked"
  suspendSubmit = true;
  addressResp1Exist: boolean;
  addressResp2Exist: boolean;
  addressCorrespondanceExist: boolean;

  constructor(
    public dialogRef: MatDialogRef<SuggestionDialogComponent>,
    private formConfigService: FormConfigService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({});
    this.model = clone(this.data.field.model);
    this.fields = clone(this.data.field.fieldGroup);

    if (this.fields.some(field => field.key === "villeResp1")) {
      this.addressResp1Exist = true;
    }

    if (this.fields.some(field => field.key === "villeResp2")) {
      this.addressResp2Exist = true;
    }

    if (this.fields.some(field => field.key === "ville")) {
      this.addressCorrespondanceExist = true;
    }

    this.formConfigService.parseAllFields(this.fields, (field) => {
      if (field.format) {
        setTimeout(() => this.formConfigService.applyFormatter(field));
      }
    })

    if (this.data.options.formState.disabled || this.data.readOnly) {
      setTimeout(() => this.form.disable());
    } else {
      setTimeout(() => {
        this.suspendSubmit = false;
        if (this.data.field.formControl.invalid) { this.markAllTouched(this.form as FormGroup); }
      });
    }
  }

  copyAddressResp1ToResp2() {
    let addressCopied = this.globalCopyAddress('numRueResp1', 'rueResp1', 'rue2Resp1', 'codePostalResp1', 'villeResp1');
    this.pastAddress('numRueResp2', 'rueResp2', 'rue2Resp2', 'codePostalResp2', 'villeResp2', addressCopied)
  }
  copyAddressResp1ToCorrespondance() {
    let addressCopied = this.globalCopyAddress('numRueResp1', 'rueResp1', 'rue2Resp1', 'codePostalResp1', 'villeResp1');
    this.pastAddress('numRue', 'rue1', 'rue2', 'codePostal', 'ville', addressCopied)
  }
  copyAddressResp2ToCorrespondance() {
    let addressCopied = this.globalCopyAddress('numRueResp2', 'rueResp2', 'rue2Resp2', 'codePostalResp2', 'villeResp2');
    this.pastAddress('numRue', 'rue1', 'rue2', 'codePostal', 'ville', addressCopied)
  }

  pastAddress(numRue: string, rue: string, codePostal: string, ville: string, rue2: string, addressCopied: any) {
    this.formConfigService.findFieldByName(this.fields, numRue).formControl.patchValue(addressCopied.numRue);
    this.formConfigService.findFieldByName(this.fields, rue).formControl.patchValue(addressCopied.rue);
    this.formConfigService.findFieldByName(this.fields, rue2).formControl.patchValue(addressCopied.rue2);
    this.formConfigService.findFieldByName(this.fields, codePostal).formControl.patchValue(addressCopied.codePostal);
    this.formConfigService.findFieldByName(this.fields, ville).formControl.patchValue(addressCopied.ville);
  }

  globalCopyAddress(numRue: string, rue: string, codePostal: string, ville: string, rue2: string) {
    let valueNumRue = this.formConfigService.findFieldByName(this.fields, numRue).formControl.value;
    let valueRue = this.formConfigService.findFieldByName(this.fields, rue).formControl.value;
    let valueRue2 = this.formConfigService.findFieldByName(this.fields, rue2).formControl.value;
    let valueCodePostal = this.formConfigService.findFieldByName(this.fields, codePostal).formControl.value;
    let valueVille = this.formConfigService.findFieldByName(this.fields, ville).formControl.value;

    return { "numRue": valueNumRue, "rue": valueRue, "rue2": valueRue2, "ville": valueVille, "codePostal": valueCodePostal };
  }

  onEnter(event: KeyboardEvent) {
    const target = event.target as HTMLElement;
    if (target.localName === 'textarea') {
      return;
    }
    if (this.data.options.formState.disabled) {
      return;
    }
    this.validate();
  }

  // Mark all fields as "touched" to notice the user of fields with error, even ones he didn't touch (mostly required)
  validate() {
    if (!this.form.valid) {
      this.markAllTouched(this.form as FormGroup);
    }
  }

  markAllTouched(formGroup: FormGroup | FormArray) {

    const controls = formGroup instanceof FormArray ?
      formGroup.controls : Object.keys(formGroup.controls).map(k => formGroup.controls[k]);

    controls.forEach(control => {
      control.markAsTouched();

      if ((control instanceof FormGroup || control instanceof FormArray) && control.controls) {
        this.markAllTouched(control);
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.dialogRef.close(this.model);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
