import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AuthenticationService, MenuService, PlatformService, SnackbarService, UserService } from '@app/services';
import { Role } from '@app/models/user';
import { PermissionService } from '@app/services/permission.service';
import { GlobalConfigService } from '@app/services';
import { OpenIdConfig } from '@app/models/global-config';
import { Location } from '@angular/common';

const storageUsernameKey = 'portail-remember-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  @Input() homeCard = false;

  error: string;
  loginForm: FormGroup;
  normalLoginLoading = false;
  showPass: boolean = false;

  redirecting: boolean;
  redirectingMessage: string;
  errorMessage = '';

  onDestroy$ = new Subject();
  // globalConfig: GlobalConfig;
  externalAuth: OpenIdConfig;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public authService: AuthenticationService,
    private userService: UserService,
    public permService: PermissionService,
    public platformService: PlatformService,
    private globalConfService: GlobalConfigService,
    private snackbarService: SnackbarService,
    private menuService: MenuService,
    private location: Location
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.globalConfService.getPart('externalAuth')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((conf: OpenIdConfig) => this.externalAuth = conf || ({} as OpenIdConfig));

    const inputEmail = localStorage.getItem(storageUsernameKey);

    if (inputEmail) {
      this.loginForm.get('username').setValue(inputEmail);
    }

    const dominoConnect = location.href.includes('dominoConnect')
    const oidcConnect = location.href.includes('oidc-authorize') // callback for openId connect / authorize request
      || location.href.includes('oidc-login-callback') // callback for openId connect / authorize request
      || location.href.includes('login-callback') // callback for openId connect / authorize request


    if (dominoConnect) {
      this.handleDominoUserRedirect();
    } else if (oidcConnect) { // callback for openId connect / authorize request
      this.handleOpenIdConnectAuthorizeCallback();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  login() {
    if (this.loginForm.invalid) {
      return;
    }

    this.normalLoginLoading = true;
    this.authService.login(this.loginForm.value)
      .pipe(finalize(() => {
        this.loginForm.markAsPristine();
        this.normalLoginLoading = false;
      }))
      .subscribe(user => {

        if (this.loginForm.get('remember').value) {
          localStorage.setItem(storageUsernameKey, this.loginForm.value?.username);
        }

        this.redirectAfterLogin(user)

      }, error => {
        this.error = error;
      });
  }

  redirectAfterLogin(user) {
    this.route.queryParams.subscribe(params => {
      // Redirect to the originally wanted route, if any
      // Else if user role is defined, redirect to right home page
      // By default, redirect to home
      const target = params.redirect ? params.redirect : (user.role ? (user.role === Role.Admin ? '/admin' : '/account') : '/');
      this.router.navigate([target], { replaceUrl: true });
    });
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', { validators: [Validators.required] }],
      password: ['', { validators: [Validators.required] }],
      remember: true
    });
  }

  handleDominoUserRedirect() {
    this.menuService.setVisibility(false);

    this.redirecting = true;
    this.redirectingMessage = 'redirecting_from_domino';

    this.authService.afterLogout(false);

    const token = location.href.split('/').pop();

    this.userService.getTokenType(token).subscribe(response => {
      if (response.type === 'dominoUserConnect' && response.user) {
        this.authService.updateUser(response.user, true);
        this.router.navigate(response.user.role === Role.Admin ? ['/admin'] : ['/account']);
      } else {
        this.snackbarService.error(`Erreur, la requête n'a pas renvoyé l'utilisateur`);
        this.router.navigate(['/home']);
      }

      this.authService.startSessionChecker();

      this.redirecting = false;
      this.menuService.setVisibility(true);

    });
  }

  openIdConnect() {
    this.redirecting = true;
    this.redirectingMessage = ' Redirection ';
    this.authService.openIdConnectInitLogin().subscribe(result => {
      if (result.redirectTo) {
        window.location.href = result.redirectTo;
      } else {
        this.snackbarService.error(`Erreur, la requête n'a pas renvoyé l'url de redirection`);
        console.error(`Erreur, la requête n'a pas renvoyé l'url de redirection : `, result)
      }
    })
  }

  handleOpenIdConnectAuthorizeCallback() {
    this.redirecting = true;
    this.redirectingMessage = 'redirecting_from_oidc';

    const authCode = this.activatedRoute.snapshot.queryParams['code']
    const state = this.activatedRoute.snapshot.queryParams['state'];

    this.authService.openIdConnectAuthorizeCallback(authCode, state).subscribe(user => {
      this.redirecting = false;

      if (user.userNotCreated && user.userInfos) {
        this.router.navigate(['/register'])
      } else {
        this.redirectAfterLogin(user);
        this.authService.startSessionChecker();
      }

    }, error => {
      this.redirecting = false;
      console.error(error)
      this.errorMessage = error.msg;
    });
  }

  closeError() {
    this.errorMessage = '';
    // this.router.navigate(['/login'])
    this.location.go('/login') // modify url without change route 
  }

}
