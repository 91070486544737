import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FamilyService, PermissionService } from '@app/services';
import { LasidoPreinscription, LasidoService } from '@app/services/lasido.service';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-lasido-preinscription',
  templateUrl: './lasido-preinscription.component.html',
  styleUrls: ['./lasido-preinscription.component.scss']
})
export class LasidoPreinscriptionComponent implements OnInit, OnDestroy {

  preinscriptions: LasidoPreinscription[];
  permToCreate: boolean;
  permToEdit: boolean;

  onDestroy$ = new Subject();

  constructor(
    private lasidoPreinscriptionService: LasidoService,
    private familyService: FamilyService,
    private router: Router,
    public permService: PermissionService
  ) { }

  ngOnInit(): void {
    this.permToEdit = this.familyService.currentFamily.active;
    this.permToCreate = this.permService.hasPermission('lasido_create') && this.familyService.currentFamily.active;

    this.familyService.currentFamily$.pipe(
      filter(f => !!f),
      takeUntil(this.onDestroy$),
      tap(_ => { this.preinscriptions = null; }),
      switchMap(f => this.lasidoPreinscriptionService.getFamilyPreinscriptions(f))
    ).subscribe(data => this.preinscriptions = data);
  }

  onClickEdit(item: LasidoPreinscription) {
    this.router.navigate([`account/lasido-preinscriptions/${item.id}`]);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
