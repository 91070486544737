<ng-container *ngIf="activitiesByWeek">
  <mat-accordion *ngIf="activitiesByWeek.length; else noActivity">
    <ng-container *ngTemplateOutlet="weekLayout"></ng-container>
  </mat-accordion>

  <ng-template #noActivity>
    <mat-card class="warn-lighter-bg">
      <div class="section-title">
        <mat-icon>warning</mat-icon>
        Aucune activité disponible.
      </div>
    </mat-card>
  </ng-template>
</ng-container>

<ng-template #weekLayout>
  <mat-expansion-panel *ngFor="let week of activitiesByWeek" class="week-panel accent-lighter-bg">
    <mat-expansion-panel-header>
      <mat-panel-title>Semaine {{ week.start|date:'ww' }}</mat-panel-title>
      <mat-panel-description>
        <!-- @TODO: probably better make the format depend on available space (screen width: short for small, else full) -->
        Du {{ week.start|date:'fullDate' }} au {{ week.end|date:'fullDate' }}
      </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-container *ngFor="let weekDay of week.days">
      <mat-card *ngFor="let act of weekDay.activities" class="activity-select mat-elevation-z4" [ngClass]="{
        'selected': act.selected || act.reserved,
        'canceled': act.canceled,
        'disabled': (act.selected || act.reserved ? act.disabledCancel : act.disabled)
      }">
        <mat-card-header (click)="act.disabled ? null : toggleActivity(act)">
          <mat-card-title>{{ act.label || act.name }}</mat-card-title>
          <mat-card-subtitle>
            <span *ngIf="act.datetimeDetails" [innerHTML]="act.datetimeDetails|safe"></span>

            <div *ngIf="!act.datetimeDetails && !act.group">
              Le {{ act.date|date:'longDate' }}
              <span *ngIf="act.startTime && act.endTime">de {{ act.startTime }} à {{ act.endTime }}</span>
            </div>
          </mat-card-subtitle>

          <mat-icon [hidden]="!(act.selected || act.reserved)" class="selected-icon"
            [ngClass]="{'disabled': act.reserved && act.disabledCancel}"
            [matTooltip]="act.selected ? 'Sélectionné' : 'Réservé'">
            done_outline
          </mat-icon>

          <mat-icon [hidden]="!act.canceled" class="canceled-icon" color="warn" matTooltip="Demande d'annulation">
            cancel
          </mat-icon>
        </mat-card-header>

        <mat-card-content class="thin-scrollbar">
          <app-activity-preview [activity]="act"></app-activity-preview>
        </mat-card-content>

        <mat-card-actions align="center">
          <!-- Sélection -->
          <div *ngIf="!act.selected && !act.reserved" class="action-button">
            <button mat-raised-button color="accent" [disabled]="act.disabled" (click)="toggleActivity(act)">
              Sélectionner
              <app-icon *ngIf="act.groupInfo?.length" type="fa" icon="campground" [matTooltip]="act.groupInfo"
                matTooltipClass="tooltip-pre-line" matTooltipPosition="right"></app-icon>
            </button>

            <span *ngIf="act.disabled" class="action-hint">
              {{ ('reservation.error_type.' + act.disabled)|trans }}
            </span>

            <ng-container *ngIf="!act.disabled && liveCapacity">
              <span *ngIf="periode.gestionListeAttente && act.dispos < 1" class="action-hint">
                <mat-icon color="accent" style="font-size: 20px;">format_list_numbered</mat-icon>
                {{ 'reservation.planning.capacity_attente'|trans }}
              </span>

              <span *ngIf="periode.warnLowCapacity && (act.dispos > 0 && act.dispos <= periode.warnLowCapacity)"
                class="action-hint">
                <mat-icon color="accent">offline_bolt</mat-icon>
                {{ 'reservation.planning.capacity_warn'|trans:{places: act.dispos} }}
              </span>
            </ng-container>
          </div>

          <ng-container *ngIf="act.selected || act.reserved">
            <!-- Annulation -->
            <button *ngIf="!(act.reserved && !periode.allowCancel)" mat-raised-button color="warn"
              [disabled]="act.disabledCancel" (click)="toggleActivity(act)">
              Annuler
            </button>

            <!-- Periode doesn't allow cancel -->
            <button *ngIf="act.reserved && !periode.allowCancel" mat-raised-button [disabled]="true">
              Déjà réservé
              <app-icon *ngIf="act.groupInfo?.length" type="fa" icon="campground" [matTooltip]="act.groupInfo"
                matTooltipClass="tooltip-pre-line" matTooltipPosition="right"></app-icon>
            </button>
          </ng-container>
        </mat-card-actions>
      </mat-card>
    </ng-container>

  </mat-expansion-panel>
</ng-template>

<mat-accordion>
</mat-accordion>

<ng-template #noActivity>
  <mat-card class="warn-lighter-bg">
    <mat-icon>priority_high</mat-icon>
    Aucune activité disponible.
  </mat-card>
</ng-template>
