import { Component, OnInit } from '@angular/core';
import { News } from '@app/models/news';
import { NewsService } from '@app/services';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-news-by-category',
  templateUrl: './news-by-category.component.html',
  styleUrls: ['./news-by-category.component.scss']
})
export class NewsByCategoryComponent implements OnInit {

  newsList: News[];
  loading: boolean;

  constructor(
    private newsService: NewsService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      tap(_ => {
        this.loading = true;
      }),
      mergeMap((url) => {
        return this.filterNews(url);
      })
    ).subscribe(data => {
      this.newsList = data;
      this.loading = false;
    })
  }

  filterNews(url: ParamMap) {
    let cat = url.get('idCategories').split('-').toString();
    return this.newsService.getNewsByCategory(cat);
  }

}
