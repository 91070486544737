import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiviteDay, HoursData, Periode, PeriodeActivite, PeriodePlage, PeriodeRubrique, PeriodeTreeItem, PeriodeType } from '@app/models/periode';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiCrudService } from './api-crud.service';

interface PeriodesForInscriptionResult {
  treeData?: PeriodeTreeItem[];
  error?: string;
  errorSteps?: { stepName: string, label: string, link?: string }[];
  errorVaccins?: string[];
  errors?: string[];
  traces?: string[];
  messages?: string[];
}

export interface InscriptionPeriodeResult extends Periode {
  traces?: string[];
  errors?: any[];
  error?: string;
  errorVaccins?: string[];
  messages?: string[];
}

export interface EtablissementFermeture {
  startDate: string;
  endDate: string;
  detail: string;
  idEtablissement: number;
}

export const weekDays = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];

@Injectable({
  providedIn: 'root'
})
export class PeriodeService extends ApiCrudService<Periode> {

  url = 'reservations/periodes';

  constructor(
    protected http: HttpClient
  ) {
    super();
  }

  getAllType(type: 'diabolo' | 'mikado') {
    // Imprecise return type ... another reason to make 2 different methods : "getTree" / "getList"
    return this.http.get<PeriodeTreeItem[] | Periode[]>('reservations/periodes-all/' + type);
  }

  getPeriodeDetail(idPeriode) {
    const url = this.url + '/' + idPeriode;

    return this.http.get<Periode>(url).pipe(
      map(data => this.parsePeriodeData(data))
    );
  }

  getInscriptionPeriode(idPeriode, idInscription): Observable<InscriptionPeriodeResult> {
    const url = this.url + '/' + idPeriode + '/' + idInscription;

    return this.http.get<InscriptionPeriodeResult>(url).pipe(
      map(p => this.parsePeriodeData(p))
    );
  }

  getMultiplePeriodes(ids) {
    return this.http.get<Periode[]>(`reservations/periodes-multiple/${ids}`).pipe(
      tap(periodes => periodes.forEach(p => this.computePeriodeActivitiesHours(p)))
    );
  }

  parsePeriodeData(periode: Periode) {

    if (periode.rubriques) {
      // Sort by order if defined, or hours by default (+ init order)
      const orderFound = periode.rubriques.find(rub => rub.order);
      if (!orderFound) {
        periode.rubriques.sort((r1, r2) => {
          const r1Hours = r1.horaires[0];
          const r2Hours = r2.horaires[0];

          if (r1Hours?.start && r2Hours?.start) {
            if (r1Hours.start === r2Hours.start) {
              if (r1Hours.end && r2Hours.end) {
                return r1Hours.end === r2Hours.end ? 0 : (r1Hours.end > r2Hours.end ? 1 : -1);
              } else {
                return 0;
              }
            } else {
              return r1Hours.start > r2Hours.start ? 1 : -1;
            }
          } else {
            return 0;
          }

        });
        periode.rubriques.forEach((rub, i) => rub.order = i + 1);
      } else {
        periode.rubriques.sort((r1, r2) => r1.order === r2.order ? 0 : !r1.order || r1.order > r2.order ? 1 : -1);
      }
    }

    if (periode.activites) {
      // Sort activities days (for display), pre-compute days based data, like price, range dates, etc.
      periode.activites.forEach(a => {
        if (a.days && a.days.length) {
          a.days.sort((ad1, ad2) => ad1.date > ad2.date ? 1 : -1);
          a.fromDate = a.days[0].date;
          a.toDate = a.days[a.days.length - 1].date;
        }

        a.days.forEach(ad => {
          ad._computedHours = this.getActivityHours(ad, ad.rubrique ? periode.rubriques.find(r => r.id === ad.rubrique) : null);
        })
      });
    }

    return periode;
  }

  computePeriodeActivitiesHours(periode: Periode) {
    periode.activites.forEach(act => act.days.forEach(ad => {
      ad._computedHours = this.getActivityHours(ad, ad.rubrique ? periode.rubriques.find(r => r.id === ad.rubrique) : null);
    }));
  }

  getActivityHours(activity: ActiviteDay, rubrique: PeriodeRubrique) {
    if ((!activity.startTime || !activity.endTime) && activity.rubrique) {
      return rubrique?.horaires || [];
    }

    return [{ start: activity.startTime, end: activity.endTime }] as HoursData[];
  }

  savePeriode(periode: Periode) {
    if (periode.id || periode.idPortail) {
      return this.http.put(`${this.url}/${this.getIdPeriode(periode)}`, periode);
    } else {
      return this.http.post(this.url, periode);
    }
  }

  getPeriodesForInscription(id: number) {
    return this.http.get<PeriodesForInscriptionResult>(`reservations/periodes-for-inscription/${id}`);
  }

  getPeriodeTreeNodeChildren(node: PeriodeTreeItem): PeriodeTreeItem[] {
    return node.periodes || node.accueils || node.etablissements || [];
  }

  getDateSelectionModes() {
    return [
      { value: 'free', label: 'Libre' },
      { value: 'day', label: 'Par jour' },
      { value: 'week', label: 'Par semaine' },
      { value: 'month', label: 'Par mois' },
      { value: 'period', label: 'Pour la période complète' }
    ];
  }

  plagesMatch(plages: PeriodePlage[], refPlages: PeriodePlage[]) {
    return !!plages && plages.length && !!refPlages && refPlages.length && !refPlages.find(x => !plages.find(y => y.id === x.id));
  }

  copyPeriodeConfig(from: Periode, to: Periode) {
    // General
    to.planningMessage = from.planningMessage;

    // Saisie dates
    to.saisieDebut = from.saisieDebut;
    to.saisieFin = from.saisieFin;

    // Options
    for (const key of ['allowCancel', 'enableWeekView', 'factureEstimate', 'liveCapacity', 'warnLowCapacity']) {
      if (from[key] !== undefined) {
        to[key] = from[key];
      }
    }

    // Limite dates
    to.limiteSaisie = from.limiteSaisie;
    to.limiteAnnulation = from.limiteAnnulation;

    // Modes
    for (const key of ['modeHoraires', 'modeSelection', 'modeValidation']) {
      if (from[key] !== undefined) {
        to[key] = from[key];
      }
    }

    // Programs
    to.programBeforePlanning = from.programBeforePlanning;
    to.programOnValidate = from.programOnValidate;

    to.trtPlanningDomino = from.trtPlanningDomino;

    // Rubriques (only copy common rubriques)
    to.rubriques.forEach((rub, i) => {
      const matchRub = from.rubriques.find(mr => mr.id === rub.id);
      if (matchRub) {
        to.rubriques[i] = matchRub;
      }
    });

    // Activities (only copy common activities)
    to.activites.forEach(act => {
      const matchAct = from.activites.find(ma => ma.id === act.id);
      if (matchAct) {
        act.enabled = matchAct.enabled;
        act.label = matchAct.label;
        act.description = matchAct.description;
      }
    });

    // Rubrique chains (only for common rubriques)
    (from.rubriqueChains || []).forEach(chain => {
      // If we find a member in chain that doesn't exist in target rubriques, or disabled, we can't copy the chain
      const incompatible = chain.find(cm => !to.rubriques.find(rub => rub.id === cm && rub.enabled));
      if (!incompatible) {
        // Avoid pushing values to null :p
        if (!to.rubriqueChains) {
          to.rubriqueChains = [];
        }
        to.rubriqueChains.push(chain);
      }
    });
  }

  getJoursFeries() {
    return this.http.get<{ date: string, detail: string }[]>('reservations/jours-feries');
  }

  getJoursFermeture(type: PeriodeType) {
    return this.http.get<EtablissementFermeture[]>(`reservations/fermetures/${type}`);
  }

  getAccueiltree(type: PeriodeType) {
    return this.http.get<PeriodeTreeItem[]>(`reservations/accueil-tree/${type}`);
  }

  getIdPeriode(periode) {
    return periode.id || 'c:' + periode.idPortail;
  }

  getLimiteDateFormFields() {
    return {
      type: [],
      // Fixed
      fixedDate: [],
      // Variable
      number: [],
      unit: [],
      // Week fixed
      weekDay: [],
      // Month fixed
      monthDate: [],
      // Month param
      monthDay: [],
      monthDayOrder: [],
      // Time
      time: ['23:00'],
      toleranceMinutes: [5]
    };
  }

  getRubriquesFor(type: string, accueils?: number[]) {
    const formatted = accueils ? accueils.join('-') : '';

    return this.http.get<PeriodeRubrique[]>(`reservations/rubriques/${type}/${formatted}`);
  }

  getDispoPlanningByEtabGroupDateMode(idEtab: number, idGroup: number, date: string | Date, mode: number) {
    return this.http.get(`reservations/dispoPlanning/${idEtab}/${idGroup}/${date}/${mode}`);
  }
}
