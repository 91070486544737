<nav *ngIf="!compact" class="column" style="height: 100%;">
  <mat-nav-list>
    <ng-container *ngIf="!previewMode">
      <div *ngIf="platformService.isMobile && headerConfig" class="title-container primary-bg"
        [style.backgroundColor]="headerConfig.title?.style?.backgroundColor">
        <img *ngIf="headerConfig.logo?.enabled" [src]="headerConfig.logo.image" routerLink="/" alt="logo"
          [ngStyle]="{'height': headerConfig.logo.height + 'px', 'width': headerConfig.logo.width + 'px'}">
        <span *ngIf="headerConfig.title?.enabled" class="title-portail" [portailCustomStyle]="headerConfig.title?.style"
          [exclude]="['backgroundColor']">
          {{ headerConfig.title?.text }}
        </span>
      </div>

      <mat-divider></mat-divider>

      <mat-toolbar *ngIf="currentUser && currentAdulte" class="sidebar-header">
        <div fxLayout="column" style="width: 100%;">
          <div class="user-name-container">

            <mat-icon *ngIf="!currentUser.fromOidc" class="avatar-icon" color="primary" [matTooltip]="userNameTooltip"
              [tooltipClass]="login-tooltip">
              account_box</mat-icon>

            <img *ngIf="currentUser.fromOidc" [src]="externalAuth.providerLogo" class="oidc-logo"
              [matTooltip]="userNameTooltip" [tooltipClass]="login-tooltip" />


            <span class="userlabel" [title]="currentUser.username">{{ currentUser.username }}</span>
            <span fxFlex></span>
          </div>

          <!-- If have multiple account type -->
          <div
            *ngIf="currentAdulte.families?.length > 1 || (currentAdulte.families?.length && currentAdulte.assistantMaternel && enabledAssmatAccount)"
            style="margin-top: 10px;">

            <mat-form-field *ngIf="account" matTooltip="La sélection du compte n'est pas disponible depuis cette page."
              [matTooltipDisabled]="!accountSwitchDisabled" matTooltipPosition="above" #tooltip="matTooltip"
              (click)="tooltip.toggle()" (mouseenter)="$event.stopImmediatePropagation()"
              (mouseleave)="$event.stopImmediatePropagation()">

              <mat-label>Type de compte :</mat-label>

              <mat-select [ngModel]="account" (ngModelChange)="onChangeAccountType($event)"
                [disabled]="accountSwitchDisabled">
                <mat-option *ngIf="currentAdulte.assistantMaternel && enabledAssmatAccount" value="assmat">Assistant
                  Maternel</mat-option>
                <mat-option *ngFor="let family of currentAdulte.families" [value]="family.id">
                  Famille {{ family.nom }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

      </mat-toolbar>

      <mat-divider></mat-divider>
    </ng-container>

    <div *ngFor="let item of (previewMode ? items : menuService.menuItems$|async)">

      <!-- Item création de compte à part car il peut ou non être actif dans les paramètres d'autorisation -->
      <!-- juste un fix temporaire, voir pour refacto le code ci-dessous par la suite -->
      <mat-list-item
        *ngIf="!previewMode && item.link && item.enabled && showToUser(item) && showDependOnRole(item) && (item.link === '/register') && permService.hasPermission('account_create')"
        (click)="onMenuLinkClick()" [routerLink]="item.link" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}">
        <ng-container *ngTemplateOutlet="contenuItem; context: {item:item}"></ng-container>
      </mat-list-item>

      <mat-list-item
        *ngIf="!previewMode && item.link && item.enabled && showToUser(item) && showDependOnRole(item) && !(item.link === '/register')"
        (click)="onMenuLinkClick()" [routerLink]="item.link" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}">
        <ng-container *ngTemplateOutlet="contenuItem; context: {item:item}"></ng-container>
      </mat-list-item>

      <mat-list-item *ngIf="previewMode && item.link && item.enabled && showToUser(item) && showDependOnRole(item)"
        (click)="onCustomItemClick(item, $event)">
        <ng-container *ngTemplateOutlet="contenuItem; context: {item:item}"></ng-container>
      </mat-list-item>

      <mat-list-item *ngIf="item.itemAdded && !item.link && item.enabled && showToUser(item) && showDependOnRole(item)"
        (click)="onCustomItemClick(item, $event)">
        <ng-container *ngTemplateOutlet="contenuItem; context: {item:item}"></ng-container>
      </mat-list-item>

    </div>
  </mat-nav-list>

  <div style="max-width: 270px" *ngIf="!compact && !previewMode">
    <app-menu-custom-links></app-menu-custom-links>
  </div>

  <div class="spacer"></div>

  <app-theme-switch *ngIf="themeSwitchEnabled"></app-theme-switch>

  <span *ngIf="env?.version" class="app-version" (click)="showChangelog()" [class.clickable]="changelogDisplayable">
    Version {{ env.version }}
  </span>
</nav>

<ng-template #contenuItem let-item="item">
  <mat-icon *ngIf="item.icon && !item.iconDomino && !item.iconFontawesome" class="item-mat-icon">{{item.icon}}
  </mat-icon>
  <app-icon *ngIf="item.iconFontawesome || item.iconDomino"
    [icon]="item.iconFontawesome ? item.iconFontawesome : item.iconDomino"
    [type]="item.iconDomino ? 'do' : (!item.typeIcon && item.iconFontawesome) ? 'fa' : item.typeIcon" [size]="24"
    style="margin-right: 12px;">
  </app-icon>
  <span class="menu-item-label">{{item.label|trans}}</span>
  <mat-divider></mat-divider>
</ng-template>


<!-- <ng-container *ngIf="compact">
  <span *ngFor="let item of items">
    <span *ngIf="item.children && item.children.length">
      <button mat-icon-button #trigger="matMenuTrigger" [matMenuTriggerFor]="menu.childMenu"
        [matTooltip]="item.label|trans|titlecase">
        <mat-icon>{{ item.icon }}</mat-icon>
      </button>

      <app-menu-item #menu [items]="item.children"></app-menu-item>
    </span>

    <span *ngIf="!item.children || !item.children.length">
      <button mat-icon-button [routerLink]="item.link" [matTooltip]="item.label|trans|titlecase">
        <mat-icon>{{ item.icon }}</mat-icon>
      </button>
    </span>
  </span>
</ng-container> -->
