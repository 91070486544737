import { Injectable } from '@angular/core';
import { GlobalConfigService } from './global-config.service';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@app/services/authentication.service';
import { Role } from '@app/models/user';

export interface PermissionConfig {
  account_create?: boolean;
  account_edit?: boolean;
  child_create?: boolean;
  child_edit?: boolean;
  preinscription_create?: boolean;
  preinscription_edit?: boolean;
  reservation_diabolo?: boolean;
  reservation_mikado?: boolean;
  foyer_edit?: boolean;
  lasido_create?: boolean;
  cogito_create?: boolean;
  cogito_edit?: boolean;
  cogito_inscriptions_create?: boolean;
  cogito_inscriptions_edit?: boolean;
  informations_assmat_edit?: boolean;
  disponibilites_edit?: boolean;
  enabled_assmat_account?: boolean;
  contrat_accueil_edit?: boolean;
  contrat_accueil_create?: boolean;
  animations_add?: boolean;
  reunions_add?: boolean;
  child_photo?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  url = 'permissions';

  permission$ = this.globalConfigService.getPart('permissions') as Observable<PermissionConfig>;

  constructor(
    private authService: AuthenticationService,
    private globalConfigService: GlobalConfigService
  ) { }

  update(perm) {
    return this.globalConfigService.updatePart('permissions', perm, true);
  }

  getPermissionSettings() {
    return this.globalConfigService.settings.permissions;
  }

  hasPermission(perm: keyof PermissionConfig) {
    const permObject = this.getPermissionSettings();

    if (permObject && (perm in permObject)) {
      return permObject[perm];
    }

    return false;
  }

  isManager() {
    return this.authService.hasRole(Role.Manager);
  }
}
