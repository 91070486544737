import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { interval, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

const VERSION_CHECK_INTERVAL_SEC = 30;

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  appUpdateSubject = new Subject();

  constructor(
    private swUpdate: SwUpdate
  ) { }

  // No longer used, for now
  // clearCacheForRequest(request: HttpRequest<any>) {
  //   window.caches.keys().then(keys => keys.forEach(k => {
  //     window.caches.open(k).then(cache => cache.keys().then(cacheKeys => {
  //       cacheKeys.filter(cacheRequest => cacheRequest.url.includes(request.url)).forEach(found => {
  //         cache.delete(found);
  //       });
  //     }));
  //   }));
  // }

  initNewAppChecker() {
    // Check that SW is enabled
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe(event => {
        console.log('Version update event : ', event);

        switch (event.type) {
          case 'VERSION_DETECTED':
            console.log(`# New App version detected : ${event.version.hash} (will be installed when ready) #`);
            break;
          case 'VERSION_READY':
            console.log(`# Updating App : from version ${event.currentVersion.hash} to ${event.latestVersion.hash} #`);
            this.swUpdate.activateUpdate().then(x => this.appUpdateSubject.next());
            break;
          case 'VERSION_INSTALLATION_FAILED':
            throw 'Version update failed.';
        }
      });

      // At regular interval (30 sec by default), check for update ! (Angular SW ...)
      interval(VERSION_CHECK_INTERVAL_SEC * 1000).pipe(startWith(0)).subscribe(_ => {
        this.checkForUpdate();
      });
    }
  }

  checkForUpdate() {
    console.log('Checking for update');
    this.swUpdate.checkForUpdate();
  }

  // Could add a method to clear all app cache (on user logout / switch family ?)
}
