import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription, timer } from 'rxjs';
import { User, ROLES_HIERARCHY, AccountType } from '@app/models/user';
import { HttpClient } from '@angular/common/http';
import { distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import moment from 'moment';
import { SnackbarService, SessionService } from './';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

const storageKey = 'currentUser';
const storageAccountType = 'remember-account-type';

const SESSION_CHECK_INTERVAL = 30;

/**
 * Authentication service.
 * @TODO: rename to UserService
 */
@Injectable()
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>;
  private storage: Storage;

  currentUser$: Observable<User>;

  private accountTypeSubject = new BehaviorSubject<AccountType>(null);

  accountType$ = this.accountTypeSubject.asObservable();
  accountTypeChange$ = this.accountType$.pipe(distinctUntilChanged());

  sessionChecker: Subscription;

  creatingUserFromOIDC: any = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private sessionService: SessionService
  ) {
  }

  init(loadUserFromStorage) {
    this.storage = this.sessionService.dominoConnect ? sessionStorage : localStorage;
    const user = loadUserFromStorage ? this.getUserFromStorage() : null;
    this.currentUserSubject = new BehaviorSubject<User>(user);
    this.currentUser$ = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(loginData: LoginContext) {

    this.storage = loginData.remember ? localStorage : sessionStorage;

    return this.http.post<any>('authenticate', loginData).pipe(
      map(user => {

        // login successful if token is provided
        if (user && user.token) {
          // Store user details (token, etc) to keep logged between pages
          this.updateUser(user);
        }

        return user;
      })
    );
  }

  logout(redirectToLogin = true): Observable<any> {
    console.warn('logout / redirectToLogin:', redirectToLogin)
    return this.http.get('/logout').pipe(
      tap(res => {
        if (!res || !res.oidcLogout || res.disconnected) {
          this.afterLogout(redirectToLogin)
        }
      })
    );
  }

  afterLogout(redirectToLogin = true) {
    console.warn('afterLogout')
    localStorage.setItem(storageAccountType, this.getAccountType());
    this.updateUser(null);
    this.sessionService.updateSession(null, true);
    // Maybe no need to be dependant / could be outside of observable call ?
    if (redirectToLogin) {
      this.router.navigate(['/login'], { replaceUrl: true });
    }
  }

  get isAuthenticated() {
    return !!this.currentUserValue;
  }

  private getUserFromStorage() {
    let user = this.storage.getItem(storageKey);
    return JSON.parse(user);
  }

  private storeUser(user?: User) {
    this.clearStoredUser();

    if (user) {
      this.storage.setItem(storageKey, JSON.stringify(user));
    }
  }

  private clearStoredUser() {
    sessionStorage.removeItem(storageKey);
    localStorage.removeItem(storageKey);
  }

  get role() {
    return this.currentUserValue ? this.currentUserValue.role : null;
  }

  updateUser(user: User, fromDomino = false, silent = false) {

    if (fromDomino) {
      this.storage = sessionStorage;
    }

    this.storeUser(user);

    if (!silent) {
      this.currentUserSubject.next(user);
    }
  }

  hasRole(role: string) {
    return this.hasRoleChild(this.role, role);
  }

  // @TODO: ensure this function works as expected .. not sure about recursivity on such a basic / central function
  private hasRoleChild(role: string, child: string) {
    if (role === child) {
      return true;
    }

    if (ROLES_HIERARCHY.hasOwnProperty(role)) {
      for (const innerRole of ROLES_HIERARCHY[role]) {
        // First check is just to ensure we don't have a Admin => [Admin] infinite loop
        if (innerRole !== role && this.hasRoleChild(innerRole, child)) {
          return true;
        }
      }
    }

    return false;
  }

  updateRole(role: string) {
    const userValue = this.currentUserValue;

    if (userValue) {
      userValue.role = role;
      this.updateUser(userValue);
    }
  }

  updateExpiration(date: Date) {
    const userValue = this.currentUserValue;

    if (userValue && userValue.sessionExpiration < date) {
      userValue.sessionExpiration = date;
      this.updateUser(userValue);
    }
  }

  isUserExpired() {
    const expiration = this.currentUserValue?.sessionExpiration;

    // Normally this should take care of "TimeZone"
    return !expiration || moment().isAfter(expiration);
  }

  getAccountType() {
    return this.accountTypeSubject.value;
  }

  setAccountType(value: AccountType) {
    this.accountTypeSubject.next(value);

    const user = this.currentUserValue;

    if (user) {
      user.accountType = value;
      this.updateUser(user);
    }
  }

  storeIdAssmat(value: number) {
    const user = this.currentUserValue;

    if (user) {
      user.idAssmat = value;
      this.updateUser(user);
    }
  }

  updateEmail(user: User, newEmail: string) {
    return this.http.post<{ status: string }>(`users/update-email`, { id: user.id, newEmail });
  }

  sendUpdateEmailToken(token: string) {
    return this.http.post<{ status: string }>(`users/update-email-token`, { token });
  }

  // Unsubscribe previous session checker if there was any ...
  clearSessionChecker() {
    if (this.sessionChecker) {
      this.sessionChecker.unsubscribe();
      this.sessionChecker = null;
    }
  }

  setSessionChecker() {
    this.clearSessionChecker();

    if (this.isAuthenticated) {
      // Check every 10 sec that user session isn't expired
      this.sessionChecker = timer(0, SESSION_CHECK_INTERVAL * 1000).pipe(
        map(_ => this.isUserExpired()),
        filter(expired => expired),
        take(1)
      ).subscribe(expired => {
        // no need if user is already logged out
        if (this.isAuthenticated) {
          // autologout
          this.logout().subscribe(_ => {
            this.dialog.openDialogs.forEach(x => x.close());
            this.translate.get('autologout_message').subscribe(message => this.snackbar.error(message));
          });
        }
      });
    }
  }

  startSessionChecker() {
    this.currentUser$.pipe(
      // only when user really changes (logs in/out), refresh "sessionChecker"
      distinctUntilChanged((x, y) => x?.id === y?.id)
    ).subscribe(user => this.setSessionChecker());
  }

  openIdConnectInitLogin() {
    return this.http.get<any>('oidc/init-authorize')
  }


  openIdConnectAuthorizeCallback(code, state) {
    const data = { code, state }
    return this.http.post<any>("oidc/authorize-callback", data).pipe(
      map(user => {

        if (!!user.error) {
          console.error('Erreur lors du retour OpenIdCallback.', user)
          throw (user.error)
        }
        // login successful if token is provided
        if (user && user.token) {
          this.storage = localStorage // forced to  localStorage to keep the connection
          // Store user details (token, etc) to keep logged between pages
          this.updateUser(user);
        } else if (user.userNotCreated && user.userInfos && user.adulte) {
          this.creatingUserFromOIDC = user;
          sessionStorage.setItem('creatingUser', JSON.stringify(user))
        } else {
          console.error('Erreur lors du retour OpenIdCallback : token non trouvé.', user)
          throw {
            code: 'none',
            msg: `Un problème est survenu lors du retour du serveur d'authentification: aucun des paramètres attendus n'a été trouvé`,
            detail: user
          }
        }

        return user;
      })
    );
  }

  openIdConnectLogoutCallback(state) {
    return this.http.get<any>("oidc/logout-callback/" + state).pipe(
      tap(_ => {
        this.afterLogout(false)
      })
    );
  }

}
