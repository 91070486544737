
  <mat-card *ngIf="errorMessage!=''" class="error-container" >
    <mat-card-title class="mat-error">
      <mat-icon color="warn" style="float: left; margin-right: 10px;">error</mat-icon>
      <span>Erreur</span>
      <button mat-icon-button aria-label="dismiss" (click)="errorMessage='';" style="float:right;">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-card-title>
    <mat-card-content>
      <pre [innerHTML]="errorMessage" class="mat-error" style="text-align: left;"></pre>
    </mat-card-content>
  </mat-card>

<div id="form" [ngClass]="{'formWithSteps':formFields.length > 1}">
  <!-- Here we listen to enter.key event to prevent default submit if we're not
    on the last step of a form, instead it will validate and go next step -->
  <form [formGroup]="form" (keydown.enter)="onEnter($event)" (ngSubmit)="onSubmit()">

    <ng-container *ngIf="formFields.length > 1 else singleStepForm">

      <mat-horizontal-stepper *ngIf="containerWidth>=verticalStepperBeakpoint" [(selectedIndex)]="activedStep"
        (selectionChange)="onStepChange($event)" [linear]="!admin">

        <ng-container *ngFor="let formStep of formFields; let index = index; let last = last;">

          <mat-step *ngIf="formStep?.enabled && !formStep?.old" [stepControl]="form.at(index)">

            <ng-template matStepLabel>{{formStep?.label}}</ng-template>

            <!-- <ng-template matStepContent *ngIf=""> -->

              <ng-container *ngIf="!isLoading;else loader">

              <mat-card class="form-step-card">

                <mat-card-header>
                  <mat-icon mat-card-avatar class="icon">assignment</mat-icon>
                  <mat-card-title>{{ formStep.label }}</mat-card-title>
                </mat-card-header>

                <mat-card-content>
                  <formly-form [form]="form.at(index)" [model]="model" [options]="options[index]"
                  [fields]="formStep.fields" (modelChange)="onModelChange($event)"></formly-form>
                </mat-card-content>

                <mat-divider></mat-divider>

                <mat-card-actions style="padding:12px;" fxLayout="row" fxLayoutAlign="center center">
                
                  <button *ngIf="index !== 0" matStepperPrevious mat-raised-button color="primary" type="button">
                    <mat-icon>arrow_back</mat-icon> Retour
                  </button>

                  <button *ngIf="!last" matStepperNext mat-raised-button color="primary" type="button"
                  (click)="validate(index)">
                  Suivant <mat-icon>arrow_forward</mat-icon>
                  </button>

                  <button *ngIf="!admin && last && !readOnly" mat-raised-button color="accent"
                    [disabled]="suspendSubmit || !form.valid">
                    <mat-icon>check</mat-icon> Valider
                  </button>

                 <!-- <button type="button" (click)="logForm();$event.preventDefault()"> Voir le model </button> -->
                </mat-card-actions>

              </mat-card>

            </ng-container>

          <!-- </ng-template> -->

        </mat-step>

      </ng-container>
       
    </mat-horizontal-stepper>

      <mat-vertical-stepper *ngIf="containerWidth<verticalStepperBeakpoint" [(selectedIndex)]="activedStep"
        (selectionChange)="onStepChange($event)" [linear]="!admin">
        <ng-container *ngFor="let formStep of formFields; let index = index; let last = last;">
          <mat-step *ngIf="formStep?.enabled" [label]="formStep?.label" [stepControl]="form.at(index)">
            <!-- <mat-card class="form-step-card"> -->
            <!-- <mat-card-header>
                <mat-icon mat-card-avatar class="icon">assignment</mat-icon>
                <mat-card-title>{{ formStep.label }}</mat-card-title>
              </mat-card-header> -->
            <!-- <mat-card-content> -->
              <ng-container *ngIf="!isLoading; else loader">
                <formly-form [form]="form.at(index)" [model]="model" [options]="options[index]" [fields]="formStep.fields"
                (modelChange)="onModelChange($event)">
                </formly-form>
              </ng-container>
            
            <!-- </mat-card-content> -->
            <!-- <mat-divider></mat-divider> -->
            <!-- <mat-card-actions style="padding:12px;"> -->
            <button *ngIf="index !== 0" matStepperPrevious mat-raised-button color="primary" type="button">
              <mat-icon>arrow_back</mat-icon> Retour
            </button>
            <button *ngIf="!last" matStepperNext mat-raised-button color="primary" type="button"
              (click)="validate(index)">
              Suivant <mat-icon>arrow_forward</mat-icon>
            </button>
            <button *ngIf="!admin && last && !readOnly" mat-raised-button color="accent"
              [disabled]="suspendSubmit || !form.valid">
              <mat-icon>check</mat-icon> Valider
            </button>
            <!-- <button (click)="logForm()"> Voir le model </button> -->
            <!-- </mat-card-actions> -->
            <!-- </mat-card> -->
          </mat-step>
        </ng-container>
      </mat-vertical-stepper>
    </ng-container>

    <ng-template #loader>
      <div style="width: 100%; height: 50vh; display:flex;">
        <app-loader message="Chargement..." style="text-align: center;">
        </app-loader>
      </div>
    </ng-template>

    <ng-template #singleStepForm>
      <ng-container *ngIf="formFields[0] as formStep">
        <mat-card class="form-step-card" *ngIf="(containerWidth>=verticalStepperBeakpoint)">
          <mat-card-header>
            <mat-icon mat-card-avatar class="icon">assignment</mat-icon>
            <mat-card-title>{{ formStep.label }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <formly-form [form]="form.at(0)" [model]="model" [options]="options[0]" [fields]="formStep.fields"
              (modelChange)="onModelChange($event)">
            </formly-form>
          </mat-card-content>
          <mat-divider></mat-divider>
          <mat-card-actions style="padding:10px;">
            <div class="text-center">
              <button *ngIf="!admin && !readOnly" mat-raised-button color="accent" [disabled]="suspendSubmit ">
                <!--|| !form.valid -->
                <mat-icon>check</mat-icon> Valider
              </button>
              <!-- <button (click)="$event.preventDefault();logForm()"> form </button> -->
            </div>
          </mat-card-actions>
        </mat-card>
        <div *ngIf="(containerWidth<verticalStepperBeakpoint)" class="small-width-container">
          <h2 class="mat-title">
            <mat-icon mat-card-avatar class="icon">assignment</mat-icon>
            <span>{{ formStep.label }}</span>
          </h2>
          <formly-form [form]="form.at(0)" [model]="model" [options]="options[0]" [fields]="formStep.fields"
            (modelChange)="onModelChange($event)">
          </formly-form>
          <mat-divider></mat-divider>
          <div class="text-center">
            <button *ngIf="!admin && !readOnly" mat-raised-button color="accent" [disabled]="suspendSubmit ">
              <mat-icon>check</mat-icon> Valider
            </button>

            <!--<button (click)="logForm()"> Voir le modeleeee </button>-->
          </div>
        </div>
      </ng-container>
    </ng-template>

  </form>
</div>
<!-- </div> -->
