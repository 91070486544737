import { Adulte } from './adulte';
import { Child } from './child';
import { Inscription } from './inscription';

export class Consumer {

  id: string; // 'e:'+idEnfant => for child  ||'a:'+idAdulte => for adult // must be set with Consumer.buildIdConsumer()
  idEnfant: number;
  idAdulte: number;
  idFamille: number;
  type: 'enfant' | 'user' | 'conjoint' | 'autreAdulte'; // autreAdulte pas encore géré au niveau des formulaires

  nom: string;
  prenom: string;
  // prenom2?: string;

  dateDebut?: string;
  dateFin?: string;

  dateNaissance: Date | string;
  age?: { years?: number, months?: number };
  sexe: 'M' | 'F';
  // lieuNaissance: string;
  // depNaissance: string; // (actually number)

  inscriptions?: Inscription[];

  static buildIdConsumer(idEnfant: number, idAdulte: number): string {
    return idEnfant ? 'e:' + idEnfant : 'a:' + idAdulte;
  }
}

export interface UsagerChild extends Child {
  type?: string;
}

interface UsagerAdulte extends Adulte {
  type?: string;
}

export type Usager = UsagerChild | UsagerAdulte;
