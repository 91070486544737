import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { EditorComponent } from '@tinymce/tinymce-angular';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'form-editor-type',
  templateUrl: './editor-type.component.html',
  styleUrls: ['./editor-type.component.scss']
})
export class EditorTypeComponent extends FieldType implements OnInit, AfterViewInit {
  ready: boolean;

  // @ViewChild("editor", { static: false }) editorRef: EditorComponent;

  init = {
    base_url: '/tinymce',
    suffix: '.min',
    plugins: '',
    toolbar: `bold italic underline | fontsizeselect | forecolor backcolor |
    alignleft aligncenter alignright ` ,
    toolbar_drawer: 'floating',
    menubar: false,
    forced_root_block: '',
    language: 'fr_FR',
    language_url: '/assets/i18n/tinymce/fr_FR.js',
    branding: false
  };

  ngOnInit() {
    // Have to wait for iframe to load, or TinyMCE will throw error on doc.open() in initContentBody()
    setTimeout(() => this.ready = true);
  }

  //Approche différente qui corrige le warning de la console mais qui ne fonctionne pas avec le code présent dans ngOnInit().
  ngAfterViewInit(){
    // setTimeout(() => {
    //   if(this.ready){
    //     this.editorRef.disabled = this.field.templateOptions.disabled;
    //   }
    // });
  }

}
