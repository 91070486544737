<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" [cdkDragDisabled]="platformService.isMobile">
  <mat-toolbar mat-dialog-title color="primary" cdkDragHandle>
    <button class="close-dialog" mat-icon-button [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
    <span class="title">
      {{ date|date:'EEEE dd MMMM y'|titlecase }}
    </span>
  </mat-toolbar>
</div>

<div mat-dialog-content class="thin-scrollbar">

  <mat-tab-group *ngIf="rubriques.length && activities.length; else singleList" mat-stretch-tabs class="content-tab"
    [(selectedIndex)]="tabIndex">
    <mat-tab [label]="'reservation.periode.rubriques'|trans">
      <ng-container *ngTemplateOutlet="rubriquesList"></ng-container>
    </mat-tab>

    <mat-tab>
      <ng-template matTabLabel>
        <span [matBadge]="activities.length" matBadgeOverlap="false" matBadgeColor="accent">
          {{ 'reservation.periode.activities'|trans }}
        </span>
      </ng-template>

      <ng-container *ngTemplateOutlet="activitiesList"></ng-container>
    </mat-tab>
  </mat-tab-group>
</div>

<div mat-dialog-actions align="center">
  <button *ngIf="!periode.disableRecopy" mat-raised-button color="accent-lighter" (click)="openRepeatOptions()">
    Recopier la sélection
  </button>
  <button mat-raised-button color="primary-lighter" (click)="validate()" [disabled]="!formValid">
    Valider
  </button>
</div>

<ng-template #noRubrique>
  <h3 style="text-align: center;">Pas de rubriques disponibles</h3>
</ng-template>

<ng-template #singleList>
  <ng-container *ngIf="activities.length; else rubriquesList" [ngTemplateOutlet]="activitiesList"></ng-container>
</ng-template>

<ng-template #rubriquesList>
  <div class="thin-scrollbar">
    <div *ngIf="rubriques.length; else noRubrique" class="rubrique-select-list">

      <mat-card *ngIf="hasError" class="error-message warn-bg">
        Une erreur est survenue : certains éléments ne peuvent être réservés.
      </mat-card>

      <div *ngFor="let rubrique of rubriques; let i = index" class="rubrique-list-row" [ngClass]="{
        'selected': rubrique.selected || rubrique.existing,
        'disabled': rubrique.disabled || rubrique.existing
      }">

        <div class="rubrique-select-top" [ngClass]="{'warn': !!rubrique.errorMessage}">

          <mat-checkbox [ngModel]="rubrique.selected || rubrique.existing" class="rubrique-checkbox"
            [disabled]="rubrique.disabled || rubrique.existing" (change)="toggleRubrique(rubrique)">
            <div class="rubrique-checkbox-label">
              <i class="icon icodomino-rubrique-planning"
                [ngStyle]="{color: (rubrique.color|customColor) || rubrique.colorDomino}"></i>
              <div class="rubrique-info" [ngClass]="{'hours': rubrique.displayTimes}">
                <span class="rubrique-name">{{ rubrique.label || rubrique.name }}</span>

                <div *ngIf="rubrique.displayTimes" class="rubrique-hours">
                  <hr>
                  <span>{{ rubrique.horaires[0].start }} - {{ rubrique.horaires[0].end }}</span>
                  <ng-container *ngIf="rubrique.horaires[1]">
                    <span>&nbsp; & &nbsp;</span>
                    <span>{{ rubrique.horaires[1].start }} - {{ rubrique.horaires[1].end }}</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </mat-checkbox>

          <mat-icon *ngIf="rubrique.otherAccountConflict" color="primary-darker"
            [matTooltip]="'Conflit avec présence d\'un autre compte'">
            lock
          </mat-icon>

          <mat-icon *ngIf="rubrique.otherConsumers" color="accent"
            [matTooltip]="'Autres enfants inscrits : ' + rubrique.otherConsumers">people</mat-icon>

          <mat-icon *ngIf="rubrique.errorMessage" [matTooltip]="rubrique.errorMessage|trans"
            matTooltipClass="tooltip-pre-line tooltip-large">
            highlight_off
          </mat-icon>

          <ng-container *ngIf="liveCapacity">
            <mat-icon
              *ngIf="periode.warnLowCapacity && (rubrique.rooms > 0 && rubrique.rooms <= periode.warnLowCapacity)"
              [matTooltip]="'reservation.planning.capacity_warn'|trans:{places: rubrique.rooms}" color="accent">
              offline_bolt
            </mat-icon>

            <ng-container *ngIf="rubrique.rooms < 1 || rubrique.attente">
              <mat-icon *ngIf="!!periode.gestionListeAttente" color="accent"
                [matTooltip]="'reservation.planning.capacity_attente'|trans">
                format_list_numbered
              </mat-icon>
              <mat-icon *ngIf="!periode.gestionListeAttente" [matTooltip]="'reservation.planning.capacity_full'|trans"
                color="warn">
                highlight_off
              </mat-icon>
            </ng-container>
          </ng-container>
        </div>

        <mat-card *ngIf="rubrique.activities && rubrique.activities.length"
          class="mat-elevation-z4 accent-lighter-bg linked-activities">
          <h4>
            <i class="icodomino-activite accent-darker" style="margin-right: 5px; font-size: 24px;"></i>
            <span>Activités :</span>
          </h4>

          <span *ngFor="let act of rubrique.activities" class="rubrique-activity-item">
            <mat-checkbox [checked]="(act.selected || act.existing) && act.selectedRubrique === rubrique.id"
              [disabled]="act.disabled || rubrique.disabled || rubrique.existing"
              (change)="toggleActivity(act, rubrique.id)">
            </mat-checkbox>
            <a href="#" (click)="accessActivity(act, $event)">
              {{ act.label || act.name }}
              <b *ngIf="act.auto">(Obligatoire)</b>
              <span *ngIf="act.grouped?.length">({{ act.grouped.length }} dates)</span>
            </a>
          </span>
        </mat-card>

        <!-- Message about recurrency behaviour, if any -->
        <mat-card *ngIf="rubrique.recurrencyMessage" class="primary-bg"
          [hidden]="!(rubrique.selected || rubrique.existing)">
          <span fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="accent" style="margin-right: 5px;">notification_important</mat-icon>
            <span>Une présence sera ajoutée automatiquement sur
              <span [innerHTML]="rubrique.recurrencyMessage|safe:'html'"></span>.
            </span>
          </span>
        </mat-card>

        <!-- User custom hours (maybe not the most handy to have in a big Card inside the Checkbox list) -->
        <!-- @TODO: study the possibility to display an icon "customizable hours", open a Dialog for Custom Hours,
            then simple display these hours inline (with a particular style to say "hours customized")
          Pros : more readability, no need for control here .. -->
        <mat-card *ngIf="rubrique.displayTimes && (rubrique.customStartTime || rubrique.customEndTime)"
          [hidden]="!rubrique.selected">
          <span>
            Pour la rubrique sélectionnée, <b>veuillez saisir vos horaires :</b>
          </span>

          <!-- According to specs, middle hours cannot be custom (most of time will be like 12h - 14h) -->
          <div *ngFor="let hour of rubrique.horaires; let first = first; let last = last" class="custom-time-row">
            <span *ngIf="first && rubrique.userHours?.start; else fixedStart" class="time-input-field">
              <label>De</label>
              <app-time-input [(ngModel)]="rubrique.userHours.start" [min]="hour.start" [max]="hour.end" [picker]="true"
                (ngModelChange)="onChangeCustomTime(rubrique)">
              </app-time-input>
              <span *ngIf="rubrique.userHours.startError as error">{{ error }}</span>
            </span>

            <span *ngIf="last && rubrique.userHours?.end; else fixedEnd" class="time-input-field">
              <label>À</label>
              <app-time-input [(ngModel)]="rubrique.userHours.end" [min]="hour.start" [max]="hour.end" [picker]="true"
                (ngModelChange)="onChangeCustomTime(rubrique)">
              </app-time-input>
              <span *ngIf="rubrique.userHours.endError as error">{{ error }}</span>
            </span>

            <span *ngIf="first && rubrique.userHours?.startError as error" class="time-error warn">{{ error }}</span>
            <span *ngIf="last && rubrique.userHours?.endError as error" class="time-error warn">{{ error }}</span>

            <ng-template #fixedStart>
              <span class="fixed-time">De <span>{{ hour.start }}</span></span>
            </ng-template>

            <ng-template #fixedEnd>
              <span class="fixed-time">À <span>{{ hour.end }}</span></span>
            </ng-template>
          </div>

        </mat-card>

        <mat-divider></mat-divider>

      </div>
    </div>
  </div>
</ng-template>

<ng-template #activitiesList>
  <div #activitiesContainer class="thin-scrollbar">
    <mat-card *ngIf="hasError" class="warn-bg error-message">
      Une erreur est survenue : certains éléments ne peuvent être réservés.
    </mat-card>

    <mat-card *ngFor="let act of activities" class="activity-card mat-elevation-z4" [attr.data-acty-id]="act.id">
      <mat-card-header>
        <mat-card-title>
          <span>{{ act.label || act.name }}</span>
        </mat-card-title>

        <mat-card-subtitle [class.inline]="!act.datetimeDetails">
          <!-- Rubriques subtext (this first template call already adds a <div>) -->
          <ng-container *ngIf="act.rubriques?.length === 1 && act.rubriques[0].enabled"
            [ngTemplateOutlet]="activityRubriqueLabel" [ngTemplateOutletContext]="{rubrique: act.rubriques[0]}">
          </ng-container>

          <!-- Multiple rubriques available -->
          <div *ngIf="act.rubriques?.length > 1" class="activity-rubrique-label" [matTooltip]="act.multiRubriquesText"
            matTooltipClass="rubriques-tooltip">
            <i class="icon icodomino-rubrique-planning"></i>
            <span>Plusieurs rubriques disponibles</span>
          </div>

          <!-- Hours subtext (session datetimeDetails version) -->
          <div *ngIf="act.datetimeDetails" [innerHTML]="act.datetimeDetails|safe" class="session-datetimes"></div>

          <!-- Single hours version -->
          <div *ngIf="!act.datetimeDetails && !act.group && act.startTime">
            <span *ngIf="act.rubriques?.length">&nbsp;-&nbsp;</span>{{ act.startTime }} à {{ act.endTime }}
          </div>
        </mat-card-subtitle>

        <mat-icon [hidden]="!(act.selected || act.existing)" class="selected-icon"
          [ngClass]="{'disabled': act.existing}" [matTooltip]="act.selected ? 'Sélectionné' : 'Réservé'">
          done_outline
        </mat-icon>

      </mat-card-header>

      <div mat-card-content class="thin-scrollbar">
        <app-activity-preview [activity]="act"></app-activity-preview>
      </div>

      <mat-card-actions align="center">
        <ng-container *ngTemplateOutlet="activitySelector; context: {activity: act}"></ng-container>

        <span *ngIf="act.disabled" class="action-hint">
          {{ ('reservation.error_type.' + act.disabled)|trans }}
        </span>

        <ng-container *ngIf="!act.disabled && liveCapacity">

          <span *ngIf="periode.gestionListeAttente && act.dispos < 1" class="action-hint">
            <mat-icon color="accent">format_list_numbered</mat-icon>
            {{ 'reservation.planning.capacity_attente'|trans }}
          </span>

          <span *ngIf="periode.warnLowCapacity &&
            (act.dispos > 0 && act.dispos <= periode.warnLowCapacity)" class="action-hint">
            <mat-icon color="accent">offline_bolt</mat-icon>
            {{ 'reservation.planning.capacity_warn'|trans:{places: act.dispos} }}
          </span>

        </ng-container>

      </mat-card-actions>
    </mat-card>
  </div>
</ng-template>

<!-- @NB: buttons for time pickers -->
<ng-template #cancelBtn>
  <button mat-raised-button style="margin-right: 10px;" color="warn" type="button">Annuler</button>
</ng-template>

<ng-template #confirmBtn>
  <button mat-raised-button style="margin-right: 10px;" color="primary-lighter" type="button">Valider</button>
</ng-template>

<ng-template #activitySelector let-act="activity">
  <ng-container *ngIf="!(act.selected || act.existing) && act.rubriques?.length > 1; else actSingleRub">
    <mat-menu #actRubSelector class="activity-rubrique-selector">
      <button *ngFor="let rub of act.rubriques" mat-menu-item (click)="toggleActivity(act, rub.id)"
        [disabled]="act.disabled || rub.disabled || rub.existing">
        <ng-container *ngTemplateOutlet="activityRubriqueLabel; context: {rubrique: rub}"></ng-container>
      </button>
    </mat-menu>

    <button mat-raised-button [color]="act.selected || act.existing ? 'warn' : 'accent'" class="subscribe-button"
      [disabled]="act.disabled || act.existing" [matMenuTriggerFor]="actRubSelector">
      {{ act.selected || act.existing ? 'Annuler' : 'Sélectionner' }}
      <mat-icon *ngIf="act.auto" [matTooltip]="act.autoMessage" matTooltipPosition="right">link</mat-icon>
      <app-icon *ngIf="act.groupMessage" type="fa" icon="campground" [matTooltip]="act.groupMessage"
        matTooltipClass="tooltip-pre-line" matTooltipPosition="right"></app-icon>
      <mat-icon style="margin-right: -5px;">chevron_right</mat-icon>
    </button>
  </ng-container>

  <ng-template #actSingleRub>
    <button mat-raised-button [color]="act.selected || act.existing ? 'warn' : 'accent'" class="subscribe-button"
      [disabled]="act.disabled || act.existing" (click)="toggleActivity(act)">
      {{ act.selected || act.existing ? 'Annuler' : 'Sélectionner' }}
      <mat-icon *ngIf="act.auto" [matTooltip]="act.autoMessage" matTooltipPosition="right">link</mat-icon>
      <app-icon *ngIf="act.groupMessage" type="fa" icon="campground" [matTooltip]="act.groupMessage"
        matTooltipClass="tooltip-pre-line" matTooltipPosition="right"></app-icon>
    </button>
  </ng-template>
</ng-template>

<ng-template #activityRubriqueLabel let-rubrique="rubrique">
  <div class="activity-rubrique-label">
    <i class="icon icodomino-rubrique-planning" [ngStyle]="{'color': rubrique.color || rubrique.colorDomino }"></i>
    <span>{{ rubrique.label || rubrique.name }}</span>
  </div>
</ng-template>
