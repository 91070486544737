import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PlatformService, UserService, SnackbarService, AuthenticationService, LoginContext } from '@app/services';
import { Router, ActivatedRoute } from '@angular/router';
import { NewPasswordComponent } from '@app/components/_elements/new-password/new-password.component';


@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  form = this.formBuilder.group({
    oldPassword: ['', [Validators.required]]
  });

  isLoading = false;
  response = '';
  token: string;
  userLogin: string;
  title = 'Modification de votre mot de passe';

  showPass = false;

  @ViewChild(NewPasswordComponent) newPasswordComponent: NewPasswordComponent;

  constructor(
    public platformService: PlatformService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
  ) {
    this.token = this.route.snapshot.paramMap.get('token');
    this.userLogin = this.route.snapshot.paramMap.get('userLogin');
    const title = this.route.snapshot.paramMap.get('title');
    this.title = title ? title : this.title;
  }

  ngOnInit() {
    if (this.token) {
      this.form.removeControl('oldPassword');
    } // else should check we have a login ...
  }

  ngAfterViewInit() {
    const newPassForm = this.newPasswordComponent.passForm;

    if (newPassForm) {
      this.form.addControl('new', newPassForm);
    }
  }

  onSubmit() {
    if (!this.form.valid) {
      this.snackbarService.error('Formulaire invalide');
      return;
    }

    this.isLoading = true;

    const data: any = { password: this.form.value.new.password }; // do we need to send confirm too ?

    if (this.token) {
      data.token = this.token;
    } else {
      data.oldPassword = this.form.value.oldPassword;
    }

    this.userService.updatePassword(data).subscribe((result: any) => {
      if (result && result.error) {
        this.snackbarService.error(result.error);
        this.form.get('oldPassword').setErrors(result.error);
        this.isLoading = false;
      } else {
        this.snackbarService.info(result.message, 5000);

        if (this.authService.currentUserValue) {
          // setTimeout( () => {this.router.navigate(['/account']); }, 200);
          history.back();
        } else if (this.userLogin && data.password) {
          const loginData: LoginContext = { username: this.userLogin, password: data.password };
          this.authService.login(loginData)
            .subscribe(() => {
              setTimeout(() => { this.router.navigate(['/account']); }, 200);
            }, error => {
              console.error(error);
              setTimeout(() => { this.router.navigate(['/home']); }, 200);
            });
        } else {
          // history.back();
          setTimeout(() => { this.router.navigate(['/login']); }, 200);
          // setTimeout( () => {this.router.navigate(['/home']); }, 200);
        }

      }
    }, error => {
      this.snackbarService.error(error?.error || error);
      this.isLoading = false;
    });
  }
}
