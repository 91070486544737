<div class="conteneur" *ngIf="preinscriptions; else loader">
  <div class="preinscription-list">

    <mat-card class="preinscription-card" *ngFor="let preinscription of preinscriptions">
      <mat-card-header class="card-header" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title fxLayout="row" fxLayoutAlign="start center">
          <mat-icon *ngIf="preinscription.idAdulte" style="margin-right: 5px;">person</mat-icon>
          <app-icon *ngIf="preinscription.idEnfant" style="margin-right: 5px;" type="do" icon="enfant"></app-icon>
          <span>{{ preinscription.nom }} {{ preinscription.prenom }}</span>
        </mat-card-title>
        <button *ngIf="permToEdit" mat-mini-fab color="primary" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-card-header>

      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item (click)="onClickEdit(preinscription)">
          <mat-icon color="primary">edit
          </mat-icon>
          {{ 'action.see_edit'|trans }}
        </button>
        <!-- <button mat-menu-item color="primary">
          <mat-icon color="warn">clear</mat-icon>
          Supprimer
        </button> -->
      </mat-menu>

      <mat-card-content>
        <div>
          <label class="label">{{ 'lasido.annee_scolaire'|trans }} : </label>
          <span>{{ preinscription.anneeScolaireName }}</span>
        </div>

        <div *ngIf="preinscription.accueilName">
          <label class="label">Etablissement - Accueil :</label>
          <span *ngIf="preinscription.etablissementName">{{ preinscription.etablissementName }} ></span>
          {{ preinscription.accueilName }}
        </div>

        <div>
          <label class="label">{{ 'lasido.date_demande'|trans }} : </label>
          <span>{{ preinscription.dateDemande ? (preinscription.dateDemande|date) : '' }}</span>
        </div>

        <div class="status">
          <label class="label">{{ 'lasido.status'|trans }} : </label>
          <span>
            {{ preinscription.decision }}

            <app-icon *ngIf="preinscription.decision === 'Refusée'" type="fa" icon="times-circle"
              class="icon-decision warn"></app-icon>
            <app-icon *ngIf="preinscription.decision === 'Acceptée'" type="fa" icon="check-circle"
              class="icon-decision primary"></app-icon>
            <app-icon *ngIf="preinscription.decision === 'Abandon'" type="fa" icon="trash" class="icon-decision">
            </app-icon>
            <app-icon *ngIf="preinscription.decision === 'En attente'" type="fa" icon="question-circle"
              class="icon-decision accent"></app-icon>
          </span>
        </div>

        <hr>

        <div class="choices-list-container">
          <label class="label">{{ 'lasido.souhaits'|trans }} : </label>

          <!-- <div *ngFor="let cours of choice.cours" style="margin-left: 35px;">
            <div style="margin-top: 10px;">
              <label>{{cours.choice ? cours.ordre + ' -' : '-'}} {{cours.libelle}}</label>
            </div>
            <div [ngStyle]="{'margin-left': cours.choice ? '30px' : '20px'}" class="details-cours">
              <label>Horaire : </label>
              <span *ngIf="!cours.provisoire; else coursProvisoire">Le {{cours.jour}}
                de
                {{cours.heureDebut}} à
                {{cours.heureFin}}</span>
            </div>
            <ng-template #coursProvisoire>
              <span>{{'lasido.text-cours-provisoire' | trans}}</span>
            </ng-template>

            <div [ngStyle]="{'margin-left': cours.choice ? '30px' : '20px'}" class="details-cours">
              <label>Enseignant(s) : </label>
              <span *ngFor="let enseignant of cours.enseignants; let last = last" style="margin-right: 5px;">
                {{enseignant.nomPrenom}}{{!last ? ' - ' : ''}}
              </span>
            </div>
            <div [ngStyle]="{'margin-left': cours.choice ? '30px' : '20px'}" class="details-cours">
              <label>Type : </label>
              <span>{{cours.individuel ? 'Individuel' : 'Collectif'}}</span>
            </div>
          </div> -->

          <ul *ngIf="preinscription.choiceGroups?.length" class="list-souhaits">
            <li *ngFor="let choice of preinscription.choiceGroups">
              <span class="choice-title" [style.color]="choice.color">
                <span *ngIf="choice.departmentName">
                  {{ choice.departmentName }}<span class="separator">-</span>
                </span>
                <span>{{ choice.disciplineName }}<span class="separator">-</span></span>
                <span>{{ choice.niveauName }}</span>
              </span>

              <ol *ngIf="choice.cours?.length" class="courses-list">
                <li *ngFor="let cours of choice.cours; let i = index">
                  <span class="course-name">
                    {{ cours.name }}
                    <mat-icon *ngIf="cours.id === choice.coursRetenu?.id" color="accent" matTooltip="Cours retenu">
                      check_circle
                    </mat-icon>
                  </span>
                  <div [ngStyle]="{'margin-left': cours.choice ? '30px' : '20px'}" class="details-cours">
                    <label>Horaire : </label>
                    <span *ngIf="!cours.provisoire; else coursProvisoire">Le {{cours.jour}}
                      de
                      {{cours.heureDebut}} à
                      {{cours.heureFin}}</span>
                  </div>
                  <ng-template #coursProvisoire>
                    <span>{{'lasido.text-cours-provisoire' | trans}}</span>
                  </ng-template>

                  <div [ngStyle]="{'margin-left': cours.choice ? '30px' : '20px'}" class="details-cours">
                    <label>Enseignant(s) : </label>
                    <span *ngFor="let enseignant of cours.enseignants; let last = last" style="margin-right: 5px;">
                      {{enseignant.nomPrenom}}{{!last ? ' - ' : ''}}
                    </span>
                  </div>
                  <div [ngStyle]="{'margin-left': cours.choice ? '30px' : '20px'}" class="details-cours">
                    <label>Type : </label>
                    <span>{{cours.individuel ? 'Individuel' : 'Collectif'}}</span>
                  </div>
                </li>
              </ol>
            </li>
          </ul>
        </div>

        <!-- <app-icon *ngIf="preinsc.decision ==='Refusée'" type="fa" icon="times-circle" class="icon-decision warn">
          </app-icon>
          <app-icon *ngIf="preinsc.decision ==='Acceptée'" type="fa" icon="check-circle" class="icon-decision primary">
          </app-icon>
          <app-icon *ngIf="preinsc.decision ==='Abandon'" type="fa" icon="trash" class="icon-decision"></app-icon>
          <app-icon *ngIf="preinsc.decision ==='En attente'" type="fa" icon="question-circle"
            class="icon-decision accent"></app-icon>
          <app-icon type="fa" icon="question-circle" class="icon-decision accent">
          </app-icon>-->
      </mat-card-content>
    </mat-card>
  </div>

  <mat-card *ngIf="permToCreate" class="actions-card card-add">
    <mat-card-header class="card-header" fxLayout="row" fxLayoutAlign="center center">
      <mat-card-title fxLayout="row" fxLayoutAlign="center center">
        {{ 'lasido.new_preinscription'|trans }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content style="margin-top: 20px;" fxLayout="row" fxLayoutAlign="center center">
      <button mat-fab color="primary" matTooltip="{{ 'lasido.new_preinscription'|trans }}"
        routerLink="/account/lasido-preinscriptions/new">
        <mat-icon>add</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <div *ngIf="!preinscriptions.length && !permToCreate">
    <h2 style="text-align: center;">Vous n'avez pas de pré-inscription(s).</h2>
  </div>

</div>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>
