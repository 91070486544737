import { Component, OnInit, ViewChild } from '@angular/core';
import { FormConfigService, ChildService, PermissionService, FamilyService, FormHelperService } from '@app/services';
import { ExtendedFormlyFieldConfig, StepType } from '@app/forms/config/form-model';
import { FormViewerComponent, SubmitFormEvent } from '@app/components/_public/form-viewer/form-viewer.component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { distinctUntilChanged, filter, startWith, switchMap, take } from 'rxjs/operators';
import { FormArray, FormGroup } from '@angular/forms';
import { FormType } from '@app/models/global-config';

@Component({
  selector: 'app-user-child-edit',
  templateUrl: './child-edit.component.html',
  styleUrls: ['./child-edit.component.scss']
})
export class ChildEditComponent implements OnInit {

  id: number;
  step: string;
  readOnly: boolean;

  form: StepType[];
  formData: any;
  formTitle: string;

  isLoading = true;
  loadingMessage = 'Chargement';
  typeForm: FormType = 'form-enfant';

  @ViewChild(FormViewerComponent) formViewer: FormViewerComponent;

  constructor(
    private formConfigService: FormConfigService,
    private helperService: FormHelperService,
    private familyService: FamilyService,
    private childService: ChildService,
    private route: ActivatedRoute,
    private location: Location,
    private permService: PermissionService
  ) { }

  ngOnInit() {
    this.id = +this.route.snapshot.paramMap.get('id') !== 0 ? +this.route.snapshot.paramMap.get('id') : null;
    this.step = this.route.snapshot.paramMap.get('step');
    this.readOnly = !this.permService.hasPermission('child_edit') || !this.familyService.currentFamily.active;

    if (this.id) {
      // Here we should probably trigger an error if the child is not found ?
      this.loadChild(this.id).subscribe(child => child ? this.formTitle = child.prenom + ' ' + child.nom : null);

    } else {
      this.formTitle = 'Nouvel enfant';
    }

    this.childService.getFormData(this.id, this.step).subscribe((form: any) => {

      this.form = this.formConfigService.getFormView(form.config).filter(ff => ff.enabled);
      this.formData = !form.data ? { modeCreation: true } : form.data;

      this.adjustPersonneAutoriseeBlocks();

      if (this.form[0].stepName === 'complement') {
        this.checkAutorisationButtonValue();
      }

      this.isLoading = false;
    })
  }

  loadChild(id: number) {
    return this.familyService.familySwitch$.pipe(
      filter(f => !!f),
      take(1),
      switchMap(f => this.childService.getFamilyChild(f.id, id))
    );
  }

  adjustPersonneAutoriseeBlocks() {
    const step = this.formConfigService.findStep(this.form, 'complement');

    if (!step) {
      return;
    }

    ['Bloc_chercher_enfant', 'Bloc_contact_urgence'].forEach(name => {
      const block = this.formConfigService.findFieldByName(step.fields, name);

      if (block) {
        this.adjustPersonneAutoriseeBlock(block);
      }
    });
  }

  adjustPersonneAutoriseeBlock(blockField: ExtendedFormlyFieldConfig) {
    if (!blockField.hooks) {
      blockField.hooks = {};
    }

    blockField.hooks.onInit = field => {
      const formArray = field.formControl as FormArray;

      if (formArray) {
        formArray.valueChanges.pipe(
          distinctUntilChanged((x: any[], y: any[]) => x.length === y.length),
          startWith(true)
        ).subscribe(x => {
          // Manually lock fields for adulte contacts, because Formly is bad :/
          formArray.controls.forEach((item: FormGroup, i) => {
            const modelValue = field.model[i];

            if (modelValue?.isAdulte) {
              Object.keys(item.controls).forEach(key => {
                if (!['lienFamille', 'commentaire'].includes(key)) {
                  item.controls[key].disable({ emitEvent: false });
                }
              });
            }
          });
        });
      }
    };
  }

  // Si la valeur sélection par l'utilisateur n'existe plus dans le formulaire alors elle est reinitialisée.
  // Cela se produit quand l'utilisateur sélectionne une option et qu'elle est ensuite supprimée côté admin.
  checkAutorisationButtonValue() {
    let listAutorisationForm = this.getAutorisationsFormList();
    if (listAutorisationForm) {
      listAutorisationForm.forEach(auth => {
        this.formData.Bloc_autorisations.forEach(authData => {
          if (auth.key === authData.key) {
            let tabOption = [];
            if (auth.templateOptions && auth.templateOptions.options) {
              for (const option of auth.templateOptions.options) {
                tabOption.push(option.value)
              }
            }
            if (!tabOption.includes(authData.valeur) && authData.valeur !== "") {
              authData.valeur = "";
            }
          }
        })
      })
    }
  }

  getAutorisationsFormList() {
    let listAutorisation;

    if (this.form[0].stepName === 'complement') {
      this.form[0].fields.forEach(field => {
        if (field.key === 'Bloc_autorisations') {
          listAutorisation = field.defaultValue;
        }
      })
      return listAutorisation;
    }
  }

  onSave(event: SubmitFormEvent) {
    const data = event.model;
    this.formViewer.setErrorMessage('');
    this.loadingMessage = 'Enregistrement';

    if (this.readOnly) {
      return;
    }

    this.isLoading = true;

    const saveMethod = (this.id) ? this.childService.update(data, this.id, this.step) : this.childService.create(data);

    saveMethod.subscribe((result: any) => {
      this.helperService.displayDebugTraces(result.traces);
      this.helperService.notifySuccess('Modification effectuée', result.messages);

      this.location.back();
    }, err => {
      this.isLoading = false;

      setTimeout(() => { // doit être encadré dans un setTimeout car sinon le 'formViewer' n'est pas accessible (car il était masqué par 'isLoading')...
        this.helperService.manageServerError(err, this.formViewer);
      })

    });

  }

}
