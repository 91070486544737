import { formatNumber } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PlatformService } from '@app/services';
import { isTime, parseTime, formatTime } from '@app/utils/date-functions';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: TimeInputComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: TimeInputComponent, multi: true }
  ]
})
export class TimeInputComponent implements OnInit, ControlValueAccessor {

  value: string;
  hour: number;
  minute: number;

  @Input() label: string; // optional

  @Input() min = '00:00';
  @Input() max = '23:59';

  @Input() default = '00:00';

  minHour: number;
  maxHour: number;

  minMinute = 0;
  maxMinute = 59;

  availableHours: number[] = [];
  availableMinutes: number[] = [];

  @Input() minuteGap: number;

  // FormControl / ngModel mechanism stuff

  onChange: (_: any) => any;
  onTouch: () => any;
  @Input() disabled: boolean;
  @Input() required: boolean;

  // [OPTIONAL] Works only if we use formControl directive, not ngModel ...
  @Input() formControl: FormControl;

  // Small tip to display a bit less visible when disabled
  @HostBinding('style.opacity')
  get opacity() {
    return this.disabled ? 0.7 : 1;
  }

  // Timepicker options

  @Input() picker: boolean;

  constructor(
    public platformService: PlatformService
  ) { }

  ngOnInit(): void {

    this.minuteGap = this.minuteGap || 1;

    this.minHour = +this.min.substring(0, 2);
    this.maxHour = +this.max.substring(0, 2);

    this.computeAvailable();
  }

  computeAvailable() {
    for (let i = this.minHour; i <= this.maxHour; i++) {
      this.availableHours.push(i);
    }

    for (let i = this.minMinute; i <= this.maxMinute; i += this.minuteGap) {
      this.availableMinutes.push(i);
    }
  }

  triggerChange() {
    this.value = formatTime({ hour: this.hour, minute: this.minute });

    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  onKeypress(e: KeyboardEvent) {
    const target = e.target as HTMLInputElement;
    const isMinute = target.classList.contains('minutes');
    const value = target.value;
    const numberPressed = +e.key;

    if (isNaN(numberPressed)) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const newValue = this.parseValue(+value, +e.key, isMinute ? this.availableMinutes : this.availableHours);

      isMinute ? this.minute = newValue : this.hour = newValue;

      this.triggerChange();
    }
  }

  parseValue(value: number, newValue: number, availables: number[]) {
    const concat = +((value + '' + newValue).substr(-2));

    if (isNaN(concat)) {
      return 0;
    } else if (availables.includes(concat)) {
      return concat;
    } else {
      return newValue;
    }
  }

  onKeydown(e: KeyboardEvent) {
    const target = e.target as HTMLInputElement;
    const isMinute = target.classList.contains('minutes');
    const value = +target.value;

    if (e.key === 'ArrowUp') {
      isMinute ? this.increaseMinute(value) : this.increaseHour(value);
      this.triggerChange();
    } else if (e.key === 'ArrowDown') {
      isMinute ? this.decreaseMinute(value) : this.decreaseHour(value);
      this.triggerChange();
    }
  }

  increaseMinute(value: number) {
    this.minute = this.findNextAvailable(this.availableMinutes, value);
  }

  increaseHour(value: number) {
    this.hour = this.findNextAvailable(this.availableHours, value);
  }

  decreaseMinute(value: number) {
    this.minute = this.findPreviousAvailable(this.availableMinutes, value);
  }

  decreaseHour(value: number) {
    this.hour = this.findPreviousAvailable(this.availableHours, value);
  }

  findNextAvailable(values: number[], value: number) {
    const next = values.find(n => n > value);
    return next !== null && next !== undefined ? next : values[0];
  }

  findPreviousAvailable(values: number[], value: number) {
    const prev = values.filter(p => p < value).pop();
    return prev !== null && prev !== undefined ? prev : values[values.length - 1];
  }

  onBlur(event: FocusEvent) {
    this.onTouch();

    const target = event.target as HTMLInputElement;

    if (target.value.length < 2) {
      const value = parseInt(target.value, 10);
      target.value = formatNumber(isNaN(value) ? 0 : value, 'FR-fr', '2.0-0');
    }
  }

  writeValue(obj: any): void {

    if (!obj && this.default) {
      obj = this.default;
    }

    if (typeof obj === 'string') {
      obj = obj.substring(0, 5); // we don't handle seconds for now, so only keep "xx:xx" (plus TimePicker plugin prefers it)
    }

    if (isTime(obj)) {
      this.value = obj;
      const parsed = parseTime(obj);
      this.minute = parsed.minute;
      this.hour = parsed.hour;
    } else {
      // probably throw error ?
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: FormControl) {
    // appelé automatiquement grace au provider "NG_VALIDATORS", en l'occurence on laisse les validateurs s'éxécuter, rien à rajouter ...
  }
}
