import { Component, OnInit, Input } from '@angular/core';
import { ActivityWithDay, PlanningService } from '@app/services/planning.service';
import { MatDialog } from '@angular/material/dialog';
import { HelpDialogComponent } from '@app/components/_common/help-dialog/help-dialog.component';
import { PlatformService } from '@app/services';
import moment from 'moment';

@Component({
  selector: 'app-activity-preview',
  templateUrl: './activity-preview.component.html',
  styleUrls: ['./activity-preview.component.scss']
})
export class ActivityPreviewComponent implements OnInit {

  @Input() activity: ActivityWithDay;

  preview: { image: string, text: string };

  constructor(
    private dialog: MatDialog,
    private planningService: PlanningService,
    private platformService: PlatformService
  ) { }

  ngOnInit() {
    this.preview = this.planningService.getActivityPreview(this.activity);
  }

  openDetails() {
    // In case we receive an Activity without Date
    const date = this.activity.date;

    const dialog = this.dialog.open(HelpDialogComponent, {
      maxWidth: 1000,
      data: {
        title: (this.activity.label || this.activity.name) + (date ? ', le ' + moment(date).format('DD/MM/Y') : ''),
        message: this.activity.description
      }
    });

    this.platformService.adaptDialogToScreen(dialog);
  }
}
