<div *ngIf="!loading && planningData; else loader" #calendarWrapper class="calendar-view-wrapper"
  [ngClass]="{'small-screen': isSmallScreen}">

  <ng-container *ngIf="!saving; else saveLoader">
    <div fxLayout="row" fxLayoutAlign="end end"
      style="padding: 3px;margin-right: 6px; position: relative; top: 8px; z-index: 2;">
      <span *ngIf="planningCurrentChanges.length && !saving" [matBadge]="planningCurrentChanges.length"
        matBadgeColor="accent"></span>
    </div>
    <full-calendar #calendar *ngIf="!loading; else loader" [options]="options"></full-calendar>
  </ng-container>

</div>

<ng-template #saveLoader>
  <app-loader class="margin">Enregistrement en cours</app-loader>
</ng-template>

<ng-template #loader>
  <app-loader class="margin">{{ 'reservation.loader.planning'|trans }}</app-loader>
</ng-template>
