import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Facture, Reglement, FacturationConfig, RegieConfig, FactureLine, RegieData } from '@app/models/facturation';
import { DATE_FORMAT } from './planning.service';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { PayRequestParams } from '@app/components/_user/facture/paiement/paiement.component';

export interface FactureLineGroup {
  details: string;
  referent: string;
  lines: FactureLine[];
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class FacturationService {

  private configUrl = 'conf/facturation';
  private factureDetailsUrl = 'factures/#id#/details';
  private onlinePayUrl = 'payment/pay';

  constructor(
    private http: HttpClient
  ) { }

  getConfig(): Observable<FacturationConfig> {
    return this.http.get<FacturationConfig>(this.configUrl);
  }

  saveConfig(config: any) {
    return this.http.put(this.configUrl, { config }, httpOptions);
  }

  getRegieConfig(id: number): Observable<RegieConfig> {
    return this.http.get<RegieConfig>(`${this.configUrl}/regie/${id}`);
  }

  getFamilyFactures(id: number) {
    return this.http.get<Facture[]>(`familles/${id}/factures`);
  }

  getFamilyRegieFactures(idFamily: number, idRegie: number) {
    return this.http.get<Facture[]>(`familles/${idFamily}/factures/${idRegie}`);
  }

  getFamilyFacturationData(id: number) {
    return this.http.get<RegieData[]>(`familles/${id}/facturation`);
  }

  getPayableFactures(idFamily: number, idRegie: number) {
    return this.getFamilyRegieFactures(idFamily, idRegie).pipe(
      map(data => data.filter(facture => facture.status === 'EnAttente'))
    );
  }

  getFactureDetails(idFacture) {
    return this.http.get<Facture>(this.factureDetailsUrl.replace('#id#', idFacture));
  }

  getIndexedLines(factureLines: FactureLine[]): FactureLineGroup[] {
    const indexed = {};

    factureLines.forEach(line => {
      const details = this.buildLineTitle(line);
      const key = (details + '-' + line.referent).replace(/\s+/g, '');

      if (!indexed[key]) {
        indexed[key] = { details, referent: line.referent, lines: [] };
      }

      indexed[key].lines.push(line);
    });

    return Object.values(indexed);
  }

  buildLineTitle(factureLine: FactureLine) {
    return [
      factureLine.accueilData?.nomEtablissement,
      factureLine.accueilData?.nomAccueil,
      factureLine.nomPeriode
    ].filter(x => !!x).join(' / ');
  }

  getPdf(idFacture: number) {
    return this.http.get(`factures/${idFacture}/pdf`, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events'
    }).pipe(map(response => this.mapProgressResponse(response)));
  }

  mapProgressResponse(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.DownloadProgress:
        return { type: 'dl_progress', value: Math.round(100 * event.loaded / event.total) };
      case HttpEventType.UploadProgress:
        return { type: 'up_progress', value: Math.round(100 * event.loaded / event.total) };
      case HttpEventType.Response:
        return { type: 'response', value: event.body };
      default:
        return { type: 'otherType', value: event.type };
    }
  }

  getPayBackUrl(type?: string, moduleRedirect?: string) {
    return window.location.origin + '/paiement/retour/' + type + (moduleRedirect ? '/' + moduleRedirect : '');
  }

  sendPaymentBack(type, params) {
    return this.http.post('payment/return/' + type, params);
  }

  getPaylineStatus(regie: string, token: string) {
    return this.http.get<any>('payment/status', { params: { regie, token } });
  }

  sendPayRequest(params: PayRequestParams, payPlatform: string, module = null) {
    params = { ...params, backUrl: this.getPayBackUrl(payPlatform, module) };
    return this.http.post<any>(this.onlinePayUrl, { ...params, backUrl: this.getPayBackUrl(payPlatform, module) });
  }

  sendPayTestRequest(regie: RegieConfig, testMode: string = 'test') {
    const params = { mode: testMode, config: regie, backUrl: this.getPayBackUrl(regie.typePaiement) };

    return this.http.post<any>('payment/test', params);
  }

  getRegieTestReglements(regie: number) {
    return this.http.get<Reglement[]>(`facturation/${regie}/test-reglements`);
  }

  buildPaymentForm(url: string, params: string[], newTab = false) {
    const form = document.createElement('form');
    form.setAttribute('action', url);
    form.setAttribute('method', 'POST');
    form.style.display = 'none';

    if (newTab) {
      form.setAttribute('target', '_blank');
    }

    for (const key of Object.keys(params)) {
      const input = document.createElement('input');

      input.setAttribute('type', 'hidden');
      input.setAttribute('name', key);
      input.setAttribute('value', params[key]);

      form.appendChild(input);
    }

    return form;
  }

  getFilteredReglements(filters: any) {
    return this.http.get<Reglement[]>('reglements', { params: this.getFormattedFilters(filters) });
  }

  getReglement(id: number) {
    return this.http.get<Reglement>(`reglement/${id}`);
  }

  getFormattedFilters(data: any) {
    const formatted = {};

    Object.keys(data).forEach(key => {
      const val = data[key];

      if (Array.isArray(val) && !!val.length) {
        formatted[key] = val.join(',');
      } else if (moment.isMoment(val)) {
        formatted[key] = val.format(DATE_FORMAT);
      } else if (val !== undefined && val !== null) {
        formatted[key] = val;
      }
    });

    return formatted;
  }

  validatePayment(reglement: Reglement) {
    return this.http.put('payment/validate', { reglement: reglement.id });
  }

  cancelPayment(reglement: Reglement, motif?: string) {
    return this.http.put('payment/cancel', { reglement: reglement.id, motif: motif });
  }

  checkPausePaiement(data: any): boolean {
    const start = moment(data.pausePaiementStart).isValid() ? moment(data.pausePaiementStart) : null;
    const end = moment(data.pausePaiementEnd).isValid() ? moment(data.pausePaiementEnd) : null;

    const currentDateTime = moment(new Date());
    if ((currentDateTime.isBetween(start, end) && start && end)
      || (currentDateTime.isAfter(start) && !end)
      || (currentDateTime.isBefore(end) && !start)) {
      return true;
    };

    return false;
  }
}
