<app-payment-status></app-payment-status>

<app-home-board *ngIf="boardCards && !loading; else loader" [boardCards]="boardCards"
  [compact]="platformService.isSmall">
</app-home-board>

<ng-template #loader>
  <app-loader class="margin"></app-loader>
</ng-template>

<ng-template #tokenDialog>
  <mat-toolbar mat-dialog-title color="primary">
    <h1 class="title">Traitement en cours</h1>
  </mat-toolbar>

  <mat-dialog-content>
    <app-loader>{{ tokenDialogMessage|trans }}</app-loader>
  </mat-dialog-content>
</ng-template>
