import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnneeScolaire, CogitoEcole, CogitoInscPreIsnc, CogitoOptions, CogitoSecteurScolaire, ConfigCogito, NiveauScolaire, ParamLibre } from '@app/models/cogito';
import { Family } from '@app/models/family';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { ChildAdapter } from './adapter/child-adapter.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export type typeConfigCogito = 'cogitoInscription' | 'cogitoPreinscription';

@Injectable({
  providedIn: 'root'
})
export class CogitoService {

  configUrl = 'conf/cogito';

  constructor(
    private http: HttpClient,
    private childAdapter: ChildAdapter
  ) { }

  getConfigForAdmin(type: typeConfigCogito) {
    return this.http.get<{ config: ConfigCogito, listAnneesScolaires: [], listNiveauxScolaires: [] }>(`conf/admin/cogito?typeConfig=${type}`);

  }

  getConfigForUser(typeConfig: typeConfigCogito) {
    return this.http.get(`${this.configUrl}?typeConfig=${typeConfig}`);
  }

  saveConfig(config: any, type: typeConfigCogito) {
    return this.http.put(this.configUrl, { config, type }, httpOptions);
  }

  getFamilyPreinscriptions(f: Family | number) {
    const idFamily = typeof f === 'object' ? f.id : f;
    return this.http.get(`familles/${idFamily}/cogito-preinscriptions`);
  }

  getFamilyInscriptions(f: Family | number) {
    const idFamily = typeof f === 'object' ? f.id : f;
    return this.http.get(`familles/${idFamily}/cogito-inscriptions`);
  }

  getPreinscription(f: Family | number, id: number) {
    const idFamily = typeof f === 'object' ? f.id : f;

    return this.http.get<CogitoInscPreIsnc>(`familles/${idFamily}/cogito-preinscriptions/${id}`);
  }

  getInscription(f: Family | number, id: number) {
    const idFamily = typeof f === 'object' ? f.id : f;

    return this.http.get<CogitoInscPreIsnc>(`familles/${idFamily}/cogito-inscriptions/${id}`);
  }

  savePreinscription(preinscription: CogitoInscPreIsnc, idFamily: number) {
    return this.http.post<{ errors?: [] }>(`familles/${idFamily}/cogito-preinscriptions`, { preinscription });
  }

  saveInscription(inscription: CogitoInscPreIsnc, idFamily: number) {
    return this.http.post<{ errors?: [] }>(`familles/${idFamily}/cogito-inscriptions`, { inscription });
  }

  getOptions(family: Family | number, typeConfig: typeConfigCogito) {
    const idFamily = typeof family === 'object' ? family.id : family;

    return this.http.get(`familles/${idFamily}/cogito/options?typeConfig=${typeConfig}`).pipe(
      map((data: any) => {
        data.childList.forEach(u => {
          u = this.childAdapter.adapt(u);
        });

        return data;
      })
    );
  }

  checkPermInscription(config: ConfigCogito) {
    const start = moment(config.startDate).isValid() ? moment(config.startDate) : null;
    const end = moment(config.endDate).isValid() ? moment(config.endDate).add(1, 'day') : null;
    const currentDateTime = moment(new Date());

    if ((start && end && currentDateTime.isBetween(start, end))
      || (!end && currentDateTime.isAfter(start))
      || (!start && currentDateTime.isBefore(end))
      || !start && !end) {
      return true;
    };

    return false;
  }

  sortParamsLibres(paramsLibres: ParamLibre[]) {
    if (paramsLibres.length) {
      paramsLibres.sort((p1, p2) => p1.ordre === p2.ordre ? 0 : !p1.ordre || p1.ordre > p2.ordre ? 1 : -1);
    }
    return paramsLibres;
  }

  setAnneeScolaireToCalculate(dateDebut): Date {
    //au 31 décembre de l'année de début
    let anneeScolaireDebut = moment(dateDebut).year();
    return new Date(anneeScolaireDebut, 11, 31);
  }

  setAgeEnfantDisplayByAnneeScolaire(options: CogitoOptions, isEdit: boolean, cogitoInscPreInsc: CogitoInscPreIsnc, dateToCalculate: Date) {
    if (options && !isEdit) {
      options.childList.forEach(child => {
        child.age = this.setAgeEnfantByAnneeScolaire(dateToCalculate, child.dateNaissance);
      })
    } else {
      cogitoInscPreInsc.yearsMonthAgeEnfant = this.setAgeEnfantByAnneeScolaire(dateToCalculate, cogitoInscPreInsc.dateNaissance);
    }
  }

  setAgeEnfantByAnneeScolaire(anneeScolaire: string | Date, dateNaissanceEnfant: string | Date): { years: number, months: number } {
    let anneeScolaireMomentFormat = moment(anneeScolaire);
    let dateNaissanceEnfantMomentFormat = moment(dateNaissanceEnfant);

    let years = anneeScolaireMomentFormat.diff(dateNaissanceEnfantMomentFormat, 'years');
    dateNaissanceEnfantMomentFormat.add(years, 'years');

    let months = anneeScolaireMomentFormat.diff(dateNaissanceEnfantMomentFormat, 'months');
    dateNaissanceEnfantMomentFormat.add(months, 'months');

    return { years, months };
  }

  setTabAgeAvailabled(limitAgeEnfant: number, ageChild: number): number[] {

    let tabAgeNiveauAvailable = []
    for (let index = 0; index <= limitAgeEnfant; index++) {
      tabAgeNiveauAvailable.push(ageChild++);
    }
    let minAge = Math.min(...tabAgeNiveauAvailable) - 1;
    for (let index = 0; index < limitAgeEnfant; index++) {
      tabAgeNiveauAvailable.push(minAge--);
    }

    return tabAgeNiveauAvailable;

  }

  filtreNiveauScolaireByAgeEnfant(ageEnfant: number, config: ConfigCogito): NiveauScolaire[] {
    let tabAgeAvailable;

    let listNiveauxScolairesAvailable = config.listNiveauxScolaires.filter(niveau => {

      if (config.enabledSelectNiveauScolaire) {

        if (config.limitSelectNiveauScolaire === "plus ou moins 2 ans") {
          tabAgeAvailable = this.setTabAgeAvailabled(2, ageEnfant);
          return tabAgeAvailable.includes(niveau.ageEnfant);
        }
        if (config.limitSelectNiveauScolaire === "plus ou moins 1 an") {
          tabAgeAvailable = this.setTabAgeAvailabled(1, ageEnfant);
          return tabAgeAvailable.includes(niveau.ageEnfant);
        }
        if (config.limitSelectNiveauScolaire === "NoLimit" || config.limitSelectNiveauScolaire === "") {
          return config.listNiveauxScolaires;
        }
      } else return niveau.ageEnfant === ageEnfant;
    });
    return listNiveauxScolairesAvailable
  }

  filtreSecteursScolairesByCycleScolaire(cycleScolaire: string, options: CogitoOptions, config: ConfigCogito, demandeDerogation: boolean) {
    if (config.enabledDerogationObligatoire && !demandeDerogation) {
      let secteurScolaireList = options.secteursScolaireListBydefault.filter(secteur => {
        if (secteur.cyclesScolaires) {
          let tabCyclesScolaires = secteur.cyclesScolaires.split(',');
          return tabCyclesScolaires.includes(cycleScolaire);
        }
      })

      if (secteurScolaireList.length) {
        return secteurScolaireList;
      } else {
        return options.secteursScolaireListBydefault.filter(secteur => secteur.estExterieur);
      }

    } else {
      return options.secteursScolaireList.filter(secteur => {
        let tabCyclesScolaires = secteur.cyclesScolaires.split(',');
        return tabCyclesScolaires.includes(cycleScolaire);
      });
    }
  }

  filtreEcolesByClassesOuvertes(listEcole: CogitoEcole[], inscPreInscCogito: CogitoInscPreIsnc): CogitoEcole[] {
    let listEcoleFiltered = listEcole.filter(ecole => {
      if (ecole.tabClasse) {
        return ecole.tabClasse
          .some(classe => {
            return classe.tabNiveauScolaire.some(nivScolaire => nivScolaire.idNiveauScolaire === inscPreInscCogito.niveauScolaire) &&
              classe.tabAnneeScolaire.some(anneeScolaire => anneeScolaire.idAnneeScolaire === inscPreInscCogito.anneeScolaire.idAnneeScolaire)
          })
      }
    })
    return listEcoleFiltered;
  }

  filtreSecteurScolaireByClasseOuverte(listSecteurScolaire: CogitoSecteurScolaire[], inscPreInscCogito: CogitoInscPreIsnc): CogitoSecteurScolaire[] {
    return listSecteurScolaire.filter(secteurScolaire => this.filtreEcolesByClassesOuvertes(secteurScolaire.tabEcole, inscPreInscCogito).length);
  }

  globalfilterSecteursScolaires(cycleScolaire: string, options: CogitoOptions, config: ConfigCogito, demandeDerogation: boolean, inscPreInscCog: CogitoInscPreIsnc): CogitoSecteurScolaire[] {
    let listSecteurScolaireByCycleScolaire = this.filtreSecteursScolairesByCycleScolaire(cycleScolaire, options, config, demandeDerogation);

    if (config.enabledDerogationObligatoire && !demandeDerogation) {
      return listSecteurScolaireByCycleScolaire;
    } else {
      return this.filtreSecteurScolaireByClasseOuverte(listSecteurScolaireByCycleScolaire, inscPreInscCog);
    }
  }

  globalFilterEcoles(idSecteurScolaire: number, cycleScolaire: string, options: CogitoOptions, config: ConfigCogito, demandeDerogation: boolean, inscPreInscCog: CogitoInscPreIsnc): CogitoEcole[] {
    let listEcolesByCycleScolaire = this.filtreEcolesByCycleScolaire(cycleScolaire, idSecteurScolaire, options, config, demandeDerogation);

    if (config?.listEcoles.length) {
      return this.filtreEcolesByClassesOuvertes(listEcolesByCycleScolaire || [], inscPreInscCog)
        .filter(ecole => config.listEcoles.some(ecoleAvailable => ecoleAvailable.idEcole === ecole.idEcole));
    } else {
      return this.filtreEcolesByClassesOuvertes(listEcolesByCycleScolaire || [], inscPreInscCog);
    }
  }

  filtreEcolesByCycleScolaire(cycleScolaire: string, idSecteurScolaire: number, options: CogitoOptions, config: ConfigCogito, demandeDerogation: boolean) {
    let tabSecteurScolaire = (config.enabledDerogationObligatoire && !demandeDerogation) ? options.secteursScolaireListBydefault : options.secteursScolaireList;
    return tabSecteurScolaire
      .find(secteur => secteur.idSecteurScolaire === idSecteurScolaire)?.tabEcole
      .filter(ecole => ecole.tabCyclesScolaire.some(cycle => cycle.libelle === cycleScolaire));
  }

}

