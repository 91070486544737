import { Child } from '@app/models/child';
import { Injectable } from '@angular/core';
import { computeAge } from '@app/utils/date-functions';
import { BaseAdapterService } from './base-adapter.service';

@Injectable({ providedIn: 'root' })
export class ChildAdapter extends BaseAdapterService<Child> {
  adapt(item: any): Child {
    const child = item as Child;

    child.id = item.idEnfant;

    if (child.dateNaissance) {
      child.dateNaissance = new Date(child.dateNaissance);
      child.age = computeAge(child.dateNaissance);
    } else {
      child.age = {years: 0, months: 0};
    }

    return child;
  }
}
