import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface ParamLibre {
  id?: number;
  nature?: string;
  obligatoire?: boolean;
  titre?: string;
  enabled?: boolean;
  ordre?: number;
  label?: string;
  options?: string[];
  value?: any;
}

@Component({
  selector: 'app-params-libre',
  templateUrl: './params-libre.component.html',
  styleUrls: ['./params-libre.component.scss']
})
export class ParamsLibreComponent implements OnInit {

  @Input() paramLibre: ParamLibre;
  // @Output() valueEmitter = new EventEmitter();
  label: string;

  constructor() { }

  ngOnInit(): void {
    this.label = this.paramLibre.label ? this.paramLibre.label : this.paramLibre.titre;
  }

  // onChangeModel(value) {
  //   this.valueEmitter.emit(this.paramLibre);
  // }


}
