import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CalendarOptions, DayCellContentArg, DayCellMountArg, DayHeaderContentArg, EventApi, EventContentArg, EventInput, EventMountArg, FullCalendarComponent, MountArg } from '@fullcalendar/angular';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import { PointageConfig, PointageInscription, PointagePlanning, PointagePresence } from '@app/models/pointage';
import { debounceTime, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, interval, Observable, Subject } from 'rxjs';
import { PeriodeService } from '@app/services/periode.service';
import { PlatformService, SnackbarService, ThemeService } from '@app/services';
import moment from 'moment';
import { DATE_FORMAT } from '@app/services/planning.service';
import { SelectTimeDialogComponent } from './select-time-dialog/select-time-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomStyleService } from '@app/services/custom-style.service';
import { mostReadable } from '@ctrl/tinycolor';
import { AssmatPointageService } from '@app/services/assmat-pointage.service';

const textColors = [ThemeService.COMMON_COLORS.lightPrimaryText, ThemeService.COMMON_COLORS.darkPrimaryText];

@Component({
  selector: 'app-assmat-planning',
  templateUrl: './assmat-planning.component.html',
  styleUrls: ['./assmat-planning.component.scss']
})
export class AssmatPlanningComponent implements OnInit, AfterViewInit, OnDestroy {

  defaultSettings: CalendarOptions = {
    locale: frLocale,
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin],
    headerToolbar: { left: 'prev,next today', center: 'title', right: 'saveButton' },
    footerToolbar: {},
    customButtons: {
      saveButton: {
        click: () => this.onSave()
      }
    },
    initialView: 'dayGridMonth',
    displayEventEnd: true,
    eventDisplay: 'block',
    aspectRatio: 2,
    weekNumberCalculation: 'ISO',
    contentHeight: 'auto',
    height: 'auto'
  };

  @Input() config: PointageConfig;
  @Input() idAssmat: number;
  @Input() selectedInscription: PointageInscription;
  @Input() preview: boolean;

  options: CalendarOptions = {} as any;
  saving = false;
  loading = true;
  loaders: Observable<any>[] = [];
  isMoreClick = false;
  feries: { date: string, detail?: string }[];
  isSmallScreen: boolean;
  isVerySmallScreen: boolean;
  planningData: PointagePlanning = { events: [], presences: [], presencesUpdatedOrCreated: [], presencesRemoved: [] };
  planningCurrentChanges: PointagePresence[] = [];
  prevList: PointagePresence[] = [];
  onPresenceUpdate = new Subject();
  renderDaySubject = new Subject();
  renderEventSubject = new Subject();
  onDestroy$ = new Subject();

  @ViewChild('calendar') calendar: FullCalendarComponent;
  @ViewChild('calendarWrapper') calendarWrapper: ElementRef;

  constructor(
    private periodeService: PeriodeService,
    public platformService: PlatformService,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private customStyleService: CustomStyleService,
    private pointageService: AssmatPointageService,
  ) { }

  // --- Data --- //
  //////////////////
  //////////////////

  ngOnInit(): void {

    this.loadOptions();
    this.loaders = [this.periodeService.getJoursFeries().pipe(tap(feries => this.feries = feries))];

    if (!this.preview) {
      this.loaders.push(this.getPlanningData());
    }

    forkJoin(this.loaders).subscribe(_ => {
      this.initCalendarStuff(this.calendar);
      this.setupCalendar();
      this.loading = false;
    });


    this.onPresenceUpdate.subscribe(_ => {
      this.planningData.events = this.generateEvents(this.planningData.presences);
      this.refreshShownEvents();
    });

    this.renderDaySubject.asObservable().pipe(debounceTime(1), take(1)).subscribe(_ => {
      if (!this.selectedInscription.enabledPointage) {
        this.calendar.getApi().gotoDate(this.selectedInscription.dateDebut);
      }
    });
  }

  getPlanningData() {
    return this.pointageService.getPresAbsByIdAssmat(this.idAssmat).pipe(tap((data: any) => {
      this.planningData = { events: [], presences: [], presencesUpdatedOrCreated: [], presencesRemoved: [] };
      this.planningCurrentChanges = [];
      this.planningData.presences = data.filter(presAbs => !presAbs.previsionnel);
      this.prevList = data.filter(prev => prev.previsionnel);
      this.onPresenceUpdate.next();
    }))
  }

  loadOptions() {
    this.parseCalendarConfig(this.config);

    this.options.dayHeaderDidMount = this.onDayHeaderRender.bind(this);
    this.options.dayCellClassNames = this.getDayClassNames.bind(this);
    this.options.dayCellContent = this.getDayContent.bind(this);
    this.options.dayCellDidMount = this.onDayRender.bind(this);

    this.options.eventClassNames = this.getEventClassNames.bind(this);
    this.options.eventContent = this.getEventContent.bind(this);

    this.options.dateClick = this.onDateClick.bind(this);
    this.options.eventClick = this.onEventClick.bind(this);
  }

  parseCalendarConfig(config: PointageConfig) {
    this.options = Object.assign({}, this.options, this.defaultSettings);

    this.options.fixedWeekCount = config.calendar.fixedWeekCount;
    this.options.showNonCurrentDates = config.calendar.showNonCurrentDates;

    const maxEvents = config.calendar.eventLimit;

    if (maxEvents) {
      this.options.dayMaxEvents = maxEvents === -1 ? true : maxEvents;
    }
  }

  setupCalendar() {
    this.options.validRange = this.setValidRange();
  }

  buildEvents() {
    this.generateEvents(this.planningData.presences);
    this.refreshShownEvents();
  }

  setValidRange() {
    const range = { start: null, end: null };
    // range.end = moment(this.selectedInscription.dateFin).isValid ? moment(this.selectedInscription.dateFin).add(1, 'day').format(DATE_FORMAT) : null;
    // range.start = moment(this.selectedInscription.dateDebut).format(DATE_FORMAT);
    return range;
  }

  refreshShownEvents() {
    this.options.events = [].concat(...this.planningData.events.filter(ev => ev.idInscription === this.selectedInscription.idInscription));

    if (this.calendar && this.calendar.getApi()) {
      this.initCalendarStuff(this.calendar);
      this.calendar.getApi().render();
    }
  }

  findPresence(id: number | string, presences: PointagePresence[]) {
    return presences.find(pr => (pr.idPresenceAbsence || pr.tmpId) === id);
  }

  filterPresencesByIdInscriptionByDate(id: number | string, date: string | Date) {
    return this.planningData.presences.filter(pr => pr.date === date && pr.idInscription === id);
  }

  removePresence(currentPresence: PointagePresence) {
    this.planningData.presences = this.planningData.presences.filter(pr => this.filterRemovePresence(pr, currentPresence));
    this.planningData.presencesUpdatedOrCreated = this.planningData.presencesUpdatedOrCreated.filter(pr => this.filterRemovePresence(pr, currentPresence));
    this.planningCurrentChanges = [...this.planningData.presencesRemoved, ...this.planningData.presencesUpdatedOrCreated]
    this.onPresenceUpdate.next();
  }

  filterRemovePresence(presence: PointagePresence, currentPresence: PointagePresence) {
    return (presence.tmpId || presence.idPresenceAbsence) !== (currentPresence.tmpId || currentPresence.idPresenceAbsence);
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.setupCalendar();
    if (!changes.selectedInscription) {
      return null;
    }

    if (!changes.selectedInscription.firstChange) {
      this.calendar.getApi().destroy();
      this.refreshShownEvents();
      // this.loadOptions();
      // this.initCalendarStuff(this.calendar);
      if (!this.selectedInscription.enabledPointage) {
        if (!this.selectedInscription.remplacement) {
          this.calendar.getApi().gotoDate(this.selectedInscription.dateDebut);
        } else {
          this.calendar.getApi().gotoDate(this.selectedInscription.datesRemplacement[0]);
        }
      } else {
        this.calendar.getApi().today();
      }
    }
  }

  getPrevByIdInscriptionByDate(id: number, date: string | Date): PointagePresence[] {
    return this.prevList.filter((prev: PointagePresence) => prev.idInscription === id && prev.date === date)
      .sort((a, b) => {
        if (a.plage1Deb < b.plage1Deb) return -1;
        if (a.plage1Deb > b.plage1Deb) return 1;
        return 0;
      });
  }

  // --- UI --- //
  ////////////////
  ////////////////

  ngAfterViewInit() {
    // Wait for Calendar to appear, then do stuff
    // interval(500).pipe(map(() => this.calendar), filter(x => !!x), take(1)).subscribe(c => this.initCalendarStuff(c));
    // To scroll on Planning wrapper when it gets loaded (should be improved)
    // const rootEl = this.elementRef.nativeElement as HTMLElement;
    // const position = rootEl.getBoundingClientRect();

    // window.scrollTo(0, Math.max(0, position.y - 100));
  }

  initCalendarStuff(c) {
    this.platformService.mainWidth$.pipe(
      startWith(0),
      debounceTime(100),
      takeUntil(this.onDestroy$),
    ).subscribe(() => { this.onWidthChange(); this.calendar.getApi().updateSize(); });
  }

  onWidthChange() {
    const width = this.getCalendarWidth();

    if (!width) {
      return;
    }

    this.isSmallScreen = width < 600;
    this.isVerySmallScreen = width < 400;

    // Refresh ratio
    // Timeout = wait for Calendar to refresh before we update ratio (else no effect)
    setTimeout(() => this.options.aspectRatio = this.getRatioForWidth(width));
    this.refreshButtons();
  }

  getRatioForWidth(width: number) {
    return width <= 600 ? .8 : (width <= 900 ? 1.6 : 2);
  }

  refreshButtons() {
    const saveButton: HTMLButtonElement = document.querySelector(".fc-saveButton-button");
    saveButton.classList.add('customButton', 'save-button');

    if ((this.planningData.presencesUpdatedOrCreated.length || this.planningData.presencesRemoved.length) && !this.preview) {
      saveButton.removeAttribute("disabled");
    } else {
      saveButton.setAttribute('disabled', 'disabled');
    }

    saveButton.innerHTML = '<mat-icon class="material-icons" style="margin-right:5px">save</mat-icon><span class="text">Enregistrer</span>'

    if (this.isVerySmallScreen) {
      this.options.headerToolbar = { left: 'prev next', center: 'title', right: 'saveButton' };
      this.options.footerToolbar = { left: 'today', center: '', right: '' };
      saveButton.innerHTML = '<mat-icon class="material-icons">save</mat-icon>'
    } else if (this.isSmallScreen && !this.isVerySmallScreen) {
      this.options.headerToolbar = { left: 'prev next', center: 'title', right: 'saveButton' };
      this.options.footerToolbar = { left: 'today', center: '', right: '' };
    } else {
      this.options.headerToolbar = this.defaultSettings.headerToolbar;
      this.options.footerToolbar = this.defaultSettings.footerToolbar;
    }
  }

  getCalendarWidth() {
    return this.calendarWrapper ? (this.calendarWrapper.nativeElement as HTMLElement).offsetWidth : this.platformService.mainWidth();
  }

  getDayContent(data: DayCellContentArg) {
    const dateMom = moment(data.date);
    const dateStr = dateMom.format(DATE_FORMAT);
    const domNodes: HTMLElement[] = [];
    const ferie = this.feries.find(f => f.date === dateStr);

    const dayNumber = document.createElement('a');
    dayNumber.classList.add('fc-daygrid-day-number', 'no-underline-animation');
    dayNumber.innerHTML = ferie ? `<span class="daynumber">${data.date.getDate()}</span><span class="ferie">Férié</span><span></span>` : `<span class="daynumber">${data.date.getDate()}</span>`;
    domNodes.push(dayNumber);

    const prev: PointagePresence[] = this.getPrevByIdInscriptionByDate(this.selectedInscription.idInscription, dateStr);

    if (prev) {
      const prevEl = document.createElement('div');
      prevEl.classList.add('prev');

      prev.forEach(prev => {
        if (prev.plage1Deb && prev.plage1Fin) {
          this.generateSinglePrevUI(prevEl, prev.plage1Deb, prev.plage1Fin, prev.type);
        }

        if (prev.plage2Deb && prev.plage2Fin) {
          this.generateSinglePrevUI(prevEl, prev.plage2Deb, prev.plage2Fin, prev.type);
        }
      })
      domNodes.push(prevEl)
    }

    this.renderDaySubject.next();

    return { domNodes };
  }

  generateSinglePrevUI(prevEl: HTMLDivElement, debut: string, fin: string, type: string) {
    // const typePr = type.toUpperCase();
    const prevSingleEl = document.createElement('div');
    prevSingleEl.innerHTML = `<mat-icon class="material-icons icon">history_toggle_off</mat-icon><span>${debut} ${fin}</span>`;
    prevSingleEl.classList.add('singleTime');
    // prevSingleEl.style.backgroundColor = typePr === "P" ? this.config.rubriquePresence.color : this.config.rubriqueAbsence.color;
    prevEl.appendChild(prevSingleEl);
  }

  onDayRender(data: MountArg<DayCellMountArg>) {
    const dateMom = moment(data.date);
    const dateStr = dateMom.format(DATE_FORMAT);

    if (!data.el.classList.contains('fc-popover')) {

      const buttons = document.createElement('div');
      buttons.classList.add('buttons');

      if (this.isEditableDate(dateStr) && this.selectedInscription.enabledPointage) {
        const addButton = document.createElement('button');
        addButton.classList.add('accent-bg');
        addButton.innerHTML = '<span class="text">Ajouter</span><mat-icon class="material-icons">add</mat-icon>';

        buttons.appendChild(addButton);
      }
      data.el.querySelector('.fc-daygrid-day-frame').appendChild(buttons);
    }
  }

  onDayHeaderRender(data: MountArg<DayHeaderContentArg>) {
    //@TODO: virer ça du niveau global !!
    data.el.querySelector('a').classList.add('no-underline-animation');
  }

  getDayClassNames(data: DayCellContentArg) {
    const dateMom = moment(data.date);
    const dateStr = dateMom.format(DATE_FORMAT);

    const classes = [];

    if (!this.isEditableDate(dateStr) || !this.selectedInscription.enabledPointage ||
      moment(this.selectedInscription.dateFin) < dateMom || moment(this.selectedInscription.dateDebut) > dateMom) {
      classes.push('fc-day-disabled');
    }

    return classes;
  }

  getEventContent(data: EventContentArg) {
    const domNodes: HTMLElement[] = [];

    const contentEl = document.createElement('div');
    contentEl.classList.add('event-main-content');

    const timeEl = document.createElement('span');
    timeEl.classList.add('event-times', 'singleTime');

    if (this.isSmallScreen) {
      timeEl.innerHTML = this.buildContentTitleEvent(data.event.extendedProps.smallScreenTitle, data.event.extendedProps.justBeenAddedOrUpdated);
    } else {
      timeEl.innerHTML = this.buildContentTitleEvent(data.event.title, data.event.extendedProps.justBeenAddedOrUpdated);
    }

    contentEl.appendChild(timeEl);

    domNodes.push(contentEl);

    this.renderEventSubject.next();

    return { domNodes };
  }

  buildContentTitleEvent(title, justBeenAddedOrUpdated) {
    if (justBeenAddedOrUpdated) {
      return `<mat-icon class="material-icons icon">watch_later</mat-icon><span>${title}</span>`;
    } else {
      return `<mat-icon class="material-icons icon">query_builder</mat-icon><span>${title}</span>`;
    }
  }

  getEventClassNames(data: EventContentArg) {
    const classNames = ['no-underline-animation', 'event-times'];
    return classNames;
  }

  isEditableDate(date: string | Date) {
    const nbDaysLimiteSaisie = this.config.nbJourLimiteSaisie;
    const firstEditableDate = moment().subtract(nbDaysLimiteSaisie, 'd').format(DATE_FORMAT);
    const prev: PointagePresence[] = this.getPrevByIdInscriptionByDate(this.selectedInscription.idInscription, date);

    if (!this.selectedInscription.remplacement) {
      if (this.config.calendar.editabledAfterCurrentDay) {
        return firstEditableDate <= date;
      } else {
        return firstEditableDate <= date && !(date > moment().format(DATE_FORMAT));
      }
    } else {
      if (this.config.calendar.editabledAfterCurrentDay) {
        return prev.length && firstEditableDate <= date;
      } else {
        return prev.length && firstEditableDate <= date && !(date > moment().format(DATE_FORMAT));
      }
    }
  }


  // --- Interaction (click, open Dialogs, etc) --- //
  ////////////////////////////////////////////////////
  ///////////////////////////////////////////////////


  onDateClick(dateData: DateClickArg) {
    const formattedDate = moment(dateData.date).format(DATE_FORMAT);

    if (this.isEditableDate(formattedDate) && this.selectedInscription.enabledPointage &&
      (moment(this.selectedInscription.dateFin) >= moment(dateData.date) || !moment(this.selectedInscription.dateFin).isValid()) &&
      moment(this.selectedInscription.dateDebut) <= moment(dateData.date)) {
      this.openSelectTimeDialog(formattedDate)
    } else {
      this.snackbar.info(`Cette date n'est pas ou plus modifiable`);
    }
  }

  onEventClick(eventInfo: { event: EventApi, jsEvent: MouseEvent }) {

    const event = eventInfo.event;
    const eventData = event.extendedProps;

    const existingPresence = this.findPresence(eventData.presence, this.planningData.presences);
    this.openSelectTimeDialog(existingPresence.date, existingPresence);

  }

  openSelectTimeDialog(date: string | Date, existingPresence?: PointagePresence) {
    let data: any = {};
    let prev: PointagePresence[] = this.getPrevByIdInscriptionByDate(this.selectedInscription.idInscription, date);
    let presences: PointagePresence[] = this.filterPresencesByIdInscriptionByDate(this.selectedInscription.idInscription, date);
    let editable = this.isEditableDate(date)

    if (!existingPresence) {
      data = {
        editable,
        date,
        presences,
        prev,
        inscription: this.selectedInscription,
        planningConfig: this.config,
      };
    } else {
      presences = presences.filter(pr => (pr.tmpId || pr.idPresenceAbsence) !== (existingPresence.tmpId || existingPresence.idPresenceAbsence));
      data = {
        editable,
        existingPresence,
        date,
        presences,
        planningConfig: this.config,
      }
    }

    const ref = this.dialog.open(SelectTimeDialogComponent, { minWidth: 'min(500px, 100%)', maxWidth: '500px', data });

    this.platformService.adaptDialogToScreen(ref);

    ref.afterClosed().subscribe((selectData) => {
      if (selectData) {
        if (existingPresence) {
          selectData.forEach(data => {
            if (data.remove) {
              if (existingPresence.idPresenceAbsence) {
                this.planningData.presencesRemoved.push(existingPresence);
                this.planningCurrentChanges = [...this.planningData.presencesRemoved, ...this.planningData.presencesUpdatedOrCreated];
              }
              this.removePresence(existingPresence);
            } else {

              this.updateExistingPresence(existingPresence, data);

              if (this.planningData.presencesUpdatedOrCreated.length) {
                const id = existingPresence.idPresenceAbsence ? existingPresence.idPresenceAbsence : existingPresence.tmpId;
                if (!this.findPresence(id, this.planningData.presencesUpdatedOrCreated)) {
                  this.planningData.presencesUpdatedOrCreated.push(existingPresence);
                  this.planningCurrentChanges = [...this.planningData.presencesRemoved, ...this.planningData.presencesUpdatedOrCreated];
                }
              } else {
                this.planningData.presencesUpdatedOrCreated.push(existingPresence);
                this.planningCurrentChanges = [...this.planningData.presencesRemoved, ...this.planningData.presencesUpdatedOrCreated];
              }

              this.onPresenceUpdate.next();
            }
          });
        } else {
          selectData.forEach(data => {
            let newPresence = this.createPresence(data, date);
            this.addPresence(newPresence);
          });
          this.onPresenceUpdate.next();
        }
      }
    });
  }

  updateExistingPresence(presence, newData) {
    presence.plage1Deb = newData.plage1Deb;
    presence.plage1Fin = newData.plage1Fin;
    presence.idRubriquePlanning = newData.idRubriquePlanning;
    presence.petitDej = newData.petitDej;
    presence.collation = newData.collation;
    presence.repasMidi = newData.repasMidi;
    presence.gouter = newData.gouter;
    presence.repasSoir = newData.repasSoir;
    presence.hasUpdated = true;
    presence.type = newData.type;
  }

  // --- Build Event & Presence --- //

  addPresence(presence: PointagePresence) {
    this.planningData.presencesUpdatedOrCreated.push(presence);
    this.planningCurrentChanges = [...this.planningData.presencesRemoved, ...this.planningData.presencesUpdatedOrCreated];
    this.planningData.presences.push(presence);
    this.onPresenceUpdate.next();
  }

  createPresence(data: PointagePresence, date): PointagePresence {

    const presence = {
      tmpId: this.generateId(),
      type: data.type,
      idInscription: this.selectedInscription.idInscription,
      idAssistantMaternel: this.idAssmat,
      idRubriquePlanning: data.idRubriquePlanning,
      date,
      plage1Deb: data.plage1Deb,
      plage1Fin: data.plage1Fin,
      petitDej: data.petitDej,
      collation: data.collation,
      repasMidi: data.repasMidi,
      gouter: data.gouter,
      repasSoir: data.repasSoir
    } as PointagePresence;

    return presence;
  }

  generateId() {
    return Math.random().toString(36).substr(2, 9);
  }

  generateEvents(presences: PointagePresence[]) {
    const events: EventInput[] = [];

    presences.forEach(pr => {
      events.push(...this.buildEventsFromPresence(pr));
    });

    return events;
  }

  buildEventFromPresence(presence: PointagePresence) {
    const event = {
      start: `${presence.date}T${presence.plage1Deb}`,
      end: `${presence.date}T${presence.plage1Fin}`,
      title: `${presence.plage1Deb} ${presence.plage1Fin}`,
      smallScreenTitle: `${presence.plage1Deb}<br>${presence.plage1Fin}`,
      presence: presence.idPresenceAbsence || presence.tmpId,
      idInscription: presence.idInscription,
      type: presence.type,
      justBeenAddedOrUpdated: presence.hasUpdated ? true : presence.tmpId ? true : false
    } as EventInput;

    let color;
    color = presence.type.toUpperCase() === "P" ? this.config.rubriquePresence.color : this.config.rubriqueAbsence.color;

    if (color) {
      color = this.customStyleService.parseCustomColor(color);
      event.backgroundColor = color;
      event.textColor = this.getContrastColor(color);
      // event.borderColor = color;
    }

    return event;
  }

  buildEventsFromPresence(presence: PointagePresence) {
    const events = [];
    events.push(this.buildEventFromPresence(presence));
    return events;
  }

  getContrastColor(color: string) {
    return '#' + mostReadable(color, textColors).toHex();
  }

  onSave() {
    if (!this.preview) {
      this.saving = true;
      this.pointageService.onSave(this.planningData.presencesRemoved, this.planningData.presencesUpdatedOrCreated).subscribe((response: any) => {

        if (response.msgErrConflict) {
          this.initCalendarStuff(this.calendar);
          this.snackbar.error(`Echec de l'enregistrement. <br>${response.msgErrConflict}`);
          this.saving = false;
        }
        if (response.length && !response.msgErrConflict) {
          const msgRapport = this.getMessageErrRapport(response);
          if (msgRapport) {
            this.afterSave()
            this.snackbar.open({ message: `Enregistrement réussi !<br>Exceptions : ${msgRapport}`, type: 'info', textButton: 'Ok' });
          } else {
            this.afterSave();
            this.snackbar.info('Enregistrement réussi !');
          }
        }
      },
        err => {
          if (err) {
            this.afterSave();
            this.snackbar.error('Une erreur est survenue lors de l\'enregistrement');
          }
        });
    }
  }

  getMessageErrRapport(response: any[]) {
    let details: string = '';

    response.forEach(resp => {
      if (!resp.result) {
        details += `<br> - ${resp.msg}`;
      }
    })
    return details;
  }

  afterSave() {
    this.getPlanningData().subscribe(_ => {
      this.initCalendarStuff(this.calendar)
      this.saving = false;
    });

  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
