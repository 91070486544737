import { Component, OnInit } from '@angular/core';
import { PlatformService } from '@app/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MailService } from '@app/services/mail.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {

  forgottenPassForm: FormGroup;
  isLoading = false;
  response = '';

  constructor(
    public platformService: PlatformService,
    private formBuilder: FormBuilder,
    private emailService: MailService,
    private route: ActivatedRoute,
  ) {
    this.forgottenPassForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get email() {
    return this.forgottenPassForm.get('email').value;
  }

  ngOnInit() {
    this.response = '';

    const inputEmail = this.route.snapshot.paramMap.get('email');
    if (inputEmail) {
      this.forgottenPassForm.get('email').setValue(inputEmail);
    }

  }

  onSubmit() {
    this.isLoading = true;
    this.emailService.sendForgottenPasswordEmail(this.email).subscribe(resp => {
      this.response = resp.message;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.response = `
      <div class="mat-error">
        <div style="font-size:1.4em; border-bottom:1px solid currentColor;">Erreur</div>
        <div> ${ typeof(err) === 'string' ? err.replace(/\n/g, '<br>') : JSON.stringify(err)} </div>
      </div>
      `;
    });
  }

}
