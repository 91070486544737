import { EventInput } from '@fullcalendar/core';
import { PeriodeRubrique, PeriodeActivite } from './periode';
import { Facture } from './facturation';

export type PresenceStatus = 'new' | 'waiting' | 'canceling' | 'canceled' | 'denied' | 'accepted' | 'liste_attente' | 'expired' | 'expired_canceling' | 'other_account';
export type ReservationStatus = PresenceStatus | 'waiting_payment' | 'partially_accepted';

// Child Reservation
export interface Reservation {
  id: number | string;

  idChild: number;
  idAdult: number;
  idFamily: number;
  idInscription: number;

  idConsumer: string; // 'e:'+idEnfant => for child  ||'a:'+idAdulte => for adult // must be set with Consumer.buildIdConsumer()

  idPortailPeriode: number;

  idPeriode?: number; // Only for Diabolo
  idRubrique?: number; // Only for Mikado

  type: string;
  state: string;
  status: ReservationStatus;
  fromDomino?: boolean;

  creationDate: Date;

  // Real matter data
  presences: ReservationPresence[];

  // All these are labels / used in list view only
  referent?: string;
  numDossier?: string; // Still to define, what does this refer to ?

  etablissement: string;
  accueil: string;
  periode: string;

  // Only front side, helper for display
  number?: string;
  events: EventInput[]; // Generated by Planning, for Calendar

  // No longer exist / used ... (@TODO: verify -on reservations list- & remove ?)
  startDate: Date | string;
  endDate: Date | string;

  otherAccount?: boolean;
  alreadyPaying?: boolean;

  editable?: boolean;
}

export interface BaseReservationPresence {
  id?: number; // From remote API, real ID
  tmpId?: string; // Generated locally, only to retrieve from event.id

  date: string | Date;
  startTime: string;
  endTime: string;

  startTime2: string;
  endTime2?: string;

  rubrique?: number | PeriodeRubrique;
  activities?: (number | any)[];
  activitiesOnListeAttente?: number[];

  state: PresenceState;
  status: PresenceStatus;

  askCancel?: boolean | Date;
  replacedBy?: number | string; // If want to invert logic, should change server side before ...

  replacing?: number;
  idDominoPresence?: number; // ID of validated Presence linked (mostly used for cancel of Domino presence)

  title?: string;
  color?: string;
  contrastColor?: string;
  group?: string;
  shortTitle?: string;
  showTimes?: boolean;
}

export interface ReservationPresence extends BaseReservationPresence {
  reservation?: number | string;
  rubrique?: number;
  activities?: number[];
  error?: PresenceError;
  attente?: number; // null = aucun, 1 = enattente coché, -1 = en attente décoché
}

// these are real enum values that can be stored in "state" column of DB table
export enum PresenceState {
  waiting = 0,
  attente = 3,

  canceled = -1,
  denied = -2,
  accepted = 1
}

export interface PanierReservation extends Reservation {
  selected?: boolean;
  alreadyPaying?: boolean;
}

export interface ReservationPanierItem {
  idRegie: number;
  nomRegie: string;
  reservations: PanierReservation[];
  facture?: Facture;
  montantAvoir?: number;
  typePaiement?: string;
}

export interface ReservationError {
  type: string;
  errorMessage: string;
  source: { idPortailPresence: number, tmpId: string | number };
}

// "chain" only happens on Rubriques
// "rubrique" only happens on Activities (means disabled because no Rubrique available)
export type PresenceError = 'date' | 'full' | 'conflict' | 'chain' | 'rubrique' | 'activity_conflict' | 'notAvailableDispo';

export interface MikadoPresence {
  id?: number;
  tmpId?: string;

  reservation: number;
  date: string;
  startTime: string;
  endTime: string;
}

export interface ReservationConfig {
  diabolo: {
    periodeFilterProgram: number[];
    smtpAccount: number;
  };

  mikado: {
    periodeFilterProgram: number[];
    smtpAccount: number;
  };

  calendar: {
    fixedWeekCount: boolean;
    showNonCurrentDates: boolean;
    hiddenDaysOfWeek: boolean;
    weekends: boolean;

    eventLimit: number;
  };
}
