import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BoardConfigService, AuthenticationService, PlatformService, UserService, SnackbarService } from '@app/services';
import { BoardCard, BoardConfig } from '@app/models/board-card';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseConfigService, loadConf } from '@app/services/config/base.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  boardConfig: BoardConfig;
  boardCards: BoardCard[];
  loading = true;
  derniereModif: any;
  localBoardHomeConf: any;

  linkToken: string;

  @ViewChild('tokenDialog') tokenDialog: TemplateRef<any>;
  tokenDialogMessage: string;
  tokenDialogRef: MatDialogRef<any>;

  constructor(
    private boardConfigService: BoardConfigService,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    public platformService: PlatformService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private baseConfigService: BaseConfigService,
  ) {
    this.linkToken = this.route.snapshot.paramMap.get('token');
  }

  ngAfterViewInit() {
    if (this.linkToken) {
      this.tokenDialogRef = this.dialog.open(this.tokenDialog, { disableClose: true });
    }
  }

  ngOnInit() {
    this.boardConfig = this.baseConfigService.getFirstConf("board-home").content;

    if (this.boardConfig) {
      this.filterCards();
      this.loading = this.linkToken ? true : false;
    }

    if (this.linkToken) {
      this.userService.getTokenType(this.linkToken)
        .subscribe(response => {
          switch (response.type) {
            case 'confirmEmail':
              this.tokenDialogMessage = 'Confirmation de l\'adresse email ...';

              this.userService.confirmEmail(this.linkToken).subscribe(res => {
                this.tokenDialogRef.close();
                this.snackbarService.info(res.message);

                if (res.mustCreatePassword && res.passwordCreationToken) {
                  this.router.navigate(['/reset-password',
                    {
                      token: res.passwordCreationToken,
                      title: 'Création de votre mot de passe',
                      userLogin: res.userLogin
                    }], { skipLocationChange: true });
                } else {
                  if (this.authService.currentUserValue) {
                    // setTimeout( () => {this.router.navigate(['/account']); }, 200);
                    this.router.navigate(['/account']);
                  } else {
                    this.router.navigate(['/home']);
                  }
                }
              });
              break;

            case 'updateEmail':
              this.tokenDialogMessage = 'Validation du changement d\'email ...';

              this.authService.sendUpdateEmailToken(this.linkToken).subscribe(resp => {
                this.loading = false;
                this.tokenDialogRef.close();

                if (resp.status === 'ok') {
                  this.snackbarService.open({ message: 'La modification a bien été prise en compte', textButton: 'OK' });
                  this.router.navigateByUrl('/home'); // remove token from URL ...
                } else {
                  this.snackbarService.error('update_email.status.' + resp.status);
                }
              });
              break;

            case 'reinitPassword':
              this.tokenDialogRef.close();

              this.router.navigate(['/reset-password',
                { token: this.linkToken, title: 'Réinitialisation de votre mot de passe' }], { skipLocationChange: true });

              break;

            case 'error':
              this.tokenDialogRef.close();
              console.warn(response.errorMessage);
              this.snackbarService.error(response.errorMessage);
              this.loading = false;
              break;
          }
        },
          error => {
            this.tokenDialogRef.close();
            console.warn(error);
            this.snackbarService.error(error);
            this.loading = false;
          });
    }
  }

  filterCards() {
    const isAuth = this.authService.isAuthenticated;

    this.boardCards = this.boardConfig.cards.filter(bc => {
      return bc.enabled && (!bc.limited || (bc.limited === 'login' && isAuth) || (bc.limited === 'logout' && !isAuth));
    });
  }
}
