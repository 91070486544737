import { Component, OnInit, ViewChild } from '@angular/core';
import { FormConfigService, FormHelperService, PreinscriptionService, PermissionService, FamilyService } from '@app/services';
import { ExtendedFormlyFieldConfig, StepType } from '@app/forms/config/form-model';
import { FormViewerComponent, SubmitFormEvent } from '@app/components/_public/form-viewer/form-viewer.component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, forkJoin } from 'rxjs';
import moment from 'moment';
import { FormType } from '@app/models/global-config';

export interface AgrementMinMax {
  numJour: number,
  jour: string,
  maxHeure: string,
  minHeure: string
}

@Component({
  selector: 'app-user-preinscription-edit',
  templateUrl: './preinscription-edit.component.html',
  styleUrls: ['./preinscription-edit.component.scss']
})
export class PreinscriptionEditComponent implements OnInit {

  id: number;
  step: string;
  readOnly: boolean;
  form: StepType[];
  data = {};
  dataSouhaits = {};
  form$: Observable<any>;
  dataSouhaits$: Observable<any>;
  isLoading = true;
  loadingMessage = 'Chargement';
  typeForm: FormType = 'form-pre-inscription';
  isNewPreinscription: boolean;
  listIdEtabsSelected: number[] = [];
  dateDebut: string;
  dateFin: string;

  formTitle: string;

  @ViewChild(FormViewerComponent) formViewer: FormViewerComponent;

  constructor(
    private formConfigService: FormConfigService,
    private helperService: FormHelperService,
    private preinscriptionService: PreinscriptionService,
    private permService: PermissionService,
    private familyService: FamilyService,
    private route: ActivatedRoute,
    private location: Location
  ) {
  }

  ngOnInit() {
    this.id = +this.route.snapshot.paramMap.get('id') !== 0 ? +this.route.snapshot.paramMap.get('id') : null;
    this.step = this.route.snapshot.paramMap.get('step');
    this.isNewPreinscription = this.route.snapshot.routeConfig.path === "add";
    this.form$ = this.preinscriptionService.getFormData(this.id, this.step);
    this.readOnly = (!this.permService.hasPermission('preinscription_edit') || !this.familyService.currentFamily.active) && !this.isNewPreinscription;
    this.formTitle = this.readOnly ? 'Voir pré-inscription' : 'Modifier pré-inscription';


    if (this.isNewPreinscription) {
      this.formTitle = this.route.snapshot.data.title;
    }

    let sourceToForkjoin = [this.form$];

    if (this.id && !this.isNewPreinscription && this.step === "Programmation") {
      this.dataSouhaits$ = this.preinscriptionService.getFormData(this.id, "accueil");
      sourceToForkjoin.push(this.dataSouhaits$);
    }

    forkJoin(sourceToForkjoin)
      .subscribe(([form, dataSouhaits]) => {

        this.form = this.formConfigService.getFormView(form.config).filter(f => f.enabled);
        this.data = !form.data ? { modeCreation: true } : form.data;

        if (dataSouhaits) {
          let dateDebut = dataSouhaits?.Bloc_dates_inscription?.souhaitDateDebut ? moment(dataSouhaits?.Bloc_dates_inscription?.souhaitDateDebut).format('L') : "";
          let dateFin = dataSouhaits?.Bloc_dates_inscription?.souhaitDateFin ? moment(dataSouhaits?.Bloc_dates_inscription?.souhaitDateFin).format('L') : "";

          if (dataSouhaits?.Bloc_souhaits_accueil) {
            let listIdEtabs: number[] = dataSouhaits?.Bloc_souhaits_accueil.map(souhait => souhait.etablissement);
            listIdEtabs = [...new Set(listIdEtabs)];

            if (listIdEtabs.length) {
            this.preinscriptionService.getAgrementsByIdEtabs(listIdEtabs, dateDebut, dateFin).subscribe((data: AgrementMinMax[]) => {
              if (data.length) {
                this.setMinMaxHoursProgrammation(data);
              }
              this.isLoading = false;
            })
            } else this.isLoading = false;

          } else this.isLoading = false;

        } else {
          this.isLoading = false;
        }

      });
  }


  getStepControls(controls) {
    if (controls.hasOwnProperty("Bloc_programmation")) {
      if (this.listIdEtabsSelected.length) {
        this.preinscriptionService.getAgrementsByIdEtabs(this.listIdEtabsSelected, this.dateDebut, this.dateFin).subscribe((data: AgrementMinMax[]) => {
          if (data.length) {
            this.setMinMaxHoursProgrammation(data);
          }
        })
      }
    }
  }

  setMinMaxHoursProgrammation(listAgrementsbyDay: AgrementMinMax[]) {
    let fieldProgrammation = this.getFieldProgrammation();

    listAgrementsbyDay.forEach(agrement => {
      for (const fieldDayDefault of fieldProgrammation.defaultValue) {
        if (fieldDayDefault.key.includes(agrement.numJour.toString())) {
          fieldDayDefault.templateOptions = {
            maxHour: agrement.maxHeure,
            minHour: agrement.minHeure
          }
        }
      }
    });
  }

  getFieldProgrammation(): ExtendedFormlyFieldConfig {
    for (const step of this.form) {
      if (step.stepName === "Programmation") {
        return this.formConfigService.findFieldByName(step.fields, "jour_programmation");
      }
    }
  }

  onModelChange(event) {
    if (event?.Bloc_souhaits_accueil?.length) {
      let listIdEtabs: number[] = event.Bloc_souhaits_accueil.map(souhait => souhait.etablissement);
      this.listIdEtabsSelected = [...new Set(listIdEtabs)] // supprime doublons
    }

    if (event?.Bloc_dates_inscription) {
      this.dateDebut = moment(event.Bloc_dates_inscription.souhaitDateDebut).toDate().toLocaleDateString('fr');
      this.dateFin = moment(event.Bloc_dates_inscription.souhaitDateFin).toDate().toLocaleDateString('fr');
    }
  }

  onSave(event: SubmitFormEvent) {
    const data = event.model;
    this.formViewer.setErrorMessage('');

    if (data["deja_ne"] === 'Oui') {
      data["dateAccouchement"] = '';
    } else {
      data["dateNaissance"] = '';
    }

    this.loadingMessage = 'Enregistrement';
    if (this.readOnly) {
      return;
    }
    this.isLoading = true;
    const saveMethod = (this.id) ? this.preinscriptionService.update(data, this.id, this.step) : this.preinscriptionService.create(data);
    saveMethod.subscribe((result: any) => {
      this.helperService.displayDebugTraces(result.traces);
      this.helperService.notifySuccess('Modification effectuée', result.messages);

      this.location.back();
    }, err => {
      this.isLoading = false;

      setTimeout(() => { // doit être encadré dans un setTimeout car sinon le 'formViewer' n'est pas accessible (car il était masqué par 'isLoading')...
        this.helperService.manageServerError(err, this.formViewer);
      })
    });

  }


}
