import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { PlatformService } from '@app/services';
import { filter } from 'rxjs/operators';

class FieldMatchErrorState implements ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective): boolean {
    return (control.dirty || control.touched) && (control.invalid || form.hasError('match'));
  }
}

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

  zxcvbn: any;

  passForm: FormGroup;

  @Input() requiredForce: number;
  @Input() minLength = 6;
  @Input() label = 'Mot de passe';

  @Output() valueChange: EventEmitter<string>;
  @Output() statusChange: EventEmitter<string>;

  matcher = new FieldMatchErrorState();

  strength: number;
  strengthColor: string;
  showPass = false;

  constructor(
    private fb: FormBuilder,
    public platformService: PlatformService
  ) {
    this.valueChange = new EventEmitter();
    this.statusChange = new EventEmitter();

    this.passForm = this.fb.group({
      password: ['', [Validators.required, this.checkPasswordStrength.bind(this)]],
      confirmPassword: ['']
    }, {
      validators: [this.checkPasswordMatch]
    });
  }

  ngOnInit(): void {
    this.passForm.valueChanges.pipe(filter(_ => !!this.valueChange)).subscribe(value => this.valueChange.emit(value.password));
    this.passForm.statusChanges.pipe(filter(_ => !!this.statusChange)).subscribe(status => this.statusChange.emit(status));

    import('zxcvbn').then(m => this.zxcvbn = m.default);

    const passwordControl = this.passForm.get('password');

    passwordControl.valueChanges.subscribe(x => {
      this.strength = this.refreshStrength(x);
      this.strengthColor = this.refreshStrengthColor(this.strength);
      passwordControl.updateValueAndValidity({ emitEvent: false });
    });
  }

  getPasswordErrorMessage() {
    const field = this.passForm.get('password');

    if (field.hasError('required')) {
      return 'Obligatoire';
    }

    if (field.hasError('weak')) {
      return 'Mot de passe trop faible';
    }

    return null;
  }

  refreshStrength(value: string) {
    return value ? this.zxcvbn(value).score : 0;
  }

  refreshStrengthColor(strength: number) {
    return strength < 2 ? 'warn' : (strength < 4 ? 'accent' : 'primary');
  }

  checkPasswordStrength() {
    return this.strength < this.requiredForce ? { weak: true } : null;
  }

  checkPasswordMatch(group: FormGroup) {
    // return object containing errors, or null if no error
    if (group.value.password !== group.value.confirmPassword) {
      return { match: true };
    }

    return null;
  }
}
