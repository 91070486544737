import { Component, OnInit, ViewChild, TemplateRef, ElementRef, OnDestroy } from '@angular/core';
import { StepType } from '@app/forms/config/form-model';
import { UserService, PermissionService, AuthenticationService, LoginContext, FamilyService, FormConfigService, FormHelperService, GlobalConfigService, SnackbarService } from '@app/services';
import { Router, ActivatedRoute } from '@angular/router';
import { FormViewerComponent, SubmitFormEvent } from '../form-viewer/form-viewer.component';
import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Observable, Subject, of, forkJoin, Subscription } from 'rxjs';
import { takeUntil, distinctUntilChanged, debounceTime, first, tap } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AdresseUpdateService } from '@app/services/adresse-update.service';
import { addressToUpdate } from '@app/models/address';
import { OpenIdConfig } from '@app/models/global-config';
import { FormType } from '@app/models/global-config';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit, OnDestroy {

  externalAuth: OpenIdConfig;

  id: number;
  step: string;
  readOnly: boolean;

  form: StepType[];
  typeForm: FormType = 'form-user';
  data: any;
  userCreated: boolean;
  emailField;
  isLoading = true;
  loadingMessage = 'Chargement';
  formTitle: string;
  updateFamilyChecked: string;
  updateConjointChecked: string;

  dataUpdateAddress: addressToUpdate;
  manyAddressesDisplay: boolean;
  conjointData: any;
  foyerData: any;

  loginCheckState = '';
  loginCheckLoaderIsMoved = false;
  loginCheckSearch$ = new Subject<string>();
  loginCheckRequest$ = new Subject<boolean>();
  destroy$ = new Subject();
  searchSubscription: Subscription;

  actualStep: any = null;


  @ViewChild(FormViewerComponent) formViewer: FormViewerComponent;
  @ViewChild('loginAlreadyExistQuestion', { static: true }) loginAlreadyExistQuestionTemplate: TemplateRef<any>;
  @ViewChild('loginCheckindicator', { static: true }) loginCheckindicator: ElementRef;
  @ViewChild('updateAddressBottomSheet', { static: true }) updateAddressBottomSheet: TemplateRef<any>;

  constructor(
    private formConfigService: FormConfigService,
    private helperService: FormHelperService,
    private userService: UserService,
    private authService: AuthenticationService,
    private permService: PermissionService,
    private familyService: FamilyService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public bottomSheet: MatBottomSheet,
    private addressUpdateService: AdresseUpdateService,
    private globalConfService: GlobalConfigService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit() {

    this.globalConfService.config$.pipe(takeUntil(this.destroy$)).subscribe(conf => {
      this.externalAuth = conf.externalAuth;
    });

    this.step = this.route.snapshot.paramMap.get('step');
    this.id = (this.userService.currentUser ? this.userService.currentUser.id : null) || null;
    this.readOnly = (this.id && !this.permService.hasPermission('account_edit')) || (this.familyService.currentFamily ? !this.familyService.currentFamily.active : false);

    forkJoin([
      this.userService.getCurrentUserData(),
      this.userService.getFormData(this.id, this.step)
    ])
      .subscribe(([userData, form]: [any, any]) => {
        this.formTitle = userData ? `${userData.prenom} ${userData.nom}` : null;
        this.form = this.formConfigService.getFormView(form.config).filter(f => f.enabled);
        this.data = !form.data ? { modeCreation: true } : form.data;

        this.isLoading = false;
        this.emailField = this.formConfigService.findFieldByName(this.form, 'email');

        if (!this.id) {

          if (this.authService.creatingUserFromOIDC === null) {
            const creatingUserJSON = sessionStorage.getItem('creatingUser')
            this.authService.creatingUserFromOIDC = creatingUserJSON !== null ? JSON.parse(creatingUserJSON) : null;
          }

          if (this.authService.creatingUserFromOIDC !== null) {

            this.data = Object.assign(this.data, this.authService.creatingUserFromOIDC.adulte)

            this.form = this.form.filter(step => step.stepName !== 'connexion')
            setTimeout(() => {
              this.formViewer.stepper.next();
            });

          } else {
            // Mode création 'normal' : brancher un validateur async pour vérifier l'existance du login
            this.setLoginAsyncValidator()
          }

        }
        this.onStepChange(0)
      });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setLoginAsyncValidator() {
    this.emailField.asyncValidators = {
      uniqueLogin: {
        expression: (control: FormControl) => this.loginValidator(control),
        message: 'L\'adresse e-mail est déjà utilisée',
      }
    };

    this.loginCheckSearch$.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      debounceTime(300),
    ).subscribe(searchTerm => {
      if (!this.id && !this.userCreated && !this.isLoading) {
        if (this.searchSubscription) { this.searchSubscription.unsubscribe(); }
        this.searchSubscription = this.userService.checkLoginAvailability(searchTerm)
          .subscribe(loginIsAvailable => {
            if (!loginIsAvailable) {
              this.loginCheckState = 'ko';
              this.bottomSheet.open(this.loginAlreadyExistQuestionTemplate);
              this.loginCheckRequest$.next(false);
            } else {
              this.loginCheckState = 'ok';
              this.loginCheckRequest$.next(true);
            }
          });
      } else {
        this.loginCheckRequest$.next(true);
      }
    });

  }

  loginValidator(control: FormControl): Promise<boolean> {
    if (!this.loginCheckLoaderIsMoved) { this.moveLoginCheckLoader(); }
    this.loginCheckState = 'loading';
    if (this.searchSubscription) { this.searchSubscription.unsubscribe(); }
    this.loginCheckSearch$.next(control.value);
    return this.loginCheckRequest$.pipe(
      first(),
      tap(res => {
        if (res === true) { // valider le champ email manuellement (car le validator formly ne le fait pas toujours bien)
          this.emailField.formControl.setErrors(null);
        }
      })).toPromise();
  }

  moveLoginCheckLoader() {
    const emailFieldElmt = this.emailField.__formField__._elementRef.nativeElement;
    emailFieldElmt.appendChild(this.loginCheckindicator.nativeElement);
    this.loginCheckLoaderIsMoved = true;
  }


  checkAddress() {
    this.addressUpdateService.checkAddress('user').subscribe(data => {
      this.dataUpdateAddress = data;

      if ((data.familyAddress && !data.familyAddressIsSameUserAddress) ||
        (data.conjointAddress && !data.conjointAddressIsSameUserAddress)) {

        this.getDataConjoint();
        this.getDataFoyer();
        this.bottomSheet.open(this.updateAddressBottomSheet);

        this.manyAddressesDisplay = this.addressUpdateService.manyAddressesDisplay(
          data.familyAddress,
          data.conjointAddress,
          data.familyAddressIsSameUserAddress,
          data.conjointAddressIsSameUserAddress)
      }
    });
  }

  getDataConjoint() {
    this.conjointData = this.addressUpdateService.getDataConjoint(this.dataUpdateAddress);
  }

  getDataFoyer() {
    this.foyerData = this.addressUpdateService.getDataFoyer(this.dataUpdateAddress);
  }

  updateAddressConjoint() {
    this.addressUpdateService.updateAddressConjoint('user', this.dataUpdateAddress);
  }

  updateAddressFamille() {
    this.addressUpdateService.updateAddressFoyer('user', this.dataUpdateAddress);
  }

  updateFamilleConjointAddresses() {
    this.addressUpdateService.updateAddresses('user', this.dataUpdateAddress);
  }

  validUpdate() {
    if (this.familyService.currentFamily) {

      if (this.updateFamilyChecked === 'oui' && (this.updateConjointChecked === 'non' || !this.updateConjointChecked)) {
        this.updateAddressFamille();
      }

      if ((this.updateFamilyChecked === 'non' || !this.updateFamilyChecked) && this.updateConjointChecked === 'oui') {
        this.updateAddressConjoint();
      }

      if (this.updateFamilyChecked === 'oui' && this.updateConjointChecked === 'oui') {
        this.updateFamilleConjointAddresses();
      }
    }
  }

  onSave(event: SubmitFormEvent) {
    this.formViewer.setErrorMessage('');
    this.loadingMessage = 'Enregistrement';
    if (this.readOnly) {
      return;
    }

    if (!!this.authService.creatingUserFromOIDC) {
      this.data.email = this.authService.creatingUserFromOIDC.userInfos.email
      this.data.sub = this.authService.creatingUserFromOIDC.userInfos.sub
      this.data.passwordGroup = "no_password_because_oidc_creation"
    }

    this.isLoading = true; // Disable the form while we send data
    const saveMethod = (this.id) ? this.userService.update(this.data, this.id, this.step) : this.userService.create(this.data);
    saveMethod.subscribe((response: any) => {

      if (this.id) {
        if (this.step === "coordinates" && this.familyService.currentFamily) {
          this.checkAddress();
        }
        this.location.back();
        this.helperService.displayDebugTraces(response.traces);
        this.helperService.notifySuccess('', response.messages);
      } else if (!this.id && response && response.id) {
        // User was successfully created, inform the user and login auto or redirect to login
        this.userCreated = true;
        this.helperService.displayDebugTraces(response.traces);
        this.helperService.notifySuccess('', response.messages);

        sessionStorage.removeItem('creatingUser')

        this.loadingMessage = ' ... ';
        const loginData: LoginContext = { username: this.data.email, password: this.data.passwordGroup };

        this.authService.login(loginData)
          .subscribe(() => {
            setTimeout(() => { this.router.navigate(['/account']); }, 200);
          }, error => {
            console.error(error);
            setTimeout(() => { this.router.navigate(['/home']); }, 200);
          });
      }
    }, err => {
      this.isLoading = false;

      setTimeout(() => { // doit être encadré dans un setTimeout car sinon le 'formViewer' n'est pas accessible (car il était masqué par 'isLoading')...
        this.helperService.manageServerError(err, this.formViewer);
      })

    });
  }

  goLogin() {
    this.router.navigate(['/login', { email: this.data.email }], { skipLocationChange: true });
  }

  goForgotPassword() {
    this.router.navigate(['/forgot-password', { email: this.data.email }], { skipLocationChange: true });
  }

  onStepChange(indice) {
    this.actualStep = this.actualStep = this.form[indice];
  }

  openIdConnect() {
    this.isLoading = true;
    // this.redirectingMessage = ' Redirection ';
    this.authService.openIdConnectInitLogin().subscribe(result => {
      if (result.redirectTo) {
        window.location.href = result.redirectTo;
      } else {
        this.snackbarService.error(`Erreur, la requête n'a pas renvoyé l'url de redirection`);
        console.error(`Erreur, la requête n'a pas renvoyé l'url de redirection : `, result)
      }

    })
  }

}
